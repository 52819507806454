import React from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import Badge from 'shared/components/Badge';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import WithRole from 'shared/components/WithRole';
import ROUTES from 'shared/constants/routes';
import { USER_PERMISSION } from 'shared/constants/user';
import UserContext from 'shared/contexts/UserContext';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/hooks/useTranslations';
import api from 'shared/services/api';
import { formatDate } from 'shared/utils/time';
import styles from './styles.module.scss';

interface Tab {
  id: any;
  label: string;
}

interface PdfTemplatesResponse {
  templates: {
    id: string;
    name: string;
    createdAt: string;
    configuration?: any;
    user?: {
      id: string;
      firstName: string;
      lastName: string;
    };
    status: any;
  }[];
}
const PdfList = () => {
  const tabs: Tab[] = [];
  const history = useHistory();
  const { data, isLoaded, isLoading } = useFetch<PdfTemplatesResponse>({
    initialValue: {},
    fetchAction: () => api.get('/pdf/templates'),
  });
  const [qsCurrentTab] = useQueryParam('currentTab', StringParam);
  const currentTab = qsCurrentTab || 'ALL';
  const handleCurrentTab = (id: any) => {};
  const onRowClick = (id: any) => {
    history.push(ROUTES.pdf.templates.edit.to(id));
  };
  const translations = useTranslations();

  return (
    <div>
      <UserContext.Consumer>
        {({ data: user }) => (
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item
                linkProps={{ to: ROUTES.pdf.list.path }}
                linkAs={Link}
              >
                Pdf
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {translations.templates.templates}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {' '}
                {translations.global.list}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
              <div className="col-xl-12">
                <Card
                  title={
                    <div className={styles.search}>
                      {translations.templates.templates}
                    </div>
                  }
                  toolbar={
                    <>
                      <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_CREATE]}>
                        <Button
                          onClick={() => {
                            history.push(ROUTES.pdf.templates.create.path);
                          }}
                          color="primary"
                        >
                          {translations.templates.createTemplate}
                        </Button>
                      </WithRole>
                    </>
                  }
                >
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((tab) => (
                      <li
                        className="nav-item"
                        key={tab.id}
                        role="button"
                        onClick={() => handleCurrentTab(tab.id)}
                      >
                        <span
                          className={`nav-link ${
                            tab.id === currentTab ? 'active' : ''
                          }`}
                          id="home-tab"
                        >
                          <span className="nav-text">{tab.label}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  {isLoading && (
                    <div>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}

                  {isLoaded && (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>{translations.global.name}</th>
                          <th>{translations.global.createdAt}</th>
                          <th>{translations.global.user}</th>
                          <th>Aktywny</th>
                          <th>Domyślny</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.templates?.map((template) => (
                          <tr
                            key={template.id}
                            onClick={() => onRowClick(template.id)}
                            role="button"
                          >
                            <td>{template.name}</td>
                            <td>{formatDate(template.createdAt)}</td>
                            <td>
                              {template.user?.firstName}{' '}
                              {template.user?.lastName}{' '}
                              {template.user?.id === user.id ? (
                                <span>(you)</span>
                              ) : null}
                            </td>

                            <td>
                              {template.configuration?.isActive === 'YES' ? (
                                <Badge color="success">Tak</Badge>
                              ) : (
                                <Badge color="danger">Nie</Badge>
                              )}
                            </td>
                            <td>
                              {template.configuration?.isDefault === 'YES' ? (
                                <Badge color="success">Tak</Badge>
                              ) : (
                                <Badge color="danger">Nie</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Card>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default PdfList;
