import React from 'react';
import { USER_PERMISSION } from 'shared/constants/user';
import { Company } from 'shared/services/api/admin/types';
import { User } from 'types/user';
interface UserContextValue {
  data: any;
  firebaseUser?: User;
  selectedCompany?: Company;
  refresh: () => void;
  roles?: USER_PERMISSION[];
}
const UserContext = React.createContext<UserContextValue>({
  data: {},
  refresh: () => {},
  selectedCompany: undefined,
  roles: [],
});

export default UserContext;
