import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCustomerFacilityInventoryItems } from 'shared/services/api/facilities/inventoryItems';
import { useState } from 'react';
import { ActionIcon, Button, Group, Image } from '@mantine/core';
import { FacilityInventoryItem } from 'shared/services/api/facilities/inventoryItems/types';
import CreateFacilityInventoryItemModal from './CreateFacilityInventoryItemModal';
import DeleteFacilityInventoryItemModal from 'modules/Customers/Details/Facilities/Details/InventoryItems/List/DeleteFacilityInventoryItemModal';
import EditFacilityInventoryItemModal from 'modules/Customers/Details/Facilities/Details/InventoryItems/List/EditFacilityInventoryItemModal';
import { IconPencil, IconTrash } from '@tabler/icons';

const FacilitiesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityInventoryItem[];
  }>({
    initialValue: {},
    fetchAction: () =>
      getCustomerFacilityInventoryItems(customerId, facilityId),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const [
    deleteFacilityInventoryItemModalOpen,
    setDeleteFacilityInventoryItemModalOpen,
  ] = useState(false);

  const [
    editFacilityInventoryItemModalOpen,
    setEditFacilityInventoryItemModalOpen,
  ] = useState(false);

  const [selectedFacilityInventoryItem, setSelectedFacilityInventoryItem] =
    useState<FacilityInventoryItem | null>(null);
  const handleOnDelete = () => {
    setDeleteFacilityInventoryItemModalOpen(false);
    refresh();
  };
  const handleOnEdit = () => {
    setEditFacilityInventoryItemModalOpen(false);
    refresh();
  };
  const handleRemove = (item: FacilityInventoryItem) => () => {
    setSelectedFacilityInventoryItem(item);
    setDeleteFacilityInventoryItemModalOpen(true);
  };
  const handleEdit = (item: FacilityInventoryItem) => () => {
    setSelectedFacilityInventoryItem(item);
    setEditFacilityInventoryItemModalOpen(true);
  };
  return (
    <div className="">
      <CreateFacilityInventoryItemModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
        currentItems={data?.rows || []}
      />
      <DeleteFacilityInventoryItemModal
        open={deleteFacilityInventoryItemModalOpen}
        onClose={() => setDeleteFacilityInventoryItemModalOpen(false)}
        onSuccess={handleOnDelete}
        customerId={customerId}
        facilityId={facilityId}
        facilityInventoryItem={selectedFacilityInventoryItem!}
      />
      <EditFacilityInventoryItemModal
        open={editFacilityInventoryItemModalOpen}
        onClose={() => setEditFacilityInventoryItemModalOpen(false)}
        onSuccess={handleOnEdit}
        customerId={customerId}
        facilityId={facilityId}
        facilityInventoryItem={selectedFacilityInventoryItem!}
        currentItems={data?.rows || []}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Sprzęt'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj sprzęt
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Nazwa</th>
                  <th>Ilość</th>
                  <th>Added at</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((inventoryItem) => (
                  <tr key={inventoryItem.id}>
                    <td>
                      <Image
                        src={inventoryItem?.inventoryItem?.imageUrl}
                        width={50}
                        height={50}
                        withPlaceholder
                      />
                    </td>
                    <td>{inventoryItem.inventoryItem?.name}</td>
                    <td>{inventoryItem.amount || 'Według potrzeb'}</td>

                    <td>{formatDate(inventoryItem.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(inventoryItem)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(inventoryItem)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesList;
