import React from 'react';
import cn from 'classnames';
// import { ButtonType } from 'react-bootstrap/esm/Button';

type ThemeColor =
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'light'
  | 'dark';

interface Props {
  children: React.ReactNode;
  color: ThemeColor;
  className?: string;
}
const Badge = (props: Props) => {
  const { children, color, className } = props;
  const classes = cn('badge', {
    [`badge-${color}`]: !!color,
    [`${className}`]: !!className,
  });
  return <span className={classes}>{children}</span>;
};

export default Badge;
