import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { useParams } from 'react-router';
import PdfTemplateForm from '../Form';
import useFetch from 'shared/hooks/useFetch';

import { toast } from 'react-toastify';
import api from 'shared/services/api';
import { useForm } from '@mantine/form';
import { LoadingOverlay } from '@mantine/core';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Params {
  id: string;
}
export default function EditPdfTemplate() {
  const { data, isLoading, isLoaded, error } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api.get(`/pdf/templates/${id}`),
  });
  const { data: dataFiles, isLoading: isLoadingFiles } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api.get(`/files`),
  });

  const [isSaving, setSaving] = useState(false);
  const { id } = useParams<Params>();

  const form = useForm({
    initialValues: {
      name: undefined,
      configuration: {
        isActive: 'YES',
        isDefault: 'NO',
        pages: [],
        pageBackgroundFileId: undefined,
      },
    },
  });

  useEffect(() => {
    if (data.template) {
      const configuration = {
        pages: [],
        pageBackgroundFileId:
          data.template?.configuration?.pageBackgroundFileId,
        isActive: data.template?.configuration?.isActive,
        isDefault: data.template?.configuration?.isDefault,
        inventory: [],
      };
      data.template?.configuration?.pages?.forEach((page) => {
        //@ts-ignore
        configuration.pages.push(page);
        // configuration.pages.push] = {
        //   wysywig: get(data, `pdf.configuration.pages.${page.id}.wysywig`),
        // };
      });
      if (data.template?.configuration?.inventory) {
        Object.values(data.template?.configuration?.inventory).forEach(
          (item, index) => {
            // const itemId = Object.keys(data.pdf?.configuration?.inventory)[index];
            // @ts-ignore
            configuration.inventory.push(item);
          }
        );
      }
      form.setValues({
        name: data.template?.name,
        configuration,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form.setValues]);

  const translations = useTranslations();
  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      await api.patch(`/pdf/templates/${id}`, {
        name: data.name,
        configuration: data.configuration,
      });
      toast.success('Zapisano');
      setSaving(false);
      // history.push(ROUTES.pdf.templates.list.path);
    } catch (e) {
      toast.error('Coś poszło nie tak, spróbuj za chwilę.');
    }
  };
  toast.success(translations.templates.addedSuccess);
  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.pdf.templates.list.path }}
          linkAs={Link}
        >
          Oferty
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.pdf.templates.list.path }}
          linkAs={Link}
        >
          {translations.templates.templates}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.template?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && <div className="alert alert-danger">No PDF template</div>}
          {isLoaded && (
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Card
                title={translations.templates.editTemplate}
                toolbar={
                  <>
                    <Button color="primary" isLoading={isSaving}>
                      {translations.global.save}
                    </Button>
                  </>
                }
              >
                <PdfTemplateForm form={form} files={dataFiles?.files || []} />
                <LoadingOverlay visible={isLoadingFiles} />
              </Card>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
