import { Alert, Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchCustomer } from 'shared/services/api/customers';
import { Customer } from 'shared/services/api/customers/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customer: Customer;
}
const EditCustomerModal = (props: Props) => {
  const { open, onClose, onSuccess, customer } = props;

  const form = useForm({
    initialValues: customer,
    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCustomer(customer.id, {
        name: values.name,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  useEffect(() => {
    if (customer && open) {
      form.setValues(customer);
    }
    // eslint-disable-next-line
  }, [customer, open]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj klienta">
        <form onSubmit={form.onSubmit(handleSubmit)} style={{ minHeight: 400 }}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditCustomerModal;
