import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Rating,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import {
  getCustomerFacilityAuditPrepare,
  postCustomerFacilityAudit,
} from 'shared/services/api/facilities/audits';

import { useTranslations } from 'shared/hooks/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import { AuditPrepare } from 'shared/services/api/facilities/audits/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
}
const CreateFacilityAuditModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId } = props;
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      auditAreas: {},
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
      description: (value) =>
        value && value.length > 0 ? null : 'Invalid name',
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { data, isLoading, refresh } = useFetch<AuditPrepare>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityAuditPrepare(customerId, facilityId),
  });
  const handleSubmit = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      await postCustomerFacilityAudit(customerId, facilityId, {
        name: values.name,
        description: values.description,
        auditAreas: values.auditAreas,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      refresh();
    }
    // eslint-disable-next-line
  }, [open]);

  const translations = useTranslations();
  useEffect(() => {
    const updatedInitialValues = data.audit?.reduce((acc, auditArea) => {
      acc[auditArea.id] = {
        tasks: auditArea?.companyAreaType?.auditTasks?.reduce(
          (taskAcc, task) => {
            taskAcc[task.id] = { score: 0, description: '' }; // Domyślne wartości dla zadań
            return taskAcc;
          },
          {}
        ),
      };
      return acc;
    }, {});

    form.setValues({ auditAreas: updatedInitialValues });
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj zamówienie">
        <LoadingOverlay visible={isLoading} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            // TODO: Translations
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <Textarea
            withAsterisk
            mt={10}
            // TODO: Translations
            label="Opis zamówienia"
            placeholder=""
            {...form.getInputProps('description')}
          />
          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              {translations.global.add}
            </Button>
          </Group>

          <table>
            {data.audit?.map((auditArea) => (
              <div key={auditArea.id}>
                <h5>{auditArea.name}</h5>
                <table>
                  <tr>
                    <th>Miejsce</th>
                    <th>Ocena</th>
                    <th>Uwagi</th>
                  </tr>
                  {auditArea.companyAreaType?.auditTasks?.map((task) => (
                    <tr key={task.id}>
                      <td>{task.name}</td>
                      <td>
                        <Rating
                          size="lg"
                          {...form.getInputProps(
                            `auditAreas.${auditArea.id}.tasks.${task.id}.score`
                          )}
                        />
                      </td>
                      <td>
                        <Textarea
                          placeholder="Komentarz"
                          {...form.getInputProps(
                            `auditAreas.${auditArea.id}.tasks.${task.id}.description`
                          )}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            ))}
          </table>
        </form>
      </Modal>
    </>
  );
};

export default CreateFacilityAuditModal;
