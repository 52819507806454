import React from 'react';
import cn from 'classnames';
interface Props {
  children?: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  toolbar?: React.ReactNode;
  className?: string;
}

const Card = (props: Props) => {
  const { children, title, toolbar, footer, className } = props;
  const classes = cn('card card-custom gutter-b', className);
  return (
    <div className={classes}>
      {(title || toolbar) && (
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">{title}</h3>
          </div>
          <div className="card-toolbar">{toolbar}</div>
        </div>
      )}
      <div className="card-body">{children}</div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};

export default Card;
