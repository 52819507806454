import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useMemo } from 'react';
import { postCustomerFacilityAdditionalService } from 'shared/services/api/facilities/additionalServices';

import { getCompanyServices } from 'shared/services/api/company/services';
import { CompanyService } from 'shared/services/api/company/services/types';
import useFetch from 'shared/hooks/useFetch';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
}
const CreateFacilityAdditionalServiceModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId } = props;
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });

  const { data, isLoading } = useFetch<{
    rows: CompanyService[];
  }>({
    initialValue: {},
    fetchAction: () => getCompanyServices(),
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerFacilityAdditionalService(customerId, facilityId, {
        name: values.name,
        description: values.description,
        companyServiceId: values.companyServiceId,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const servicesData = useMemo(() => {
    return (
      data.rows?.map((service) => ({
        label: service.name,
        value: service.id,
      })) || []
    );
  }, [data.rows]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Zamów dodatkową usługę">
        {isLoading && <LoadingOverlay visible />}
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            // TODO: Translations
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <Select
            data={servicesData}
            mt={10}
            clearable
            searchable
            // TODO: Translations
            label="Wybierz usługę"
            placeholder=""
            {...form.getInputProps('companyServiceId')}
          />
          <Textarea
            mt={10}
            // TODO: Translations
            label="Opis usługi"
            placeholder=""
            {...form.getInputProps('description')}
          />
          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button
              type="submit"
              loading={loading}
              disabled={loading || isLoading}
            >
              Zamów
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateFacilityAdditionalServiceModal;
