import { Group, Radio, Select, Textarea } from '@mantine/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import { useForm } from '@mantine/form';
import { formatDate } from 'shared/utils/time';

interface PdfOutput {
  id: string;
  configuration: {
    name: string;
    description?: string;
  };
  createdAt: string;
}
interface Props {
  show: boolean;
  onClose: Function;
  loading: boolean;
  onSubmit: Function;
  outputs: PdfOutput[];
}

const GenerateOutputModal = (props: Props) => {
  const form = useForm({
    initialValues: {
      generateNewFile: 'YES',
      description: '',
      outputToOverride: props.outputs[0]?.id || '',
    },
  });
  const { show, onClose, loading, onSubmit } = props;
  const onSave = async () => {
    try {
      onSubmit(form.values);
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    } finally {
    }
  };

  const handleClose = () => onClose();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Wygeneruj plik PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.outputs.length > 0 && (
          <Radio.Group
            label="Wygeneruj nowy plik"
            description="Wygeneruj nowy plik PDF zamiast nadpisać istniejący."
            mb={10}
            {...form.getInputProps(`generateNewFile`)}
          >
            <Group mt={10}>
              <Radio value="YES" label="Tak" defaultChecked />
              <Radio value="NO" label="Nie" />
            </Group>
          </Radio.Group>
        )}
        {form.values.generateNewFile === 'NO' && props.outputs.length > 0 && (
          <Select
            label="Wybierz plik PDF do nadpisania"
            name="outputToOverride"
            data={
              props.outputs?.map((output) => ({
                label:
                  (output.configuration.name ||
                    output.configuration.description) +
                  ` ` +
                  (output.createdAt
                    ? ` - ${formatDate(output.createdAt)}`
                    : ''),
                value: output.id,
              })) || []
            }
            {...form.getInputProps(`outputToOverride`)}
          />
        )}
        <Textarea
          label="Opis"
          description="Jezeli wyzej zostala wybrana opcja NIE, to zostanie nadpisany"
          mb={10}
          {...form.getInputProps(`description`)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          Anuluj
        </Button>
        <Button color="primary" onClick={onSave} isLoading={loading}>
          Wygeneruj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default GenerateOutputModal;
