import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteCustomer } from 'shared/services/api/customers';
import { Customer } from 'shared/services/api/customers/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customer: Customer;
}
const RemoveCustomerModal = (props: Props) => {
  const { open, onClose, onSuccess, customer } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomer(customer.id);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń klienta">
        Czy na pewno chcesz usunąć klienta?
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Usuń
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default RemoveCustomerModal;
