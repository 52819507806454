import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import { getAppConfiguration, LANG } from 'config';
import {
  INVENTORY_ITEM_STATUS,
  INVENTORY_PRIORITY,
  INVENTORY_STATUS,
  INVENTORY_USABILITY,
} from './utils/status';
import { CommonUserType, FacilityUserRole } from '../types/user';
import { FacilityRequestStatus } from './services/api/facilities/types';
import { FacilityAuditVisibility } from 'shared/services/api/facilities/audits/types';

export const LanguageToMoment = {
  [LANG.PL]: 'pl',
  [LANG.EN]: 'en-gb',
};

export const TRANSLATIONS = {
  [LANG.EN]: {
    customers: {
      addCustomer: 'Add customer',
      editCustomer: 'Edit customer',
      deleteCustomer: 'Delete customer',
      addUser: 'Add user',
      editUser: 'Edit user',
      deleteUser: 'Remove user',
    },
    enums: {
      
      facilityUserRole: {
        [FacilityUserRole.CLEANING]: 'Cleaning',
        [FacilityUserRole.COORDINATOR]: 'Coordinator',
        [FacilityUserRole.SALES]: 'Sales',
        [FacilityUserRole.EQ]: 'Eq',
        [FacilityUserRole.CUSTOMER]: 'Client',
      },
      commonUserType: {
        [CommonUserType.COMPANY_EMPLOYEE]: 'Company employee',
        [CommonUserType.CUSTOMER]: 'Client',
        [CommonUserType.COMPANY_ADMIN]: 'Company admin',
      },
      facilityRequestStatus: {
        [FacilityRequestStatus.OPEN]: 'Open',
        [FacilityRequestStatus.IN_PROGRESS]: 'In progress',
        [FacilityRequestStatus.CLOSED]: 'Closed',
      },
      facilityOrderStatus: {
        [FacilityRequestStatus.OPEN]: 'Open',
        [FacilityRequestStatus.IN_PROGRESS]: 'In progress',
        [FacilityRequestStatus.CLOSED]: 'Closed',
      },
      facilityAdditionalServiceStatus: {
        [FacilityRequestStatus.OPEN]: 'Open',
        [FacilityRequestStatus.IN_PROGRESS]: 'In progress',
        [FacilityRequestStatus.CLOSED]: 'Closed',
      },
      facilityAuditVisibility: {
        [FacilityAuditVisibility.PUBLIC]: 'Public',
        [FacilityAuditVisibility.COMPANY_INTERNAL]: 'Company internal',
        [FacilityAuditVisibility.CUSTOMER_INTERNAL]: 'Customer internal',
      },
    },
    dashboard: {
      noItemsTaken: 'No items taken!',
      yourTakenInventoryItems: 'Your taken inventory items',
      newAndInProgressRequests: 'New & In Progress requests',
      noNewRequests: 'No new requests!',
    },
    files: {
      uploadFileTitle: 'Upload file',
      upload: 'Upload',
      file: 'File',
      selectFile: 'Select file',
    },
    auth: {
      signIn: 'Sign in',
      emailAddress: 'E-mail address',
      password: 'Password',
      signInButton: 'Sign in',
      noAccount: 'No account?',
      joinUs: 'Join Us',
      signInWithGoogle: 'Sign in with Google',
    },
    global: {
      somethingWentWrong: 'Something went wrong',
      order: 'Order',
      add: 'Add',
      create: 'Create',
      edit: 'Edit',
      save: 'Save',
      remove: 'Delete',
      createdAt: 'Created at',
      user: 'User',
      name: 'Name',
      amount: 'Amount',
      download: 'Download',
      description: 'Description',
      list: 'List',
      you: 'You',
      status: 'Status',
      priority: 'Priority',
      usability: 'Usability',
      actions: 'Actions',

      reject: 'Reject',
      cancel: 'Cancel request',
      done: 'Done',
      accept: 'Accept',
      typeToSearch: 'Type to search',
      image: 'Image',
      metadata: 'Metadata',
      key: 'Key',
      value: 'Value',
      users: 'Users',
      firstAndLastName: 'First and last name',
    },
    pdf: {
      list: 'PDF files list',
      findInventoryItem: 'Find inventory item',
      selectInventoryItem: 'Select inventory item',
      page: 'Page',
      template: 'Template',
      editPdfFile: 'Edit PDF file',
      createPdf: 'Create PDF',
      generate: 'Generate PDF file',
      outputs: 'Latest PDF outputs',
      asNeeded: 'As needed',
      fileWillBeAdded: 'PDF file will be added to the final output',
      renderPdfPage: 'Add page to final PDF file',
      isGenerating: 'Generating...',
    },
    templates: {
      editTemplate: 'Edit template',
      createTemplate: 'Create template',
      templateName: 'Template name',
      templates: 'Templates',
      addSuccess: 'Template has been added',
      editSuccess: 'Template has been modified',
      backgroundImageTitle: 'Background image',
      backgroundImageDesc:
        'Select background image that will be rendered on pdf pages',
      pageType: 'Page type',
      pageTypeFile: 'File',
      pageTypeTextEditor: 'Text editor',
      pageTypeInventory: 'Inventory',
      clickHereToAddPage: 'Click here to add new page',
    },
    navigation: {
      dashboard: 'Dashboard',
      files: 'Files',
      pdf: 'PDF',
      pdfTemplates: 'Templates',
      inventory: 'Inventory',
      users: 'Users',
      company: 'Company',
      admin: 'Admin',
      groups: 'Groups',
      requests: 'Requests',
      invitations: 'Invitations',
    },
    account: {
      avatar: 'Avatar',
      firstName: 'First name',
      lastName: 'Last name',
      extensions: 'Extensions',
      personalInformation: 'Personal information',
      signOut: 'Sign out',
      emailAddress: 'E-mail address',
    },
    inventory: {
      all: 'All',
      take: 'Take',
      giveBack: 'Give back',
      assignToUser: 'Assign to user',
      unassignUser: 'Unassign user',
      latest10actions: 'Latest 10 actions',
      columns: 'Columns',
      currentOwner: 'Current owner',
      group: 'Group',
      selectGroup: 'Select group',
      selectImage: 'Select image',
      uniqueCode: 'Unique Code',
      addRequest: 'Add request',
      editItem: 'Edit item',
      createItem: 'Create item',
      nameTip: 'For example: USB-C type 1meter cable',
      inventory: 'Inventory',
      createSuccess: 'Item has been added',
      editSuccess: 'Item has been added',
      removeSuccess: 'Item has been added',
      requests: 'Requests',
      usability: {
        [INVENTORY_USABILITY.PUBLIC]: 'Public',
        [INVENTORY_USABILITY.PRIVATE]: 'Private',
      },
      priority: {
        [INVENTORY_PRIORITY.HIGH]: 'High',
        [INVENTORY_PRIORITY.MEDIUM]: 'Medium',
        [INVENTORY_PRIORITY.LOW]: 'Low',
      },
      itemStatus: {
        [INVENTORY_ITEM_STATUS.FREE]: 'Free',
        [INVENTORY_ITEM_STATUS.TAKEN]: 'Taken',
      },
      status: {
        [INVENTORY_STATUS.CREATED]: 'Created',
        [INVENTORY_STATUS.IN_PROGRESS]: 'In progress',
        [INVENTORY_STATUS.REJECTED]: 'Rejected',
        [INVENTORY_STATUS.CANCELED]: 'Canceled',
        [INVENTORY_STATUS.DONE]: 'Done',
      },
    },
    groups: {
      groups: 'Groups',
      group: 'Group',
      editGroup: 'Edit group',
      createGroup: 'Create group',
      groupExample: 'For example: Cables',
      removeGroupTitle: 'Remove inventory group',
      removeGroupDescription:
        'Are you sure you want to delete group test? It will be removed pernamently, and all of the devices under the group will be unconnected from that group',
      createSuccess: 'Group has been added',
      editSuccess: 'Group has been added',
      removeSuccess: 'Group has been added',
    },
  },
  [LANG.PL]: {
    enums: {
      facilityUserRole: {
        [FacilityUserRole.CLEANING]: 'Pracownik sprzątający',
        [FacilityUserRole.COORDINATOR]: 'Koordynator',
        [FacilityUserRole.SALES]: 'Handlowiec',
        [FacilityUserRole.EQ]: 'Asortyment',
        [FacilityUserRole.CUSTOMER]: 'Klient',
      },
      commonUserType: {
        [CommonUserType.COMPANY_EMPLOYEE]: 'Pracownik firmy',
        [CommonUserType.CUSTOMER]: 'Klient',
        [CommonUserType.COMPANY_ADMIN]: 'Administrator firmy',
      },
      facilityRequestStatus: {
        [FacilityRequestStatus.OPEN]: 'Otwarte',
        [FacilityRequestStatus.IN_PROGRESS]: 'W trakcie',
        [FacilityRequestStatus.CLOSED]: 'Zamknięte',
      },
      facilityOrderStatus: {
        [FacilityRequestStatus.OPEN]: 'Otwarte',
        [FacilityRequestStatus.IN_PROGRESS]: 'W trakcie',
        [FacilityRequestStatus.CLOSED]: 'Zamknięte',
      },
      facilityAdditionalServiceStatus: {
        [FacilityRequestStatus.OPEN]: 'Otwarte',
        [FacilityRequestStatus.IN_PROGRESS]: 'W trakcie',
        [FacilityRequestStatus.CLOSED]: 'Zamknięte',
      },
      facilityAuditVisibility: {
        [FacilityAuditVisibility.PUBLIC]: 'Publiczny',
        [FacilityAuditVisibility.COMPANY_INTERNAL]: 'Wewnętrzny dla firmy',
        [FacilityAuditVisibility.CUSTOMER_INTERNAL]: 'Wewnętrzny dla klienta',
      },
    },
    customers: {
      customers: 'Klienci',
      customer: 'Klient',
    },
    facilities: {
      facilities: 'Obiekty',
      facility: 'Obiekt',
    },
    templates: {
      editTemplate: 'Edytuj szablon',
      createTemplate: 'Stwórz szablon',
      templateName: 'Nazwa szablonu',
      templates: 'Szablony',
      addSuccess: 'Szablon został dodany',
      editSuccess: 'Szablon został zmodyfikowany',
      backgroundImageTitle: 'Tło',
      backgroundImageDesc:
        'Wybierz tło, które będzie wyświetlone na stronach PDF',
      pageType: 'Typ strony',
      pageTypeFile: 'Plik',
      pageTypeTextEditor: 'Edytor tekstowy',
      pageTypeInventory: 'Sprzęt',
      clickHereToAddPage: 'Kliknij tutaj aby dodać stronę',
    },
    inventory: {
      all: 'Wszystko',
      columns: 'Kolumny',
      currentOwner: 'Aktualny właściciel',
      group: 'Grupa',
      uniqueCode: 'Unikalny kod',
      nameTip: 'Na przykład: Kabel typu USB-C"',
      addRequest: 'Dodaj zapytanie',
      inventory: 'Sprzęt',
      requests: 'Zapytania',
      latest10actions: 'Ostatnie 10 akcji',

      take: 'Przypisz do siebie',
      giveBack: 'Zwróć',
      assignToUser: 'Przypisz do użytkownika',
      unassignUser: 'Zabierz od użytkownika',
      editItem: 'Edytuj sprzęt',
      selectGroup: 'Wybierz grupę',
      selectImage: 'Wybierz obrazek',
      createItem: 'Dodaj sprzęt',
      createSuccess: 'Sprzęt został dodany',
      editSuccess: 'Sprzęt został zmodyfikowany',
      removeSuccess: 'Sprzęt został usunięty',
      nameExample: 'e.g. iPhone 5',
      descriptionExample: 'e.g. Best item in the world',

      usability: {
        [INVENTORY_USABILITY.PUBLIC]: 'Publiczna',
        [INVENTORY_USABILITY.PRIVATE]: 'Prywatna',
      },
      priority: {
        [INVENTORY_PRIORITY.HIGH]: 'Wysoki',
        [INVENTORY_PRIORITY.MEDIUM]: 'Średni',
        [INVENTORY_PRIORITY.LOW]: 'Niski',
      },
      itemStatus: {
        [INVENTORY_ITEM_STATUS.FREE]: 'Wolny',
        [INVENTORY_ITEM_STATUS.TAKEN]: 'Zajęty',
      },
      status: {
        [INVENTORY_STATUS.CREATED]: 'Stworzone',
        [INVENTORY_STATUS.IN_PROGRESS]: 'W trakcie',
        [INVENTORY_STATUS.REJECTED]: 'Odrzucone',
        [INVENTORY_STATUS.CANCELED]: 'Anulowane',
        [INVENTORY_STATUS.DONE]: 'Zakończone',
      },
    },
    dashboard: {
      noItemsTaken: 'Brak przypisanego sprzętu.',
      yourTakenInventoryItems: 'Twój sprzęt',
      newAndInProgressRequests: 'Nowe & W trakcie zapytania',
      noNewRequests: 'Brak nowych zapytań o sprzęt',
    },
    files: {
      uploadFileTitle: 'Wrzuć plik',
      upload: 'Wrzuć',
      file: 'Plik',
      selectFile: 'Wybierz plik',
    },
    account: {
      avatar: 'Zdjęcie profilowe',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      extensions: 'Rozszerzenia',
      personalInformation: 'Informacje personalne',
      signOut: 'Wyloguj',
      emailAddress: 'Adres E-mail',
      phoneNumber: 'Numer telefonu',
    },
    auth: {
      signIn: 'Zaloguj się',
      emailAddress: 'Adres E-mail',
      password: 'Hasło',
      signInButton: 'Zaloguj',
      noAccount: 'Nie masz konta?',
      joinUs: 'Dołącz do nas',
      signInWithGoogle: 'Zaloguj z Google',
    },
    global: {
      somethingWentWrong: 'Coś poszło nie tak',
      order: 'Kolejność',
      key: 'Klucz',
      value: 'Wartość',
      add: 'Dodaj',
      image: 'Obrazek',
      metadata: 'Dodatkowe dane',
      create: 'Stwórz',
      edit: 'Edytuj',
      save: 'Zapisz',
      remove: 'Usuń',
      delete: 'Usuń',
      createdAt: 'Stworzone dnia',
      user: 'Użytkownik',
      name: 'Nazwa',
      amount: 'Ilość',
      download: 'Pobierz',
      description: 'Opis',
      list: 'Lista',
      you: 'Ty',
      status: 'Status',
      priority: 'Priorytet',
      usability: 'Użyteczność',
      actions: 'Akcje',

      reject: 'Odrzuć',
      cancel: 'Anuluj',
      done: 'Zakończ',
      accept: 'Zaakceptuj',
      typeToSearch: 'Wpisz aby wyszukać',
    },
    pdf: {
      list: 'Lista plików',
      findInventoryItem: 'Wybierz sprzęt',
      selectInventoryItem: 'Wpisz cokolwiek aby wyszukać sprzęt',
      page: 'Strona',
      template: 'Szablon',
      editPdfFile: 'Edytuj oferte',
      createPdf: 'Stwórz oferte',
      generate: 'Wygeneruj plik PDF',
      outputs: 'Ostatnie wygenerowane pliki',
      asNeeded: 'Według potrzeb',
      fileWillBeAdded: 'Plik PDF zostanie dodany do końcowego pliku PDF',
      renderPdfPage: 'Dołącz stronę do końcowego pliku pdf',
      isGenerating: 'Trwa generowanie...',
    },
    groups: {
      groups: 'Grupy',
      group: 'Grupa',
      editGroup: 'Edytuj grupę',
      createGroup: 'Stwórz grupę',
      groupExample: 'Na przykład: Odkurzacze',
      removeGroupTitle: 'Usuń grupę',
      removeGroupDescription: 'Czy na pewno chcesz usunąć grupę?',
      createSuccess: 'Grupa została dodana',
      editSuccess: 'Grupa została zmodyfikowana',
      removeSuccess: 'Grupa została usunięta',
    },
    navigation: {
      dashboard: 'Dashboard',
      files: 'Pliki',
      pdf: 'Oferty',
      pdfTemplates: 'Szablony',
      inventory: 'Sprzęt',
      users: 'Użytkownicy',
      company: 'Firma',
      admin: 'Administracja',
      groups: 'Grupy',
      requests: 'Zapytania',
      invitations: 'Zaproszenia',
      facilities: 'Obiekty',
      customers: 'Klienci',
    },
    user: {
      permissions: {
        USERS_LIST: 'Użytkownicy - Lista',
        USERS_INVITE: 'Użytkownicy - Zaproszenia',
        USERS_EDIT: 'Użytkownicy - Edycja',
        USERS_REMOVE: 'Użytkownicy - Usuwanie',
        USERS_INVITATIONS_LIST: 'Zaproszenia użytkowniów - Lista',
        USERS_INVITATIONS_INVITE: 'Zaproszenia użytkowniów - Zapraszanie',
        USERS_INVITATIONS_REMOVE: 'Zaproszenia użytkowniów - Usuwanie',
        INVENTORY_ITEM_CREATE: 'Zasoby - Tworzenie',
        INVENTORY_ITEM_LIST: 'Zasoby - Lista',
        INVENTORY_ITEM_REMOVE: 'Zasoby - Usuwanie',
        INVENTORY_ITEM_EDIT: 'Zasoby - Edycja',
        INVENTORY_ITEM_EDIT_UNIQUE_ID: 'Zasoby - Edycja unikatowego ID',
        INVENTORY_ITEM_ASSIGN_TO_USER: 'Zasoby - Przypisanie do użytkownika',
        INVENTORY_ITEM_TAKE: 'Zasoby - Przypisanie',
        INVENTORY_GROUP_CREATE: 'Grupa zasobów - Tworzenie',
        INVENTORY_GROUP_LIST: 'Grupa zasobów - Lista',
        INVENTORY_GROUP_REMOVE: 'Grupa zasobów - Usuwanie',
        INVENTORY_GROUP_EDIT: 'Grupa zasobów - Edycja',
        INVENTORY_REQUEST_ADD: 'Prośby zasobów - Zapytanie',
        INVENTORY_REQUEST_MANAGE: 'Prośby zasobów - Zarządzanie',
        INVENTORY_REQUEST_LIST_ALL: 'Prośby zasobów - Lista',
        COMPANY_EDIT: 'Firma - Edycja',
        COMPANY_DETAILS: 'Firma - Detale',

        PDF_LIST_ALL: 'Dokumenty pdf - Wszystkich użytkownikow',
        PDF_LIST: 'Dokumenty pdf - Lista',
        PDF_CREATE: 'Dokumenty pdf - Tworzenie',
        PDF_EDIT: 'Dokumenty pdf - Edycja',
        PDF_DELETE: 'Dokumenty pdf - Usuwanie',

        PDF_TEMPLATE_LIST: 'Szablony pdf - Lista',
        PDF_TEMPLATE_CREATE: 'Szablony pdf - Dodawanie',
        PDF_TEMPLATE_EDIT: 'Szablony pdf - Edycja',
        PDF_TEMPLATE_DELETE: 'Szablony pdf - Usuwanie',

        FILES_LIST: 'Pliki - Lista',
        FILES_CREATE: 'Pliki - Dodawanie',
        FILES_EDIT: 'Pliki - Edycja',
        FILES_DELETE: 'Pliki - Usuwanie',
      },
    },
  },
};

export const LANGUAGE_STORAGE_KEY = 'language';
export interface Translation {
  [key: string]: any;
}
class TranslationService {
  defaultLanguage = LANG.PL;

  constructor() {
    const lang = this.getCurrentLanguage();
    if (!lang) {
      this.setCurrentLanguage(this.defaultLanguage);
    }
  }

  setCurrentLanguage = (lang: any) => {
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
    moment.locale(LanguageToMoment[lang]);
  };

  getCurrentLanguage = () => {
    return (
      localStorage.getItem(LANGUAGE_STORAGE_KEY) ||
      getAppConfiguration().defaultLang
    );
  };

  getTranslations = () => {
    const lang = this.getCurrentLanguage();
    moment.locale(LanguageToMoment[lang]);
    return TRANSLATIONS[lang] as Translation;
  };

  changeLanguage = (lang: LANG) => {
    this.setCurrentLanguage(lang);
    window.location.reload();
  };
}

export const translationService = new TranslationService();
