import axios from 'axios';
import { auth } from 'shared/services/firebase';

class ApiService {
  selectedCompany: string | undefined = undefined;
  instance = axios.create({
    // baseURL: 'https://v2.backend.procleaner.cloud/api/'
    // baseURL:'https://backend-prod-eb7qcfkb3a-ew.a.run.app/api/' 
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  });

  setCompany = (companyId: string) => {
    this.selectedCompany = companyId;
    this.instance.interceptors.request.use(
      async (config) => {
        config.headers['company-id'] = companyId;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  setup() {
    this.instance.interceptors.request.use(
      async (config) => {
        const token = await auth?.currentUser?.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export const apiService = new ApiService();
apiService.setup();
export default apiService.instance;
