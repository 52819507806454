import yup from 'shared/services/yup';

const exerciceValidation = (y: typeof yup) =>
  y.object().shape({
    name: yup.string().required(),
    metaData: yup.array().of(
      yup.object().shape({
        // @ts-ignore,
        key: yup.object().nullable().isCorrectOption(),
        value: yup.string().required().typeError('Must be filled').min(1),
      })
    ),
  });

export default exerciceValidation(yup);
