import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from '@mantine/core';
import { useHistory } from 'react-router';
import ROUTES from 'shared/constants/routes';
import api from 'shared/services/api';

interface Props {
  show: boolean;
  onClose: Function;
  pdfId: string;
}

const DeletePdfModal = (props: Props) => {
  const { show, onClose, pdfId } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const onSave = async () => {
    try {
      setLoading(true);
      await api.delete(`/pdf/${pdfId}`);
      history.replace(ROUTES.pdf.list.path);
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Usuń ofertę PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>Czy napewno chcesz usunąć tą ofertę?</Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          Anuluj
        </Button>
        <Button color="red" onClick={onSave} loading={isLoading}>
          Tak, usuń
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeletePdfModal;
