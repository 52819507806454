import React from 'react';
import ErrorBg from 'assets/img/error_bg.jpg';

const GlobalError = () => (
  <div className="d-flex flex-column flex-root vh-100">
    <div
      className="error error-6 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      style={{
        backgroundImage: `url(${ErrorBg})`,
      }}
    >
      <div className="d-flex flex-column flex-row-fluid text-center">
        <h1
          className="error-title font-weight-boldest text-white mb-12"
          style={{ marginTop: '12rem', fontSize: '10rem' }}
        >
          Oops...
        </h1>
        <p className="display-4 font-weight-bold text-white">
          Houston we've got a problem. Our team is doing best they can to solve
          it.
        </p>
      </div>
    </div>
  </div>
);
export default GlobalError;
