import yup from 'shared/services/yup';

const exerciceValidation = (y: typeof yup) =>
  y.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    sets: yup.array().of(
      yup.object().shape({
        sets: yup.number().typeError('Musi być liczbą').integer(),
        reps: yup.number().typeError('Musi być liczbą').integer(),
        rest: yup.string().isCorrectTime(),
        duration: yup.string().isCorrectTime(),
      })
    ),
  });

  export default exerciceValidation(yup)