import api from 'shared/services/api';
import { CompanyProduct, CompanyProductBody } from './types';
// Orders
export const getCompanyProduct = (companyProductId: string) =>
  api.get<{ customer: CompanyProduct }>(`/company/products/${companyProductId}`);

export const getCompanyProducts = () =>
  api.get<{ rows: CompanyProduct[] }>(`/company/products`);

export const patchCompanyProduct = (companyProductId: string, body: CompanyProductBody) =>
  api.patch(`/company/products/${companyProductId}`, body);

export const postCompanyProduct = (body: CompanyProductBody) =>
  api.post(`/company/products`, body);

export const deleteCompanyProduct = (companyProductId: string) =>
  api.delete(`/company/products/${companyProductId}`);
