import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { useHistory, useParams } from 'react-router';
import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import { toast } from 'react-toastify';

import { getUser, patchUser } from 'shared/services/api/users';
interface Params {
  id: string;
}
export default function EditExercice() {
  const { id } = useParams<Params>();
  const { data, isLoading, isLoaded, error } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getUser(id),
  });
  const [isSaving, setSaving] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, reset, control, watch } = useForm({
    defaultValues: {
      roles: {},
      status: '',
      type: '',
    },
  });

  useEffect(() => {
    const roles = {};

    data.user?.roles.forEach((role) => (roles[role.role] = true));

    reset({ roles, status: data.user?.status, type: data.user?.type });
  }, [data, reset]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      const roles = keys(pickBy(data.roles, identity));
      await patchUser(id, {
        roles,
        status: data.status,
        type: data.type,
      });
      // toast.success('User has been modified');
      setSaving(false);
      history.push(ROUTES.users.details.to(id));
    } catch (e) {
      toast.error('Something went wrong');
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.users.list.path }}
          linkAs={Link}
        >
          Użytkownicy
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {data.user?.firstName} {data.user?.lastName}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Edytuj</Breadcrumb.Item>
      </Breadcrumb>
      {/* <RemoveExerciceModal
        show={removeModal}
        onClose={() => setRemoveModal(false)}
        exercice={data.item}
      /> */}
      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && <div className="alert alert-danger">Brak Użytkownika</div>}
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                title="Edytuj użytkownika"
                toolbar={
                  <>
                    <Button color="primary" isLoading={isSaving}>
                      Zapisz
                    </Button>
                  </>
                }
              >
                <ExerciceForm
                  user={data.user}
                  errors={errors}
                  register={register}
                  control={control}
                  watch={watch}
                />
              </Card>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
