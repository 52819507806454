import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';

import { toast } from 'react-toastify';
import validation from '../validation';
import { getCompany, patchCompany } from 'shared/services/api/admin';

interface Params {
  companyId: string;
}
export default function EditCompany() {
  const { companyId } = useParams<Params>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getCompany(companyId),
  });
  const [isSaving, setSaving] = useState(false);
  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    reset(data.company);
  }, [data, reset]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      await patchCompany(companyId, {
        name: data.name,
        usersLimit: data.usersLimit,
        inventoryItemsLimit: data.inventoryItemsLimit,
        status: data.status,
      });
      toast.success('Company has been updated');
      refresh();
      setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error('Coś poszło nie tak, spróbuj za chwilę.');
    }
  };
  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.admin.default.path }}
          linkAs={Link}
        >
          Admin
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.admin.companies.path }}
          linkAs={Link}
        >
          Companies
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.company?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && <div className="alert alert-danger">No company</div>}
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                title="Edytuj firmę"
                toolbar={
                  <>
                    <Button color="primary" isLoading={isSaving}>
                      Zapisz
                    </Button>
                  </>
                }
              >
                <ExerciceForm
                  errors={errors}
                  register={register}
                  control={control}
                />
              </Card>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
