import React from 'react';
import Card from 'shared/components/Card';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomers } from 'shared/services/api/customers';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import CreateCustomerModal from './CreateCustomerModal';
import { Customer } from 'shared/services/api/customers/types';
import EditCustomerModal from './EditCustomerModal';
import RemoveCustomerModal from './RemoveCustomerModal';
import { IconPencil, IconTrash } from '@tabler/icons';

const CustomersList = () => {
  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState<
    Customer | undefined
  >();
  const [removeCustomerModalOpen, setRemoveCustomerModalOpen] = useState<
    Customer | undefined
  >();
  const { data, error, isLoading, refresh } = useFetch<{ rows: Customer[] }>({
    initialValue: {},
    fetchAction: () => getCustomers(),
  });
  const translations = useTranslations();

  const handleOnCreate = () => {
    setCreateCustomerModalOpen(false);
    refresh();
  };

  const handleOnEdit = () => {
    setEditCustomerModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveCustomerModalOpen(undefined);
    refresh();
  };

  const handleEdit = (customer: Customer) => () => {
    setEditCustomerModalOpen(customer);
  };
  const handleRemove = (customer: Customer) => () => {
    setRemoveCustomerModalOpen(customer);
  };

  return (
    <div className="container">
      <CreateCustomerModal
        open={createCustomerModalOpen}
        onClose={() => setCreateCustomerModalOpen(false)}
        onSuccess={handleOnCreate}
      />
      <EditCustomerModal
        customer={editCustomerModalOpen!}
        open={!!editCustomerModalOpen}
        onClose={() => setEditCustomerModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveCustomerModal
        customer={removeCustomerModalOpen!}
        open={!!removeCustomerModalOpen}
        onClose={() => setRemoveCustomerModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.users.default.path }}
          linkAs={Link}
        >
          {translations.customers.customers}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{translations.global.list}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title={translations.customers.customers}
            toolbar={
              <Button onClick={() => setCreateCustomerModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj klienta
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.rows?.map((customer: any) => (
                  <tr key={customer.id}>
                    <td>
                      <Link to={ROUTES.customers.details.to(customer.id)}>
                        {customer?.name}
                      </Link>
                    </td>

                    <td>{formatDate(customer.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(customer)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(customer)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                      {/* <Button
                        type="button"
                        color="light"
                        className="btn-sm btn-icon mr-5 ml-auto"
                        onClick={handleEdit(customer)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        className="btn-sm btn-icon"
                        onClick={handleRemove(customer)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CustomersList;
