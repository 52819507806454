import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getAreaTypes } from 'shared/services/api/company/areaTypes';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import { AreaType } from 'shared/services/api/company/areaTypes/types';
import CreateAreaTypeModal from './CreateCompanyAreaTypeModal';
import EditAreaTypeModal from 'modules/Company/AreaTypes/List/EditCompanyAreaTypeModal';
import RemoveAreaTypeModal from 'modules/Company/AreaTypes/List/RemoveAreaTypeModal';
import { IconPencil, IconTrash } from '@tabler/icons';

const CompanyAreaTypes = () => {
  const { facilityId } = useParams<{
    areaTypeId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: AreaType[];
  }>({
    initialValue: {},
    fetchAction: () => getAreaTypes(),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const [editAreaTypeModalOpen, setEditAreaTypeModalOpen] = useState<
    AreaType | undefined
  >();
  const [removeAreaTypeModalOpen, setRemoveAreaTypeModalOpen] = useState<
    AreaType | undefined
  >();

  const handleOnEdit = () => {
    setEditAreaTypeModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveAreaTypeModalOpen(undefined);
    refresh();
  };

  const handleEdit = (areaType: AreaType) => () => {
    setEditAreaTypeModalOpen(areaType);
  };
  const handleRemove = (areaType: AreaType) => () => {
    setRemoveAreaTypeModalOpen(areaType);
  };
  return (
    <div className="">
      <CreateAreaTypeModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
      />
      <EditAreaTypeModal
        areaType={editAreaTypeModalOpen!}
        open={!!editAreaTypeModalOpen}
        onClose={() => setEditAreaTypeModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveAreaTypeModal
        areaType={removeAreaTypeModalOpen!}
        open={!!removeAreaTypeModalOpen}
        onClose={() => setRemoveAreaTypeModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Typy stref'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj typ strefy
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Zadania w audycie</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((areaType) => (
                  <tr key={areaType.id}>
                    <td>{areaType.name}</td>
                    {/* <td>{areaType.description}</td> */}
                    <td>
                      {areaType.auditTasks?.map((task) => (
                        <div key={task.id}>- {task.name}</div>
                      ))}
                    </td>
                    <td>{formatDate(areaType.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(areaType)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(areaType)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompanyAreaTypes;
