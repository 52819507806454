import React, { useState } from 'react';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { Breadcrumb } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import InviteCompanyModal from '../InviteCompanyModal';
import { getCompaniesList } from 'shared/services/api/admin';
import {
  CompaniesListResponse,
  Company,
} from 'shared/services/api/admin/types';
import { formatDate } from 'shared/utils/time';

export default function CompaniesList() {
  const [selectedInvitation, setSelectedInvitation] = useState<
    undefined | Company
  >();
  const history = useHistory();
  const [manageModal, setManageModal] = useState<boolean>(false);
  const { data: responseData, refresh } = useFetch<CompaniesListResponse>({
    initialValue: {},
    fetchAction: () => getCompaniesList(),
  });
  const data = responseData.companies; // TODO

  const handleEdit = (company: Company) => () => {
    history.push(ROUTES.admin.companies.edit.to(company.id));
    // setSelectedInvitation(company);
    // setManageModal(true);
  };
  const handleCreate = () => {
    setSelectedInvitation(undefined);
    setManageModal(true);
  };

  const onClose = (shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      refresh();
    }
    setSelectedInvitation(undefined);
    setManageModal(false);
  };
  return (
    <div className="container">
      <InviteCompanyModal
        show={manageModal}
        company={selectedInvitation}
        onClose={onClose}
      />
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.admin.default.path }}
          linkAs={Link}
        >
          Admin
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Companies</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title="Companies"
            toolbar={
              <Button color="primary" onClick={handleCreate}>
                Add company
              </Button>
            }
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Created At</th>
                  <th>Inventory items</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((company) => (
                  <tr key={company.id}>
                    <td>{company.name}</td>
                    <td>{company.status}</td>
                    <td>
                      {company.users?.length || 0}/{company.inventoryItemsLimit}
                    </td>
                    <td>
                      {company.inventoryItems?.length || 0}/{company.usersLimit}
                    </td>
                    <td>{formatDate(company.createdAt)}</td>
                    <td>
                      <Button
                        type="button"
                        color="light"
                        className="btn-sm btn-icon mr-5 ml-auto"
                        onClick={handleEdit(company)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
}
