import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Dropdown, Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import useFetch from 'shared/hooks/useFetch';
import store from 'store';
import {
  getInventoryGroups,
  getInventoryItems,
} from 'shared/services/api/inventory';
import UserContext from 'shared/contexts/UserContext';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import CheckboxField from 'shared/components/CheckboxField';
import styles from './styles.module.scss';
import TextField from 'shared/components/TextField';
import { formatDate } from 'shared/utils/time';
import { StringParam, useQueryParam } from 'use-query-params';
import { useTranslations } from 'shared/hooks/useTranslations';
import { Image } from '@mantine/core';

const getFiltered = (array: any[] = [], filters: any) => {
  let arrayCopy = [...array];
  if (filters.owner.length > 0) {
    arrayCopy = arrayCopy.filter((item) =>
      filters.owner.includes(item.owner?.id)
    );
  }
  if (filters.status?.length > 0) {
    arrayCopy = arrayCopy.filter((item) =>
      filters.status.includes(item.status)
    );
  }

  return arrayCopy;
};

export default function ExerciceList() {
  const translations = useTranslations();
  const [qsCurrentTab, setCurrentTab] = useQueryParam(
    'currentTab',
    StringParam
  );
  const currentTab = qsCurrentTab || 'ALL';
  const [searchValue, setSearchValue] = useQueryParam('qs', StringParam);
  const handleCurrentTab = (data: any) => {
    setCurrentTab(data);
  };
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [filters] = useState<{ owner: string[]; status: string[] }>({
    owner: [],
    status: [],
  });
  const [activeDynamicColumns, setActiveDynamicColumns] = useState(
    store.get('INVENTORY_ITEMS_DYNAMIC_COLUMNS') || []
  );

  const handleSetActiveDynamicColumns = (data: any) => {
    store.set('INVENTORY_ITEMS_DYNAMIC_COLUMNS', data);
    setActiveDynamicColumns(data);
  };
  const { data, isLoading, isLoaded } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryItems(),
  });
  const { data: categoriesData } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getInventoryGroups(),
  });
  const history = useHistory();

  const onAdd = () => {
    history.push(ROUTES.inventory.items.create.path);
  };
  const onRowClick = (id: number) => () => {
    history.push(ROUTES.inventory.items.details.to(id));
  };
  const categoriesFromApi = useMemo(
    () =>
      categoriesData.groups
        ? categoriesData.groups.map((category) => ({
            id: category.id,
            name: `${category.name} (${category.items?.length || 0})`,
          }))
        : [],
    [categoriesData.groups]
  );

  useEffect(() => {
    if (categoriesFromApi.length > 0) {
      setCurrentTab(categoriesFromApi[0].id);
    }
  }, [categoriesFromApi, setCurrentTab]);
  const groups = [
    ...categoriesFromApi,
    {
      id: 'ALL',
      name: `${translations.inventory.all} (${data.items?.length || 0})`,
    },
  ];
  const tabs = groups.map((category) => ({
    id: String(category.id),
    label: category.name,
  }));
  const filteredExercices =
    currentTab === 'ALL'
      ? data?.items
      : data.items?.filter((exercice) => {
          const cs = exercice.groups?.map((category) =>
            String(category.id).toLowerCase()
          );
          return currentTab && cs?.includes(String(currentTab).toLowerCase());
        });
  const filteredBySearch = searchValue
    ? filteredExercices?.filter((item) => {
        if (
          item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.uniqueId?.includes(searchValue)
        ) {
          return true;
        }
        const metaData = item.data?.filter((d) =>
          d.value.toLowerCase().includes(searchValue.toLowerCase())
        );
        return metaData?.length || false;
      })
    : filteredExercices;
  const filtered = getFiltered(filteredBySearch, filters);
  // const colorByStatus = {
  //   FREE: 'success',
  //   TAKEN: 'info',
  // };
  useEffect(() => {
    const possibleColumns =
      uniqBy(
        flatMap(data.items, (item) => item.data).map((data) => data.key),
        (d) => d
      ) || [];
    // @ts-ignore
    setDynamicColumns(possibleColumns);
  }, [data.items]);
  // @ts-ignore
  const getValueByKey = (key, item) =>
    item.data.find((d) => d.key === key)?.value || '-';

  const handleFilter = (event: any) => {
    setSearchValue(event?.target.value);
  };
  // const filteredOwners = (data.items?.map((item) => item.owner) || []).filter(
  //   (item) => !!item
  // ) as { firstName: string; lastName: string; id: string }[];
  //
  // const owners = uniqBy(filteredOwners, 'id');

  // const filteredStatus = (data.items?.map((item) => item.status) || []).filter(
  //   (item) => !!item
  // ) as string[];
  // //

  // const statuses = uniqBy(filteredStatus, (d) => d);
  return (
    <UserContext.Consumer>
      {({ data: user }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.inventory.items.list.path }}
              linkAs={Link}
            >
              {translations.inventory.inventory}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{translations.global.list}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="row">
            <div className="col-xl-12">
              <Card
                title={
                  <div className={styles.search}>
                    {translations.inventory.inventory}{' '}
                    <TextField
                      name="search"
                      defaultValue={searchValue}
                      placeholder={translations.global.typeToSearch}
                      className={styles.searchInput}
                      onChange={handleFilter}
                    />
                  </div>
                }
                toolbar={
                  <>
                    <Dropdown style={{ marginRight: '15px' }}>
                      <Dropdown.Toggle
                        variant="success"
                        className={styles.columnsButton}
                      >
                        {translations.inventory.columns}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className={styles.checkboxList}>
                          {uniqBy(
                            [...dynamicColumns, ...activeDynamicColumns],
                            (i) => i
                          ).map((k) => (
                            <CheckboxField
                              label={k}
                              className={styles.checkbox}
                              name={`column[${k}]`}
                              defaultValue={activeDynamicColumns.includes(k)}
                              onChange={(v) =>
                                handleSetActiveDynamicColumns(
                                  v.target.checked
                                    ? [...activeDynamicColumns, k]
                                    : activeDynamicColumns.filter(
                                        (a) => a !== k
                                      )
                                )
                              }
                            />
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_CREATE]}>
                      <Button onClick={onAdd} color="primary">
                        {translations.global.create}
                      </Button>
                    </WithRole>
                  </>
                }
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {tabs.map((tab) => (
                    <li
                      className="nav-item"
                      key={tab.id}
                      role="button"
                      onClick={() => handleCurrentTab(tab.id)}
                    >
                      <span
                        className={`nav-link ${
                          tab.id === currentTab ? 'active' : ''
                        }`}
                        id="home-tab"
                      >
                        <span className="nav-text">{tab.label}</span>
                      </span>
                    </li>
                  ))}
                </ul>
                {isLoading && (
                  <div>
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}

                {isLoaded && (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{translations.global.name}</th>
                        <th>{translations.inventory.group}</th>
                        <th>Kolejność</th>
                        <th>{translations.global.createdAt}</th>
                        {/* <th>
                          <Dropdown style={{ marginRight: '15px' }}>
                            <Dropdown.Toggle
                              variant="light"
                              size="sm"
                              className={styles.columnsButton}
                            >
                              {translations.inventory.currentOwner}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className={styles.checkboxList}>
                                {map(owners, (owner) => (
                                  <CheckboxField
                                    label={`${owner.firstName} ${owner?.lastName}`}
                                    className={styles.checkbox}
                                    name={`owner[${owner?.id}]`}
                                    onChange={(v) => {
                                      if (v.target.checked) {
                                        setFilters({
                                          ...filters,
                                          owner: [...filters.owner, owner.id],
                                        });
                                      } else {
                                        setFilters({
                                          ...filters,
                                          owner: [...filters.owner].filter(
                                            (a) => a !== owner.id
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </th> */}
                        {activeDynamicColumns.map((col) => (
                          <th key={`th-${col}`}>{col}</th>
                        ))}
                        {/* <th>
                          <Dropdown style={{ marginRight: '15px' }}>
                            <Dropdown.Toggle
                              variant="light"
                              size="sm"
                              className={styles.columnsButton}
                            >
                              {translations.global.status}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className={styles.checkboxList}>
                                {map(statuses, (status) => (
                                  <CheckboxField
                                    label={status}
                                    className={styles.checkbox}
                                    name={`status[${status}]`}
                                    onChange={(v) => {
                                      if (v.target.checked) {
                                        setFilters({
                                          ...filters,
                                          status: [...filters.status, status],
                                        });
                                      } else {
                                        setFilters({
                                          ...filters,
                                          status: [...filters.status].filter(
                                            (a) => a !== status
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                ))}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {filtered.map((exercice) => (
                        <tr
                          key={exercice.id}
                          onClick={onRowClick(exercice.id)}
                          role="button"
                        >
                          <td>
                            <Image
                              src={exercice.imageUrl}
                              width={50}
                              height={50}
                              withPlaceholder
                            />
                          </td>
                          <td>{exercice.name}</td>
                          <td>
                            {exercice.groups?.map((category) => (
                              <div key={`e-${exercice.id}-${category.id}`}>
                                {category.name}
                              </div>
                            ))}
                          </td>
                          <td>{exercice.order}</td>
                          <td>{formatDate(exercice.createdAt)}</td>
                          {/* <td>
                            {exercice.owner?.firstName}{' '}
                            {exercice.owner?.lastName}{' '}
                            {exercice.owner?.id === user.id ? (
                              <span>(you)</span>
                            ) : null}
                          </td> */}
                          {activeDynamicColumns.map((col) => (
                            <td key={`${col}-${exercice.id}`}>
                              {getValueByKey(col, exercice)}
                            </td>
                          ))}
                          {/* <td>
                            <Badge
                              color={colorByStatus[exercice.status] || 'light'}
                            >
                              {translations.inventory.itemStatus[
                                exercice.status
                              ] || exercice.status}
                            </Badge>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Card>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
