import { Alert, Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import FileAccessForm from 'shared/components/FileAccessForm';
import useFetch from 'shared/hooks/useFetch';

import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomerUsers } from 'shared/services/api/customers';
import { postCustomerCustomerFolder } from 'shared/services/api/customers/files';
import { CustomerUser } from 'shared/services/api/customers/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  folderId?: string;
}
const CreateFilesFolderModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, folderId } = props;
  const form = useForm({
    initialValues: {
      name: '',
      visibility: 'PUBLIC',
      users: [],
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const { data } = useFetch<{
    rows: CustomerUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerUsers(customerId),
  });

  const handleSubmit = async (values) => {
    try {
      setError(null);
      setLoading(true);
      await postCustomerCustomerFolder(customerId, {
        name: values.name,
        parentFolderId: folderId,
        visibility: values.visibility,
        users: values.users,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj folder">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            // TODO: Translations
            label="Nazwa folderu"
            placeholder=""
            {...form.getInputProps('name')}
          />

          <FileAccessForm form={form} users={data.rows || []} customer />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              {translations.global.add}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateFilesFolderModal;
