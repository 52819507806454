import api from 'shared/services/api';
import {} from './types';

export const getUsers = () => api.get('/users');

export const getUsersInvitations = () => api.get('/users/invitations');

export const postUserInvitation = (email: string) =>
  api.post('/users/invitations', { email });

export const patchUser = (companyUserId: string, body: any) =>
  api.patch(`/users/${companyUserId}`, body);

export const getUser = (id: string) => api.get(`/users/${id}`);

export const patchUserInvitation = (email: string, body: any) =>
  api.patch(`/admin/invitations/${email}`, body);

export const deleteUserInvitation = (id: string) =>
  api.patch(`/users/invitations/${id}`);
