/**
 * src/Login.js
 */
import React from 'react';
import Button from 'shared/components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import TextField from 'shared/components/TextField';
import Card from 'shared/components/Card';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

interface Props {
  onFinish: (formValues: any) => void;
  defaultValues: any;
  isLoading?: boolean;
}
export default function CompleteRegistration(props: Props) {
  const { onFinish, defaultValues, isLoading } = props;
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    // @ts-ignore
    defaultValues,
  });
  const onSubmit = async (data: any) => {
    onFinish(data);
  };

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div
        className="login login-4 login-signin-on d-flex flex-row-fluid"
        id="kt_login"
      >
        <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              title="Dokończ rejestrację"
              footer={
                <>
                  <Button
                    color="primary"
                    className="btn btn-primary mr-2"
                    isLoading={isLoading}
                  >
                    Dokończ
                  </Button>
                </>
              }
            >
              <p>Brakuje nam Twoich danych</p>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    name="firstName"
                    ref={register}
                    label="Imię"
                    error={errors.firstName}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    name="lastName"
                    ref={register}
                    label="Nazwisko"
                    error={errors.lastName}
                    required
                  />
                </div>
              </div>
            </Card>
          </form>
        </div>
      </div>
    </div>
  );
}
