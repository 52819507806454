import api from 'shared/services/api';
import { CompanyService, CompanyServiceBody } from './types';
// Orders
export const getCompanyService = (companyServiceId: string) =>
  api.get<{ customer: CompanyService }>(
    `/company/services/${companyServiceId}`
  );

export const getCompanyServices = () =>
  api.get<{ rows: CompanyService[] }>(`/company/services`);

export const patchCompanyService = (
  companyServiceId: string,
  body: CompanyServiceBody
) => api.patch(`/company/services/${companyServiceId}`, body);

export const postCompanyService = (body: CompanyServiceBody) =>
  api.post(`/company/services`, body);

export const postCompanyServiceComment = (
  companyServiceId: string,
  body: {
    description: string;
  }
) => api.post(`/company/services/${companyServiceId}/comments`, body);

export const deleteCompanyService = (companyServiceId: string) =>
  api.delete(`/company/services/${companyServiceId}`);
