import React from 'react';
import { UseFormReturnType } from '@mantine/form';
import ContentLoader from 'shared/components/ContentLoader';
import styles from './styles.module.scss';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { Group, Radio, Select, TextInput } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { v4 as uuid } from 'uuid';
import { IconPlus } from '@tabler/icons';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  form: UseFormReturnType<any>;
  files: any[];
}

const DragHandle = SortableHandle(() => <span>::</span>);

const SortableItem = SortableElement(
  ({ page, pageIndex, form, files, handleDelete, translations }) => (
    <div className={styles.page} key={pageIndex}>
      <DragHandle />
      <div className={styles.pageInner}>
        <div
          className={styles.pageDelete}
          onClick={() => handleDelete(pageIndex)}
        >
          <i className="fas fa-trash"></i>
        </div>
        <TextInput
          defaultValue={page.title}
          mb={10}
          label={translations.global.name}
          {...form.getInputProps(`configuration.pages.${pageIndex}.name`)}
        />
        <Select
          defaultValue={page.type}
          mb={10}
          label={translations.templates.pageType}
          data={[
            { label: translations.templates.pageTypeFile, value: 'FILE' },
            { label: 'Plik wybrany w PDF', value: 'CUSTOM_FILE' },
            {
              label: translations.templates.pageTypeTextEditor,
              value: 'WYSYWIG',
            },
            {
              label: 'Wartość usługi',
              value: 'SERVICE_VALUE',
            },
            {
              label: translations.templates.pageTypeInventory,
              value: 'INVENTORY',
            },
          ]}
          {...form.getInputProps(`configuration.pages.${pageIndex}.type`)}
        />
        <Radio.Group
          label="Domyślnie aktywny"
          mt={20}
          defaultValue="YES"
          {...form.getInputProps(`configuration.pages.${pageIndex}.isActive`)}
        >
          <Group mt="xs">
            <Radio value="YES" label="Tak" />
            <Radio value="NO" label="Nie" />
          </Group>
        </Radio.Group>
        {page?.type === 'FILE' && (
          <Select
            label={translations.templates.pageTypeFile}
            data={
              files?.map((file) => ({
                label: file.name,
                value: file.id,
              })) || []
            }
            {...form.getInputProps(`configuration.pages.${pageIndex}.fileId`)}
          />
        )}
        <div className={styles.pagePageIndicator}>{pageIndex + 1}</div>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ fields, form, files, handleAddPage, handleDelete, translations }) => {
    return (
      <div className={styles.pagesWrapper}>
        {fields?.map((page, index) => {
          return (
            <SortableItem
              key={index}
              index={index}
              pageIndex={index}
              form={form}
              files={files}
              page={page}
              handleDelete={handleDelete}
              translations={translations}
            />
          );
        })}
        <div
          className={`${styles.page} ${styles.addPage}`}
          onClick={handleAddPage}
        >
          <IconPlus style={{ opacity: 0.5, fontSize: '30px' }} size={60} />
          <span style={{ color: 'black', marginTop: '20px' }}>
            {translations.templates.clickHereToAddPage}
          </span>
        </div>
      </div>
    );
  }
);

export default function PdfTemplateForm(props: Props) {
  const { form, files } = props;

  const handleAddPage = () => {
    form.insertListItem('configuration.pages', {
      title: 'Page',
      active: false,
      key: randomId(),
      id: uuid(),
    });
  };

  const handleDelete = (index) => {
    form.removeListItem('configuration.pages', index);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    form.reorderListItem('configuration.pages', {
      from: oldIndex,
      to: newIndex,
    });
  };
  const translations = useTranslations();
  return (
    <ContentLoader isLoading={false}>
      <div className="row">
        <div className="col-md-3">
          <TextInput
            name="name"
            label={translations.templates.templateName}
            required
            {...form.getInputProps(`name`)}
          />
          <Select
            mt={20}
            name={`configuration.pageBackgroundFileId`}
            label={translations.templates.backgroundImageTitle}
            description={translations.templates.backgroundImageDesc}
            defaultValue={form.values?.configuration?.pageBackgroundFileId}
            data={
              files?.map((file) => ({
                label: file.name,
                value: file.id,
              })) || []
            }
            {...form.getInputProps(`configuration.pageBackgroundFileId`)}
          />
          <Radio.Group
            name="configuration.isDefault"
            label="Domyślny"
            mt={20}
            defaultValue="NO"
            {...form.getInputProps(`configuration.isDefault`)}
          >
            <Group mt="xs">
              <Radio value="YES" label="Tak" />
              <Radio value="NO" label="Nie" />
            </Group>
          </Radio.Group>
          
          <Radio.Group
            name="configuration.isActive"
            label="Aktywny"
            mt={20}
            defaultValue="YES"
            {...form.getInputProps(`configuration.isActive`)}
          >
            <Group mt="xs">
              <Radio value="YES" label="Tak" />
              <Radio value="NO" label="Nie" />
            </Group>
          </Radio.Group>
        </div>
        <div className="col-md-9">
          <SortableList
            fields={form.values?.configuration?.pages || []}
            form={form}
            files={files}
            handleAddPage={handleAddPage}
            handleDelete={handleDelete}
            onSortEnd={onSortEnd}
            useDragHandle
            // onSortMove={onSortEnd}
            translations={translations}
            axis={'xy'}
          />
        </div>
      </div>
    </ContentLoader>
  );
}
