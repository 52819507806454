import { NavLink } from 'react-router-dom';

export const LeftNavLinkItem = ({ to, icon, text, exact = false }) => (
  <NavLink to={to} className="navi-link py-4" activeClassName="active" exact={exact}>
    <i className={`${icon} navi-icon mr-2`}></i>
    <span className="navi-text font-size-lg">{text}</span>
  </NavLink>
);

export default LeftNavLinkItem;
