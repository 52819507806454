import React, { useState } from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Badge from 'shared/components/Badge';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import ROUTES from 'shared/constants/routes';
import UserContext from 'shared/contexts/UserContext';
import api from 'shared/services/api';
import { formatDate } from 'shared/utils/time';
import styles from './styles.module.scss';
import { FileInput, Group, SimpleGrid, TextInput } from '@mantine/core';
import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Tab {
  id: any;
  label: string;
}
interface File {
  name: string;
  id: string;
  createdAt: string | Date;
  user?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  status: any;
}

interface FilesResponse {
  files: File[];
}

const FilesList = () => {
  const tabs: Tab[] = [];
  const { data, isLoaded, isLoading, refresh } = useFetch<FilesResponse>({
    initialValue: {},
    fetchAction: () => api.get('/files'),
  });
  const currentTab = 'all';

  const handleCurrentTab = (id: any) => {};
  const onRowClick = (id: any) => {};

  const [isUploading, setUploading] = useState(false);
  const onSubmit = async (formValues) => {
    try {
      const formData = new FormData();
      // @ts-ignore
      formData.append('file', formValues.file[0]);
      formData.append('name', formValues.name);
      formData.append('description', formValues.description);
      setUploading(true);

      await api.post(`/files`, formData, {
        // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      refresh();
      form.reset();
    } catch (e) {
      toast.error('Coś poszło nie tak. Spróbuj ponownie później.');
    } finally {
      setUploading(false);
    }
  };

  const handleDownload = async (fileId: string) => {
    try {
      const { data } = await api.get(`/files/${fileId}/signed-url`);
      window.open(data.url, '_blank');
    } catch {}
  };
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      file: undefined,
    },
  });
  const translations = useTranslations();
  return (
    <div>
      <UserContext.Consumer>
        {({ data: user }) => (
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item
                linkProps={{ to: ROUTES.files.list.path }}
                linkAs={Link}
              >
                {translations.files?.uploadFileTitle}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {translations.global.list}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
              <div className="col-xl-12">
                <Card
                  title={
                    <div className={styles.search}>
                      {translations.files.uploadFileTitle}
                    </div>
                  }
                >
                  <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <SimpleGrid cols={4}>
                      <TextInput
                        withAsterisk
                        required
                        label={translations.global.name}
                        {...form.getInputProps('name')}
                        mb={5}
                      />
                      <TextInput
                        label={translations.global.description}
                        {...form.getInputProps('description')}
                        mb={5}
                      />

                      <FileInput
                        multiple
                        accept={[...PDF_MIME_TYPE, ...IMAGE_MIME_TYPE].join(
                          ','
                        )}
                        label={translations.files.file}
                        placeholder={translations.files.selectFile}
                        required
                        {...form.getInputProps('file')}
                      />
                      <Group position="right" mt="md">
                        <Button
                          type="submit"
                          color="light"
                          isLoading={isUploading}
                        >
                          {translations.files.upload}
                        </Button>
                      </Group>
                    </SimpleGrid>
                  </form>
                </Card>
                <Card
                  title={
                    <div className={styles.search}>
                      {' '}
                      {translations.global.list}{' '}
                    </div>
                  }
                >
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((tab) => (
                      <li
                        className="nav-item"
                        key={tab.id}
                        role="button"
                        onClick={() => handleCurrentTab(tab.id)}
                      >
                        <span
                          className={`nav-link ${
                            tab.id === currentTab ? 'active' : ''
                          }`}
                          id="home-tab"
                        >
                          <span className="nav-text">{tab.label}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  {isLoading && (
                    <div>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}

                  {isLoaded && (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>{translations.global.name}</th>
                          <th>{translations.global.createdAt}</th>
                          <th>{translations.global.user}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.files?.map((file) => (
                          <tr
                            key={file.id}
                            onClick={() => onRowClick(file.id)}
                            role="button"
                          >
                            <td>{file.name}</td>

                            <td>{formatDate(file.createdAt)}</td>
                            <td>
                              {file.user?.firstName} {file.user?.lastName}{' '}
                              {file.user?.id === user.id ? (
                                <span>({translations.global.you})</span>
                              ) : null}
                            </td>

                            <td>
                              <Badge color={'light'}>{file.status}</Badge>
                            </td>
                            <td>
                              <Button
                                color={'light'}
                                onClick={() => handleDownload(file.id)}
                              >
                                {translations.global.download}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Card>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default FilesList;
