import api from 'shared/services/api';
import { FacilityAudit, FacilityAuditBody } from './types';
// Orders
export const getCustomerFacilityAudit = (
  customerId: string,
  facilityId: string,
  auditId: string
) =>
  api.get<{ audit: FacilityAudit }>(
    `/customers/${customerId}/facilities/${facilityId}/audits/${auditId}`
  );

export const getCustomerFacilityAuditsScore = (
  customerId: string,
  facilityId: string
) =>
  api.get<any>(
    `/customers/${customerId}/facilities/${facilityId}/audits/score`
  );

export const getCustomerFacilityAuditPrepare = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ audit: FacilityAudit }>(
    `/customers/${customerId}/facilities/${facilityId}/audits/prepare`
  );

export const getCustomerFacilityAudits = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityAudit[] }>(
    `/customers/${customerId}/facilities/${facilityId}/audits`
  );

export const patchCustomerFacilityAudit = (
  customerId: string,
  facilityId: string,
  auditId: string,
  body: FacilityAuditBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/audits/${auditId}`,
    body
  );

export const postCustomerFacilityAudit = (
  customerId: string,
  facilityId: string,
  body: FacilityAuditBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/audits`, body);

export const postCustomerFacilityAuditComment = (
  customerId: string,
  facilityId: string,
  auditId: string,
  body: {
    description: string;
  }
) =>
  api.post(
    `/customers/${customerId}/facilities/${facilityId}/audits/${auditId}/comments`,
    body
  );

export const deleteCustomerFacilityAudit = (
  customerId: string,
  facilityId: string,
  auditId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/audits/${auditId}`
  );
