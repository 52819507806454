import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { useHistory, useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import RemoveExerciceModal from '../RemoveExerciceModal';
import { getInventoryItem } from 'shared/services/api/inventory';
import UserContext from 'shared/contexts/UserContext';
import Badge from 'shared/components/Badge';
import { getBadgeColorByInventoryItemStatus } from 'shared/utils/status';
import { dateFromNow } from 'shared/utils/time';
import ConfirmTakeModal from '../ConfirmTakeModal';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import AssignToUserModal from '../AssignToUserModal';
import UnassignFromUserModal from '../UnassignFromUserModal';
import { Image } from '@mantine/core';
import { useTranslations } from 'shared/hooks/useTranslations';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
interface Params {
  id: string;
}
export default function EditExercice() {
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [takeModal, setTakeModal] = useState<boolean>(false);
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [unassignModal, setUnassignModal] = useState<boolean>(false);
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryItem(id),
  });
  const { id } = useParams<Params>();
  const history = useHistory();
  const handleEdit = () => {
    history.push(ROUTES.inventory.items.edit.to(data.item?.id));
  };
  const [isTranslating, setIsTranslating] = useState(false);

  // const handleTake = (loggedUser: User) => async () => {
  //   if (data.item.ownerId && loggedUser.id !== data.item.ownerId) {
  //     setTakeModal(true);
  //     return;
  //   }
  //   try {
  //     await takeInventoryItem(data.item?.id);
  //     refresh();
  //   } catch {}
  // };

  // const handleUntake = async () => {
  //   try {
  //     await untakeInventoryItem(data.item?.id);
  //     refresh();
  //   } catch {}
  // };

  const translations = useTranslations();

  const handleTranslate = async () => {
    try {
      setIsTranslating(true);
      await api.post(`/inventory/items/${id}/translate`);
      setIsTranslating(false);
      refresh();
    } catch (e) {
      setIsTranslating(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };
  return (
    <UserContext.Consumer>
      {({ data: user }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.inventory.items.list.path }}
              linkAs={Link}
            >
              {translations.inventory.inventory}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.inventory.items.list.path }}
              linkAs={Link}
            >
              {translations.global.list}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{data.item?.name}</Breadcrumb.Item>
          </Breadcrumb>
          {isLoaded && (
            <>
              <RemoveExerciceModal
                show={removeModal}
                onClose={() => setRemoveModal(false)}
                exercice={data.item}
              />
              <ConfirmTakeModal
                show={takeModal}
                onClose={() => setTakeModal(false)}
                exercice={data.item}
              />
              <AssignToUserModal
                show={assignModal}
                onClose={() => setAssignModal(false)}
                exercice={data.item}
              />
              <UnassignFromUserModal
                show={unassignModal}
                onClose={() => setUnassignModal(false)}
                exercice={data.item}
              />
            </>
          )}
          <div className="row">
            <div className="col-xl-12">
              {isLoading && (
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {error && (
                <div className="alert alert-danger">No inventory item</div>
              )}
              {isLoaded && (
                <Card
                  title={data.item?.name}
                  toolbar={
                    <>
                      <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_EDIT]}>
                        <Button
                          color="info"
                          onClick={handleTranslate}
                          isLoading={isTranslating}
                          className="mr-5"
                        >
                          Przetłumacz na angielski
                        </Button>
                      </WithRole>
                      <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_REMOVE]}>
                        <Button
                          color="danger"
                          onClick={() => setRemoveModal(true)}
                          className="mr-5"
                          type="button"
                        >
                          {translations.global.remove}
                        </Button>
                      </WithRole>
                      <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_EDIT]}>
                        <Button
                          color="light"
                          onClick={handleEdit}
                          className="mr-5"
                        >
                          {translations.global.edit}
                        </Button>
                      </WithRole>
                      {/* <WithRole
                        roles={[USER_PERMISSION.INVENTORY_ITEM_ASSIGN_TO_USER]}
                      >
                        <Button
                          color="light"
                          onClick={() => setAssignModal(true)}
                          className="mr-5"
                        >
                          {translations.inventory.assignToUser}
                        </Button>
                      </WithRole> */}
                      {/* <WithRole
                        roles={[USER_PERMISSION.INVENTORY_ITEM_ASSIGN_TO_USER]}
                      >
                        <Button
                          color="light"
                          onClick={() => setUnassignModal(true)}
                          className="mr-5"
                        >
                          {translations.inventory.unassignUser}
                        </Button>
                      </WithRole> */}
                      {/* <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_TAKE]}>
                        <>
                          {data.item?.owner?.id !== user.id && (
                            <Button onClick={handleTake(user)} color="success">
                              {translations.inventory.take}
                            </Button>
                          )}
                          {data.item?.owner?.id === user.id && (
                            <Button color="info" onClick={handleUntake}>
                              {translations.inventory.giveBack}
                            </Button>
                          )}
                        </>
                      </WithRole> */}
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-5">
                        <div>
                          <strong>{translations.global.name}:</strong>
                        </div>
                        {data.item?.name}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>{translations.global.description}:</strong>
                        </div>
                        {data.item?.description || '-'}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>{translations.inventory.uniqueCode}:</strong>
                        </div>
                        {data.item?.uniqueId}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>
                            {translations.inventory.currentOwner}:
                          </strong>
                        </div>
                        {data.item?.owner ? (
                          <span>
                            {data.item?.owner?.firstName}{' '}
                            {data.item?.owner?.lastName}
                          </span>
                        ) : (
                          '-'
                        )}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>{translations.global.image}:</strong>
                        </div>
                        {data.item?.imageUrl ? (
                          <Image
                            src={data?.item?.imageUrl}
                            style={{ maxWidth: '200px' }}
                          />
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <h4>{translations.global.metadata}</h4>
                      {data.item?.data?.map((o) => (
                        <div className="mb-5" key={`${o.id}`}>
                          <div>
                            <strong>{o.key}:</strong>
                          </div>
                          {o.value}
                        </div>
                      ))}
                    </div>

                    <div className="col-md-4">
                      <div className="mb-5">
                        <div>
                          <strong>
                            {translations.inventory.latest10actions}:
                          </strong>
                        </div>
                        <div>
                          {data.item?.actions?.map((o) => (
                            <div key={`${o.id}`} className="mt-5">
                              <Badge
                                color={getBadgeColorByInventoryItemStatus(
                                  o.fromStatus
                                )}
                              >
                                {o.fromStatus}
                              </Badge>{' '}
                              to{' '}
                              <Badge
                                color={getBadgeColorByInventoryItemStatus(
                                  o.toStatus
                                )}
                              >
                                {o.toStatus}
                              </Badge>{' '}
                              by{' '}
                              <strong>{`${o.user?.firstName} ${o.user?.lastName}`}</strong>{' '}
                              / {dateFromNow(o.createdAt)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
