import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';
// import InvitationsList from './InvitationsList';
// import Details from './Details';
// import Edit from './Edit';
// import { USER_PERMISSION } from 'shared/constants/user';
// import WithRole from 'shared/components/WithRole';
import CustomerDetails from './Details';
import CustomerFacilitiesRoutes from './Details/Facilities';

function CustomersRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.default.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
          <Redirect to={ROUTES.customers.list.path} />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.customers.list.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <List />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.customers.details.path]}>
        {/* <WithRole roles={[USER_PERMISSION.USERS_EDIT]}> */}
        <CustomerDetails />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.customers.edit.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_EDIT]}> */}
        <List />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.customers.facilities.default.path]}>
        {/* <WithRole roles={[USER_PERMISSION.USERS_EDIT]}> */}
        <CustomerFacilitiesRoutes />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomersRoutes;
