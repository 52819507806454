import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';
import AdditionalServiceDetails from './Details';

function CustomerFacilityAdditionalServicesRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.facilities.additionalServices.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <List />
        {/* </WithRole> */}
      </Route>
      <Route
        path={[ROUTES.customers.facilities.additionalServices.details.path]}
        exact
      >
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <AdditionalServiceDetails />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomerFacilityAdditionalServicesRoutes;
