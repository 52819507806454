import React from 'react';
import { Settings } from 'luxon';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import './index.css';
import 'react-image-crop/lib/ReactCrop.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-day-picker/lib/style.css';
import { QueryParamProvider } from 'use-query-params';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Settings.defaultLocale = 'en';

Sentry.init({
  // dsn: process.env.REACT_APP_SENTRY_DSN,
  dsn: "https://99d7346094304bebc279379b9ba9249b@o4507533020364800.ingest.de.sentry.io/4507533032489040",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  // @ts-ignore
  <React.StrictMode>
    <Router>
    {/* @ts-ignore */}
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className='h-100 w-100'>
          <ToastContainer />
          <App />
        </div>
      </QueryParamProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
