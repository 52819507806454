import { Alert, Button, Group, Modal, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { patchCustomerFacilityRequest } from 'shared/services/api/facilities';
import { FacilityRequestStatus } from 'shared/services/api/facilities/types';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  requestId: string;
  currentStatus?: FacilityRequestStatus;
}
const ChangeRequestStatusModal = (props: Props) => {
  const {
    open,
    onClose,
    onSuccess,
    customerId,
    facilityId,
    requestId,
    currentStatus,
  } = props;

  const form = useForm({
    initialValues: {
      status: currentStatus,
    },

    validate: {
      status: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCustomerFacilityRequest(customerId, facilityId, requestId, {
        status: values.status,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const translations = useTranslations();
  const dataOptions = [
    {
      label:
        translations.enums.facilityRequestStatus[FacilityRequestStatus.OPEN],
      value: FacilityRequestStatus.OPEN,
    },
    {
      label:
        translations.enums.facilityRequestStatus[
          FacilityRequestStatus.IN_PROGRESS
        ],
      value: FacilityRequestStatus.IN_PROGRESS,
    },
    {
      label:
        translations.enums.facilityRequestStatus[FacilityRequestStatus.CLOSED],
      value: FacilityRequestStatus.CLOSED,
    },
  ];

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Zmień status">
        <form onSubmit={form.onSubmit(handleSubmit)} style={{ minHeight: 200 }}>
          <Select
            mt={10}
            data={dataOptions}
            // TODO: Translations
            label="Status"
            placeholder=""
            {...form.getInputProps('status')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              {translations.global.save}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default ChangeRequestStatusModal;
