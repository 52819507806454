import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomerUsers } from 'shared/services/api/customers';
import { useState } from 'react';
import { Button, Badge, Group, ActionIcon } from '@mantine/core';
import { CustomerUser } from 'shared/services/api/customers/types';
import CreateCustomerUserModal from './CreateCustomerUserModal';
import { COMMON_USER_TYPE_BADGE_COLOR } from '../../../../../types/user';
import { IconPencil, IconTrash } from '@tabler/icons';
import RemoveCustomerUserModal from 'modules/Customers/Details/Users/List/RemoveCustomerUserModal';
import EditCustomerUserModal from 'modules/Customers/Details/Users/List/EditCustomerUserModal';

const CustomerUsers = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [createCustomerModalOpen, setCreateCustomerModalOpen] = useState(false);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState<
    CustomerUser | undefined
  >();
  const [removeCustomerModalOpen, setRemoveCustomerModalOpen] = useState<
    CustomerUser | undefined
  >();
  const { data, error, isLoading, refresh } = useFetch<{
    rows: CustomerUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerUsers(customerId),
  });
  // const query = useQuery({ queryKey: ['customers'], queryFn: getCustomers });
  const translations = useTranslations();

  const handleOnCreate = () => {
    setCreateCustomerModalOpen(false);
    refresh();
  };

  const handleOnEdit = () => {
    setEditCustomerModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveCustomerModalOpen(undefined);
    refresh();
  };

  const handleEdit = (customer: CustomerUser) => () => {
    setEditCustomerModalOpen(customer);
  };
  const handleRemove = (customer: CustomerUser) => () => {
    setRemoveCustomerModalOpen(customer);
  };
  return (
    <div className="">
      <CreateCustomerUserModal
        open={createCustomerModalOpen}
        onClose={() => setCreateCustomerModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
        currentUsers={data.rows || []}
      />
      <EditCustomerUserModal
        customerId={customerId}
        user={editCustomerModalOpen!}
        open={!!editCustomerModalOpen}
        onClose={() => setEditCustomerModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveCustomerUserModal
        customerId={customerId}
        user={removeCustomerModalOpen!}
        open={!!removeCustomerModalOpen}
        onClose={() => setRemoveCustomerModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title="Uzytkownicy"
            toolbar={
              <Button onClick={() => setCreateCustomerModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj użytkownika
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Imie i nazwisko</th>
                  <th>Email</th>
                  <th>Typ</th>
                  <th>Obiekty</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.rows?.map((customerUser: CustomerUser) => {
                  const { user, createdAt, type } = customerUser;
                  return (
                    <tr key={user.id}>
                      <td>
                        <Link
                          to={ROUTES.customers.users.details.to(
                            customerId,
                            user.id
                          )}
                        >
                          {user?.firstName} {user.lastName}
                        </Link>
                      </td>
                      <td>{user.email}</td>
                      <td>
                        <Badge color={COMMON_USER_TYPE_BADGE_COLOR[type]}>
                          {translations.enums.commonUserType[type]}
                        </Badge>
                      </td>
                      <td>
                        {user.facilities?.map((facility) => (
                          <div>{facility.name}</div>
                        ))}
                      </td>

                      <td>{formatDate(createdAt)}</td>
                      <td>
                        <Group spacing="xs" noWrap>
                          <ActionIcon
                            type="button"
                            color="blue"
                            onClick={handleEdit(customerUser)}
                          >
                            <IconPencil size={15} />
                          </ActionIcon>
                          <ActionIcon
                            type="button"
                            color="red"
                            onClick={handleRemove(customerUser)}
                          >
                            <IconTrash size={15} color="red" />
                          </ActionIcon>
                        </Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CustomerUsers;
