import React from 'react';
import cn from 'classnames';
// import { ButtonType } from 'react-bootstrap/esm/Button';

type ThemeColor =
  | 'white'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'
  | 'light'
  | 'dark';
interface Props {
  children: React.ReactNode;
  color: ThemeColor;
  onClick?: (e?: any) => void;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
}
const Button = (props: Props) => {
  const {
    children,
    color,
    onClick,
    className,
    isLoading,
    type = 'submit',
    disabled,
  } = props;
  const classes = cn('btn', {
    [`btn-${color}`]: !!color,
    'btn-disabled': disabled,
    [`${className}`]: !!className,
    'spinner spinner-white  spinner-right': isLoading,
  });
  return (
    <button
      className={classes}
      onClick={!disabled ? onClick : undefined}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
