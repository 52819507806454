import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import TextareaField from 'shared/components/TextareaField';
import { postInventoryRequestAction } from 'shared/services/api/inventory';
interface Props {
  show: boolean;
  onClose: Function;
  request: any;
  refresh: any;
}

const RejectRequestModal = (props: Props) => {
  const { show, onClose, request, refresh } = props;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {},
  });
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await postInventoryRequestAction(request.id, {
        status: 'REJECTED',
        description: data.description,
      });
      onClose(true);
      toast.success('Request has been rejected');
      refresh();
    } catch (e) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose();

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Reject request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to reject request{' '}
          <strong>{request?.name}</strong>?
          <TextareaField name="description" ref={register} label="Description" />
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button color="danger" isLoading={isLoading}>
            Reject
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default RejectRequestModal;
