import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import FilesList from './List';

function Files() {
  return (
    <Switch>
      <Route path={ROUTES.files.default.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <Redirect to={ROUTES.files.list.path} />
        </WithRole>
      </Route>
      <Route path={ROUTES.files.list.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <FilesList />
        </WithRole>
      </Route>
    </Switch>
  );
}

export default Files;
