import { Alert, Button, FileInput, Group, Modal } from '@mantine/core';
import { FileInputProps, Center } from '@mantine/core';
import { IconPhoto } from '@tabler/icons';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';

import { useTranslations } from 'shared/hooks/useTranslations';
import { postCustomerFacilityFile } from 'shared/services/api/files';
import useFetch from 'shared/hooks/useFetch';
import { FacilityUser } from 'shared/services/api/facilities/types';
import { getCustomerFacilityUsers } from 'shared/services/api/facilities';
import FileAccessForm from 'shared/components/FileAccessForm';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  folderId?: string;
  currentFolderId?: string;
}
const UploadFilesModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId, currentFolderId } =
    props;
  const form = useForm({
    initialValues: {
      files: [],
      name: '',
      visibility: 'PUBLIC',
      users: [],
    },

    validate: {
      files: (value) => {
        return value && value.length > 0 ? null : 'Wybierz plik';
      },
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const { data } = useFetch<{
    rows: FacilityUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityUsers(customerId, facilityId),
  });

  const [isUploading, setUploading] = useState(false);

  const handleSubmit = async (values) => {
    const { files: fileList, visibility } = values;
    try {
      setUploading(true);
      const files = Array.from(fileList as FileList);
      await Promise.all(
        files?.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          if (currentFolderId) {
            formData.append('folderId', currentFolderId);
          }
          formData.append('visibility', visibility);
          formData.append('users', values.users);
          await postCustomerFacilityFile(customerId, facilityId, formData);
        })
      );
      onSuccess();
    } catch (error) {
      setError(error);
      alert('Failed to upload files');
    } finally {
      setUploading(false);
      form.reset();
    }
  };
  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj pliki">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <FileInput
            placeholder="Wybierz pliki"
            label="Plik"
            withAsterisk
            multiple
            valueComponent={ValueComponent}
            {...form.getInputProps('files')}
          />

          <FileAccessForm form={form} users={data.rows || []} />

          {!!error && (
            <Alert color="red">{translations.global.somethingWentWrong}</Alert>
          )}
          <Group position="right" mt="md">
            <Button type="button" variant="outline" onClick={onClose}>
              {translations.global.cancel}
            </Button>
            <Button type="submit" loading={isUploading}>
              {translations.global.add}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

function Value({ file }: { file: File | null }) {
  if (!file) {
    return null;
  }
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark'
            ? theme.colors.dark[7]
            : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: '3px 7px',
        borderRadius: theme.radius.sm,
      })}
    >
      <IconPhoto size={14} style={{ marginRight: 5 }} />
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: 200,
          display: 'inline-block',
        }}
      >
        {file.name}
      </span>
    </Center>
  );
}

const ValueComponent: FileInputProps['valueComponent'] = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <Group spacing="sm" py="xs">
        {value?.map((file, index) => (
          <Value file={file} key={index} />
        ))}
      </Group>
    );
  }

  return <Value file={value} />;
};

export default UploadFilesModal;
