import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';
import { isCorrectTimeValidator } from './../utils/time';

yup.setLocale({
  mixed: {
    required: 'Field is required',
  },
  number: {
    positive: 'Must be grater than 0',
  },
  string: {
    // @ts-ignore
    // eslint-disable-next-line
    min: 'Must contain at least ${min} characters',
  },
});

yup.addMethod<yup.StringSchema>(yup.string, 'isCorrectTime', function () {
  return this.test(
    'is-correct-time',
    'Wartość musi wynosić 1m30s lub 1m lub 30s',
    isCorrectTimeValidator
  );
});
// @ts-ignore
yup.addMethod<yup.ObjectSchema>(yup.object, 'isCorrectOption', function () {
  return this.test('is-correct-option', 'Must be selected', (value: any) => {
    if (!value?.value) {
      return false;
    }
    return true;
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isCorrectTime(): StringSchema<TType, TContext>;
  }
}

export default yup;
