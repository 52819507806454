import { useCallback, useEffect, useState } from 'react';
interface Props {
  initialValue: any;
  fetchAction: any;
  noFetchInitial?: boolean;
}
const useFetch = <T>(props: Props) => {
  const { fetchAction, initialValue, noFetchInitial } = props;
  const [data, setData] = useState<T>(initialValue);
  const [isLoading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const load = useCallback(() => {
    async function loadData() {
      try {
        setLoading(true);
        const { data } = await fetchAction();
        setLoading(false);
        setData(data);
        setLoaded(true);
      } catch {
        setError(true);
        setLoaded(false);
        setLoading(false);
      }
    }
    loadData();
  }, [fetchAction]);

  useEffect(() => {
    if (noFetchInitial) {
      return;
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noFetchInitial]);

  const refresh = useCallback(() => {
    load();
  }, [load]);

  return {
    data,
    isLoading,
    isLoaded,
    error,
    refresh,
  };
};

export default useFetch;
