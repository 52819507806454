import api from 'shared/services/api';
import { AreaType, AreaTypeBody } from './types';
// Orders
export const getAreaType = (auditTaskId: string) =>
  api.get<{ customer: AreaType }>(`/company/area-types/${auditTaskId}`);

export const getAreaTypes = () =>
  api.get<{ rows: AreaType[] }>(`/company/area-types`);

export const patchAreaType = (auditTaskId: string, body: AreaTypeBody) =>
  api.patch(`/company/area-types/${auditTaskId}`, body);

export const postAreaType = (body: AreaTypeBody) =>
  api.post(`/company/area-types`, body);

export const deleteAreaType = (auditTaskId: string) =>
  api.delete(`/company/area-types/${auditTaskId}`);
