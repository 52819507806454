export enum FacilityOrderStatus {
  'OPEN' = 'OPEN',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CLOSED' = 'CLOSED',
}

export const FACILITY_ORDER_STATUS_COLOR = {
  [FacilityOrderStatus.OPEN]: 'yellow',
  [FacilityOrderStatus.IN_PROGRESS]: 'violet',
  [FacilityOrderStatus.CLOSED]: 'green',
};

export interface FacilityOrderComment {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface FacilityOrder {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  parentAreaId?: string;
  customerId: string;
  companyId: string;
  facilityId: string;
  status: FacilityOrderStatus;
  products?: {
    id: string;
    quantity: number;
    product: {
      id: string;
      name: string;
      unitType: string;
      price: number;
    };
  }[];
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };

  comments?: FacilityOrderComment[];
}
export interface FacilityOrderBody {}
