import React, { useEffect, useState } from 'react';
import { NavLink, Route } from 'react-router-dom';

import styles from './styles.module.scss';
import ROUTES from 'shared/constants/routes';
import UserContext from 'shared/contexts/UserContext';
import { hasUserRole } from 'shared/services/firebase';
import WithRole from '../WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import { getAppConfiguration } from '../../../config';
import { LanguageSelector } from '../LanguageSelector';
import { useTranslations } from 'shared/hooks/useTranslations';
const Navigation = () => {
  const translations = useTranslations();
  const [isAdmin, setAdmin] = useState(false);
  const configuration = getAppConfiguration();
  useEffect(() => {
    hasUserRole('super_admin').then((value) => {
      setAdmin(value);
    });
  }, []);
  return (
    <UserContext.Consumer>
      {({ data: user, refresh, selectedCompany }) => (
        <div>
          <div id="kt_header" className="header flex-column header-fixed">
            <div className="header-top">
              <div className="container">
                <div className="d-none d-lg-flex align-items-center mr-3">
                  <NavLink to="/" className="mr-10">
                    <img
                      alt="Logo"
                      src={configuration.logo}
                      className={`max-h-35px ${styles.logo}`}
                    />
                  </NavLink>
                  <ul
                    className="header-tabs nav align-self-end font-size-lg"
                    role="tablist"
                  >
                    {/* <li className="nav-item">
                      <NavLink
                        to="/"
                        exact
                        activeClassName="active"
                        className="nav-link py-4 px-6"
                      >
                        {translations.navigation.dashboard}
                      </NavLink>
                    </li> */}

                    <WithRole roles={[USER_PERMISSION.FILES_LIST]}>
                      <li className="nav-item mr-3">
                        <NavLink
                          to={ROUTES.files.default.path}
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          {translations.navigation.files}
                        </NavLink>
                      </li>
                    </WithRole>

                    <WithRole
                      roles={[
                        USER_PERMISSION.PDF_LIST,
                        USER_PERMISSION.PDF_TEMPLATE_LIST,
                      ]}
                    >
                      <li className="nav-item mr-3">
                        <NavLink
                          to={ROUTES.pdf.default.path}
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          Oferty
                        </NavLink>
                      </li>
                    </WithRole>
                    <WithRole
                      roles={[
                        USER_PERMISSION.INVENTORY_ITEM_LIST,
                        USER_PERMISSION.INVENTORY_GROUP_LIST,
                      ]}
                    >
                      <li className="nav-item mr-3">
                        <NavLink
                          to="/inventory"
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          {translations.navigation.inventory}
                        </NavLink>
                      </li>
                    </WithRole>
                    <WithRole roles={[USER_PERMISSION.USERS_LIST]}>
                      <li className="nav-item mr-3">
                        <NavLink
                          to="/users"
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          {translations.navigation.users}
                        </NavLink>
                      </li>
                    </WithRole>
                    <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}>
                      <li className="nav-item mr-3">
                        <NavLink
                          to={ROUTES.company.default.path}
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          {translations.navigation.company}
                        </NavLink>
                      </li>
                    </WithRole>
                    {/* TODO: Add permissions with role CUSTOMERS_MANAGE */}
                    {/* <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}> */}
                    <li className="nav-item mr-3">
                      <NavLink
                        to={ROUTES.customers.default.path}
                        className="nav-link py-4 px-6"
                        activeClassName="active"
                      >
                        {translations.navigation.customers}
                      </NavLink>
                    </li>
                    {/* </WithRole> */}
                    {isAdmin && (
                      <li className="nav-item mr-3">
                        <NavLink
                          to={ROUTES.admin.default.path}
                          className="nav-link py-4 px-6"
                          activeClassName="active"
                        >
                          {translations.navigation.admin}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="topbar bg-primary">
                  <div className="topbar-item">
                    <LanguageSelector />
                    <NavLink
                      to={ROUTES.account.default.path}
                      className="btn btn-icon btn-hover-transparent-white w-sm-auto d-flex align-items-center btn-lg px-2"
                      id="kt_quick_user_toggle"
                    >
                      <div className="d-flex flex-column text-right pr-sm-3">
                        <span className="text-white opacity-50 font-weight-bold font-size-sm d-none d-sm-inline">
                          {user?.firstName} {user?.lastName}
                        </span>
                        <span className="text-white font-weight-bolder font-size-sm d-none d-sm-inline">
                          {selectedCompany?.name}
                        </span>
                      </div>
                      <span className="symbol symbol-35">
                        {user?.avatar ? (
                          <img src={user.avatar} alt="" />
                        ) : (
                          <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
                            {user?.firstName[0]}
                            {user?.lastName[0]}
                          </span>
                        )}
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div className={`header-bottom ${styles.headerBottom}`}>
              <div className="container">
                <div
                  className="header-navs header-navs-left"
                  id="kt_header_navs"
                >
                  <div className="tab-content">
                    <div
                      className="tab-pane py-5 p-lg-0 show active"
                      id="kt_header_tab_1"
                    >
                      <div
                        id="kt_header_menu"
                        className="header-menu header-menu-mobile header-menu-layout-default"
                      >
                        <ul className="menu-nav">
                          <Route path={ROUTES.pdf.default.path}>
                            <WithRole roles={[USER_PERMISSION.PDF_LIST]}>
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.pdf.default.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">Oferty</span>
                                </NavLink>
                              </li>
                            </WithRole>
                            <WithRole
                              roles={[USER_PERMISSION.PDF_TEMPLATE_LIST]}
                            >
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.pdf.templates.default.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    {' '}
                                    {translations.navigation.pdfTemplates}
                                  </span>
                                </NavLink>
                              </li>
                            </WithRole>
                          </Route>
                          <Route path={ROUTES.company.default.path}>
                            {/* <WithRole
                              roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}
                            > */}
                            <li className={`${styles.menuItem} menu-item`}>
                              <NavLink
                                exact
                                to={ROUTES.company.default.path}
                                className="menu-link menu-toggle"
                                activeClassName={styles.menuItemActive}
                              >
                                <span className="menu-text">Detale</span>
                              </NavLink>
                            </li>
                            <li className={`${styles.menuItem} menu-item`}>
                              <NavLink
                                to={ROUTES.company.services.path}
                                className="menu-link menu-toggle"
                                activeClassName={styles.menuItemActive}
                              >
                                <span className="menu-text">Usługi</span>
                              </NavLink>
                            </li>
                            <li className={`${styles.menuItem} menu-item`}>
                              <NavLink
                                to={ROUTES.company.areaTypes.path}
                                className="menu-link menu-toggle"
                                activeClassName={styles.menuItemActive}
                              >
                                <span className="menu-text">Typy stref</span>
                              </NavLink>
                            </li>
                            <li className={`${styles.menuItem} menu-item`}>
                              <NavLink
                                to={ROUTES.company.auditTasks.path}
                                className="menu-link menu-toggle"
                                activeClassName={styles.menuItemActive}
                              >
                                <span className="menu-text">Zadania w audycie</span>
                              </NavLink>
                            </li>
                            <li className={`${styles.menuItem} menu-item`}>
                              <NavLink
                                to={ROUTES.company.companyProducts.path}
                                className="menu-link menu-toggle"
                                activeClassName={styles.menuItemActive}
                              >
                                <span className="menu-text">Produkty</span>
                              </NavLink>
                            </li>
                            {/* </WithRole> */}
                          </Route>
                          <Route path={ROUTES.inventory.default.path}>
                            <WithRole
                              roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}
                            >
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.inventory.items.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    {translations.navigation.inventory}
                                  </span>
                                </NavLink>
                              </li>
                            </WithRole>
                            <WithRole
                              roles={[USER_PERMISSION.INVENTORY_GROUP_LIST]}
                            >
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.inventory.groups.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    {' '}
                                    {translations.navigation.groups}
                                  </span>
                                </NavLink>
                              </li>
                            </WithRole>

                            {/* <WithRole
                              roles={[USER_PERMISSION.INVENTORY_REQUEST_ADD]}
                            >
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.inventory.requests.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    {translations.navigation.requests}
                                  </span>
                                </NavLink>
                              </li>
                            </WithRole> */}
                          </Route>
                          <Route path={ROUTES.users.default.path}>
                            <WithRole roles={[USER_PERMISSION.USERS_LIST]}>
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.users.list.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    {translations.navigation.users}
                                  </span>
                                </NavLink>
                              </li>
                            </WithRole>
                            {/* <WithRole
                              roles={[USER_PERMISSION.USERS_INVITATIONS_LIST]}
                            >
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to={ROUTES.users.invitations.path}
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">Zaproszenia</span>
                                </NavLink>
                              </li>
                            </WithRole> */}
                          </Route>
                          {isAdmin && (
                            <Route path="/admin">
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to="/admin/invites"
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">
                                    Company admin invitations
                                  </span>
                                </NavLink>
                              </li>
                              <li className={`${styles.menuItem} menu-item`}>
                                <NavLink
                                  to="/admin/companies"
                                  className="menu-link menu-toggle"
                                  activeClassName={styles.menuItemActive}
                                >
                                  <span className="menu-text">Companies</span>
                                </NavLink>
                              </li>
                            </Route>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default Navigation;
