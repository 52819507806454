import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NavLink,
  Navbar,
  Table,
  Title,
} from '@mantine/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Card from 'shared/components/Card';
import api from 'shared/services/api';
import ROUTES from 'shared/constants/routes';
import styles from './styles.module.scss';
import useFetch from 'shared/hooks/useFetch';
import { formatDate } from 'shared/utils/time';

interface FacilityWorkSchedule {
  id: string;
  customerId: string;
  facilityId: string;
  name: string;
  active: boolean;
  data: {
    name: string;
    facilityAreas: {
      name: string;
      subAreas: {
        name: string;
        period: string;
        details?: string;
      }[];
    }[];
  }[];
}

const CustomerFacilityWorkSchedules = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event) => {
    uploadFiles(event.target.files);
  };
  const [isUploading, setUploading] = useState(false);

  const { data, refresh, isLoading } = useFetch<{
    rows: FacilityWorkSchedule[];
  }>({
    initialValue: {},
    fetchAction: () =>
      api.get(
        `/customers/${customerId}/facilities/${facilityId}/work-schedules`
      ),
  });

  const uploadFiles = async (fileList: FileList) => {
    try {
      setUploading(true);
      const files = Array.from(fileList);
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const workScheduleName = formatDate(new Date());
          formData.append('name', workScheduleName);
          await api.post(
            `/customers/${customerId}/facilities/${facilityId}/work-schedules`,
            formData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
            }
          );
        })
      );
      refresh();
      setUploading(false);
      //   refreshData();
    } catch (error) {
      setUploading(false);
      alert('Failed to upload files');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.type = 'text';
        fileInputRef.current.type = 'file';
      }
    }
  };

  const handleClickOnUpload = (
    e: any //: React.ChangeEventHandler<HTMLInputElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    fileInputRef.current?.click();
  };

  const history = useHistory();
  const navigate = (a) => {
    history.push(a);
  };
  const match = useRouteMatch(
    ROUTES.customers.facilities.workSchedules.details.path
  );
  // @ts-ignore
  const selectedAreaId = match?.params?.workScheduleId;

  const values = useMemo(() => {
    if (!data || !data.rows || !data.rows.length) return;
    const activeWorkSchedule = data.rows[0]?.data;
    const values = Object.values(activeWorkSchedule);
    return values;
  }, [data]);

  const selectedArea = useMemo(() => {
    if (!data || !data.rows || !data.rows.length) return;
    const activeWorkSchedule = data.rows[0]?.data;
    const values = Object.values(activeWorkSchedule);
    return values[Number(selectedAreaId)];
  }, [data, selectedAreaId]);

  useEffect(() => {
    if (!selectedAreaId) {
      navigate(
        ROUTES.customers.facilities.workSchedules.details.to(
          customerId,
          facilityId,
          '0'
        )
      );
    }
    // eslint-disable-next-line
  }, [selectedAreaId]);
  return (
    <div>
      <Card
        title="Harmonogram pracy"
        toolbar={
          <Group>
            <Button
              onClick={handleClickOnUpload}
              variant="outline"
              loading={isUploading}
            >
              Zaktualizuj harmonogram pracy
            </Button>
          </Group>
        }
      >
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {!values && !isLoading && (
          <div>
            Brak aktualnego harmonogramu pracu. Wczytaj plik .xlsx aby
            zaktualizować aktualny harmonogram pracy
          </div>
        )}
        {isLoading && <LoadingOverlay visible />}
        {values && (
          <Grid>
            <Grid.Col span={3}>
              <Navbar width={{ base: '100%' }}>
                <Navbar.Section>
                  {values?.map((value, index) => (
                    <NavLink
                      key={value.name}
                      label={value.name}
                      active={selectedAreaId === String(index)}
                      onClick={() => {
                        navigate(
                          ROUTES.customers.facilities.workSchedules.details.to(
                            customerId,
                            facilityId,
                            String(index)
                          )
                        );
                        // onSelectFacility(facility)
                      }}
                    />
                  ))}
                </Navbar.Section>
              </Navbar>
            </Grid.Col>
            <Grid.Col span={9}>
              {selectedArea && (
                <>
                  {/* <Title order={2}>{selectedArea.name}</Title> */}
                  {selectedArea.facilityAreas?.map((area) => (
                    <div key={area.name}>
                      <Title order={4} mb={10} className={styles.title}>
                        {area.name}
                      </Title>
                      <Table mb={20} className={styles.table}>
                        <thead>
                          <tr>
                            <th>Strefa</th>
                            <th className={styles.period}>Okres</th>
                            <th className={styles.details}>Dzień tygodnia</th>
                          </tr>
                        </thead>
                        <tbody>
                          {area.subAreas.map((subArea, index) => (
                            <tr key={index} className={styles.tr}>
                              <td>{subArea.name}</td>
                              <td className={styles.period}>
                                {subArea.period}
                              </td>
                              <td className={styles.details}>
                                {subArea.details}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </>
              )}
            </Grid.Col>
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default CustomerFacilityWorkSchedules;
