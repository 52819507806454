import api from 'shared/services/api';
import { AuditTask, AuditTaskBody } from './types';
// Orders
export const getAuditTask = (auditTaskId: string) =>
  api.get<{ customer: AuditTask }>(`/company/audit-tasks/${auditTaskId}`);

export const getAuditTasks = () =>
  api.get<{ rows: AuditTask[] }>(`/company/audit-tasks`);

export const patchAuditTask = (auditTaskId: string, body: AuditTaskBody) =>
  api.patch(`/company/audit-tasks/${auditTaskId}`, body);

export const postAuditTask = (body: AuditTaskBody) =>
  api.post(`/company/audit-tasks`, body);

export const deleteAuditTask = (auditTaskId: string) =>
  api.delete(`/company/audit-tasks/${auditTaskId}`);
