import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCustomerFacilityAdditionalServices } from 'shared/services/api/facilities/additionalServices';
import { useState } from 'react';
import { Badge, Button } from '@mantine/core';
import { FacilityAdditionalService } from 'shared/services/api/facilities/additionalServices/types';
import CreateFacilityAdditionalServiceModal from './CreateFacilityAdditionalServiceModal';
import { useTranslations } from 'shared/hooks/useTranslations';
import { FACILITY_ADDITIONAL_SERVICE_STATUS_COLOR } from 'shared/services/api/facilities/additionalServices/types';

const AdditionalServicesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityAdditionalService[];
  }>({
    initialValue: {},
    fetchAction: () =>
      getCustomerFacilityAdditionalServices(customerId, facilityId),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const translations = useTranslations();

  return (
    <div className="">
      <CreateFacilityAdditionalServiceModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Usługi dodatkowe'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)} display="none">
                {/* TODO: Translations */}
                Zamów dodatkową usługę
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Wybrana usługa</th>
                  <th>Status</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((additionalService) => (
                  <tr key={additionalService.id}>
                    <td>
                      <Link
                        to={ROUTES.customers.facilities.additionalServices.details.to(
                          customerId,
                          facilityId,
                          additionalService.id
                        )}
                      >
                        {additionalService.name}
                      </Link>
                    </td>
                    <td>{additionalService.description}</td>
                    <td>{additionalService.companyService?.name}</td>
                    <td>
                      <Badge
                        color={
                          FACILITY_ADDITIONAL_SERVICE_STATUS_COLOR[
                            additionalService.status
                          ]
                        }
                      >
                        {
                          translations.enums.facilityAdditionalServiceStatus[
                            additionalService.status
                          ]
                        }
                      </Badge>
                    </td>
                    <td>{formatDate(additionalService.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServicesList;
