import { Alert, Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { postCustomerFacility } from 'shared/services/api/facilities';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
}
const CreateFacilityModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId } = props;

  const form = useForm({
    initialValues: {
      name: '',
      address: '',
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerFacility(customerId, {
        name: values.name,
        address: values.address,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj obiekt">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <TextInput
            withAsterisk
            label="Adres"
            placeholder=""
            {...form.getInputProps('address')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Dodaj
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateFacilityModal;
