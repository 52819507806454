import api from 'shared/services/api';
import {
  FacilityAdditionalService,
  FacilityAdditionalServiceBody,
} from './types';
// AdditionalServices
export const getCustomerFacilityAdditionalService = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.get<{ customer: FacilityAdditionalService }>(
    `/customers/${customerId}/facilities/${facilityId}/additional-services/${orderId}`
  );

export const getCustomerFacilityAdditionalServices = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityAdditionalService[] }>(
    `/customers/${customerId}/facilities/${facilityId}/additional-services`
  );

export const patchCustomerFacilityAdditionalService = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: FacilityAdditionalServiceBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/additional-services/${orderId}`,
    body
  );

export const postCustomerFacilityAdditionalService = (
  customerId: string,
  facilityId: string,
  body: FacilityAdditionalServiceBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/additional-services`, body);

export const postCustomerFacilityAdditionalServiceComment = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: {
    description: string;
  }
) =>
  api.post(
    `/customers/${customerId}/facilities/${facilityId}/additional-services/${orderId}/comments`,
    body
  );

export const deleteCustomerFacilityAdditionalService = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/additional-services/${orderId}`
  );
