import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SelectItem,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useMemo } from 'react';
import useFetch from 'shared/hooks/useFetch';
import { CompanyUser, FacilityUserRole } from 'types/user';
import { postCustomerFacilityUser } from 'shared/services/api/facilities';
import { FacilityUser } from 'shared/services/api/facilities/types';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomerUsers } from 'shared/services/api/customers';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  currentUsers: FacilityUser[];
}

const CreateFacilityUserModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId, currentUsers } =
    props;

  const form = useForm({
    initialValues: {
      userId: undefined,
      role: undefined,
    },

    validate: {
      userId: (value) => (!!value ? null : 'Invalid user'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { data, isLoading, isLoaded } = useFetch<{
    rows: CompanyUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerUsers(customerId),
  });
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerFacilityUser(customerId, facilityId, {
        ...values,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const translations = useTranslations();
  const usersData = useMemo(() => {
    const list =
      data.rows?.map(({ user }) => {
        const isDisabled = currentUsers?.find((u) => u.userId === user.id);

        return {
          label: `${[user.firstName, user.lastName].join(' ')} (${
            translations.enums.commonUserType[user.type!]
          })`,
          value: user.id,
          userId: user.id,
          email: user.email,
          type: user.type,
          disabled: isDisabled,
        };
      }) || [];

    return list as SelectItem[];
  }, [data.rows, currentUsers, translations]);

  const roleData = [
    {
      label: 'Klient',
      value: FacilityUserRole.CUSTOMER,
    },
    {
      label: 'Koordynator',
      value: FacilityUserRole.COORDINATOR,
    },
    {
      label: 'Handlowiec',
      value: FacilityUserRole.SALES,
    },
    {
      label: 'Dzial Sprzedazy',
      value: FacilityUserRole.EQ,
    },
  ];

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Modal
        opened={open}
        onClose={onClose}
        title="Dodaj użytkownika do placówki"
      >
        {isLoading && <LoadingOverlay visible />}
        {isLoaded && (
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ minHeight: 400 }}
          >
            <Text mb={10}>Wybrać można jedynie użytkowników, którzy są przypisani do tego klienta</Text>
            <Select
              withAsterisk
              label="Użytkownik"
              data={usersData}
              clearable
              searchable
              placeholder=""
              {...form.getInputProps('userId')}
            />
            <Select
              mt={10}
              withAsterisk
              label="Rola"
              data={roleData}
              searchable
              placeholder=""
              {...form.getInputProps('role')}
            />

            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button type="submit" loading={loading}>
                Dodaj
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default CreateFacilityUserModal;
