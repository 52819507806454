import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { translationService } from "shared/translations";
import styles from "./styles.module.scss";

export const LanguageSelector = () => {
  const activeLang = useMemo(() => translationService.getCurrentLanguage(), []);

  const languages = ["PL", "EN"];
  const handleLangSelect = useCallback(
    (lang) => () => {
      translationService.changeLanguage(lang);
    },
    []
  );
  return (
    <div className={styles.root}>
      {languages.map((lang) => {
        const classes = classNames(styles.button, {
          [styles.buttonActive]: lang === activeLang,
        });
        return (
          <div className={classes} onClick={handleLangSelect(lang)} key={lang}>
            {lang}
          </div>
        );
      })}
    </div>
  );
};
