import {
  Alert,
  Button,
  // Checkbox,
  Group,
  Modal,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { postCustomer } from 'shared/services/api/customers';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}
const CreateCustomerModal = (props: Props) => {
  const { open, onClose, onSuccess } = props;

  const form = useForm({
    initialValues: {
      name: '',
      address: '',
      // createCustomerAdminUser: false,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomer({
        name: values.name,
        address: values.address,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj klienta">
        <form onSubmit={form.onSubmit(handleSubmit)} style={{ minHeight: 400 }}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <TextInput
            withAsterisk
            label="Adres"
            placeholder=""
            {...form.getInputProps('address')}
          />

          {/* <Checkbox
            mt="md"
            label="Stwórz konto admina uzutkownika"
            {...form.getInputProps('createCustomerAdminUser', {
              type: 'checkbox',
            })}
          />
          {form.values.createCustomerAdminUser && (
            <TextInput
              withAsterisk
              label="User email"
              placeholder=""
              {...form.getInputProps('userEmail')}
            />
          )} */}
          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Stwórz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateCustomerModal;
