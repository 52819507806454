import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import { deleteTrainerInvitation } from 'shared/services/api/admin';

interface Props {
  show: boolean;
  onClose: Function;
  invitation?: any;
}

const RemoveTrainerInvitationModal = (props: Props) => {
  const { show, onClose, invitation } = props;
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => onClose();
  const onSave = async () => {
    try {
      setLoading(true);
      await deleteTrainerInvitation(invitation!.email);
      onClose(true);
      toast.success('Zaproszenie zostało usunięte');
    } catch (e) {
      toast.error('Coś poszło nie tak. Spróbuj ponownie później.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Usuń zaproszenie</Modal.Title>
      </Modal.Header>
      <Modal.Body>Czy na pewno chcesz usunąć zaproszenie?</Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          Anuluj
        </Button>
        <Button color="danger" onClick={onSave} isLoading={isLoading}>
          Usuń
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default RemoveTrainerInvitationModal;
