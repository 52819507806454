import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCompanyServices } from 'shared/services/api/company/services';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import { CompanyService } from 'shared/services/api/company/services/types';
import CreateCompanyServiceModal from './CreateCompanyServiceModal';
import { IconPencil, IconTrash } from '@tabler/icons';
import EditCompanyServiceModal from './EditCompanyServiceModal';
import DeleteCompanyServiceModal from './DeleteCompanyServiceModal';

const CompanyServicesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createServiceModalOpen, setCreateServiceModalOpen] = useState(false);
  const [editServiceModalOpen, setEditServiceModalOpen] = useState<
    CompanyService | undefined
  >();
  const [removeServiceModalOpen, setRemoveServiceModalOpen] = useState<
    CompanyService | undefined
  >();
  const { data, error, isLoading, refresh } = useFetch<{
    rows: CompanyService[];
  }>({
    initialValue: {},
    fetchAction: () => getCompanyServices(),
  });

  const handleOnCreate = () => {
    setCreateServiceModalOpen(false);
    refresh();
  };
  const handleOnEdit = () => {
    setEditServiceModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveServiceModalOpen(undefined);
    refresh();
  };

  const handleEdit = (customer: CompanyService) => () => {
    setEditServiceModalOpen(customer);
  };
  const handleRemove = (customer: CompanyService) => () => {
    setRemoveServiceModalOpen(customer);
  };

  return (
    <div className="">
      <CreateCompanyServiceModal
        facilityId={facilityId}
        open={createServiceModalOpen}
        onClose={() => setCreateServiceModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />
      <EditCompanyServiceModal
        service={editServiceModalOpen!}
        facilityId={facilityId}
        open={!!editServiceModalOpen}
        onClose={() => setEditServiceModalOpen(undefined)}
        onSuccess={handleOnEdit}
        customerId={customerId}
      />
      <DeleteCompanyServiceModal
        service={removeServiceModalOpen!}
        facilityId={facilityId}
        open={!!removeServiceModalOpen}
        onClose={() => setRemoveServiceModalOpen(undefined)}
        onSuccess={handleOnDelete}
        customerId={customerId}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Usługi'}
            toolbar={
              <Button onClick={() => setCreateServiceModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj usługę
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Cena</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((companyService) => (
                  <tr key={companyService.id}>
                    <td>{companyService.name}</td>
                    <td>{companyService.description}</td>
                    <td>
                      {companyService.price} {companyService.unit}
                    </td>

                    <td>{formatDate(companyService.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(companyService)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(companyService)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompanyServicesList;
