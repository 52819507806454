import React from 'react';
import { FieldErrors } from 'react-hook-form';

import CheckboxField from 'shared/components/CheckboxField';
import { USER_PERMISSION } from 'shared/constants/user';
import RadioField from 'shared/components/RadioField';
import { TRANSLATIONS } from 'shared/translations';
import { LANG } from 'config';
import { CommonUserType } from 'types/user';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Props {
  errors: FieldErrors<any>; // TODO
  register: any; // TODO
  control: any;
  [key: string]: any;
}
export default function ExerciceForm(props: Props) {
  const { register, user } = props;
  const translations = useTranslations();
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          {user.firstName} {user.lastName}
        </div>
      </div>

      <RadioField
        label="Typ"
        name="type"
        ref={register}
        options={[
          {
            label: translations.enums.commonUserType[CommonUserType.COMPANY_ADMIN],
            value: CommonUserType.COMPANY_ADMIN,
          },
          {
            label: translations.enums.commonUserType[CommonUserType.COMPANY_EMPLOYEE],
            value: CommonUserType.COMPANY_EMPLOYEE,
          },
          {
            label: translations.enums.commonUserType[CommonUserType.CUSTOMER],
            value: CommonUserType.CUSTOMER,
          },
        ]}
      />

      <RadioField
        label="Status"
        name="status"
        ref={register}
        options={[
          { label: 'Active', value: 'ACTIVE' },
          { label: 'Inactive', value: 'INACTIVE' },
        ]}
      />

      <h4>Roles</h4>
      {Object.keys(USER_PERMISSION).map((role) => (
        <CheckboxField
          key={role}
          label={TRANSLATIONS[LANG.PL].user.permissions[role] || role}
          name={`roles[${role}]`}
          ref={register}
        />
      ))}
    </>
  );
}
