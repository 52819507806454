import api from 'shared/services/api';

export interface InventoryGroup {
  id: string;
  name: string;
  parentInventoryGroupId?: string;
}

export interface InventoryGroupBody {
  name: string;
  parentInventoryGroupId?: string;
  order?: string;
}

export const getInventoryGroups = () =>
  api.get<{ groups: InventoryGroup[] }>('/inventory/groups');

export const getInventoryItemsMetaDataOptions = () =>
  api.get<{ metaData: any[] }>('/inventory/items/meta-data-options');

export const getInventoryGroup = (id: string) =>
  api.get<{ group: InventoryGroup }>(`/inventory/groups/${id}`);

export const createInventoryGroup = (body: InventoryGroupBody) =>
  api.post<{ group: InventoryGroup }>('/inventory/groups', body);

export const editInventoryGroup = (id: string, body: InventoryGroupBody) =>
  api.patch<{ group: InventoryGroup }>(`/inventory/groups/${id}`, body);

export const removeInventoryGroup = (id: string) =>
  api.delete<{ group: InventoryGroup }>(`/inventory/groups/${id}`);

export const getInventoryItems = () =>
  api.get<{ groups: InventoryGroup[] }>('/inventory/items');

export const countInventoryItems = () =>
  api.get<{ count: number }>('/inventory/items/count');

export const getInventoryItem = (id: string) =>
  api.get<{ group: InventoryGroup }>(`/inventory/items/${id}`);

export const createInventoryItem = (body) => api.post('/inventory/items', body);

export const editInventoryItem = (id: string, body: InventoryGroupBody) =>
  api.patch<{ group: InventoryGroup }>(`/inventory/items/${id}`, body);

export const removeInventoryItem = (id: string) =>
  api.delete<{ group: InventoryGroup }>(`/inventory/items/${id}`);

export const takeInventoryItem = (
  id: string,
  body?: { userId?: string | null }
) => api.post<{ group: InventoryGroup }>(`/inventory/items/${id}/take`, body);

export const unassignInventoryItem = (
  id: string,
  body?: { userId?: string | null }
) =>
  api.post<{ group: InventoryGroup }>(`/inventory/items/${id}/unassign`, body);

export const untakeInventoryItem = (id: string) =>
  api.post<{ group: InventoryGroup }>(`/inventory/items/${id}/untake`);

interface InventoryRequest {
  status: 'CREATED' | 'IN_PROGRESS' | 'REJECTED' | 'DONE' | 'CANCELED';
}
interface InventoryRequestBody {}

export const getInventoryRequests = () =>
  api.get<{ requests: InventoryRequest }>('/inventory/requests');

export const getInventoryRequest = (id: string) =>
  api.get<{ request: InventoryRequest }>(`/inventory/requests/${id}`);

export const postInventoryRequestAction = (id: string, body: any) =>
  api.post<{ request: InventoryRequest }>(
    `/inventory/requests/${id}/action`,
    body
  );

export const createInventoryRequest = (body: InventoryRequestBody) =>
  api.post<{ request: InventoryRequest }>('/inventory/requests', body);
