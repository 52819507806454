import React from 'react';
import { FieldError } from 'react-hook-form';
interface Option {
  label: string;
  value: any;
}
interface Props {
  label: string;
  placeholder?: string;
  tip?: string;
  required?: boolean;
  name: string;
  options: Option[];
  error?: FieldError;
  defaultValue?: any;
}
const SelectField = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const { placeholder, tip, label, required, name, options, defaultValue } =
    props;
  const optionsWithNull = [{ value: '', label: 'Wybierz' }, ...options];
  return (
    <div className="form-group">
      <label>
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <select
        ref={ref}
        className="form-control"
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        name={name}
      >
        {optionsWithNull.map(({ label, value }) => (
          <option value={value} key={`${name}-${value}`}>
            {label}
          </option>
        ))}
      </select>
      {tip && <span className="form-text text-muted">{tip}</span>}
    </div>
  );
});

export default SelectField;
