import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { useTranslations } from 'shared/hooks/useTranslations';

import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import TextField from 'shared/components/TextField';
import WithRole from 'shared/components/WithRole';
import ROUTES from 'shared/constants/routes';
import { USER_PERMISSION } from 'shared/constants/user';
import UserContext from 'shared/contexts/UserContext';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';
import { formatDate } from 'shared/utils/time';
import styles from './styles.module.scss';

interface Tab {
  id: any;
  label: string;
  value: string;
}

interface PdfTemplatesResponse {
  pdfs: {
    id: string;
    name: string;
    createdAt: string;
    user?: {
      id: string;
      firstName: string;
      lastName: string;
    };
    status: any;
  }[];
}
const PdfList = () => {
  const currentYear = String(new Date().getFullYear());
  const years = useMemo(() => {
    const years: number[] = [];

    for (let year = Number(currentYear); year >= 2016; year--) {
      years.push(year);
    }

    return years;
  }, [currentYear]);
  const tabs: Tab[] = [
    ...years.map((year) => ({
      id: String(year),
      label: String(year),
      value: String(year),
    })),
  ];
  const history = useHistory();
  const { data, isLoaded, isLoading, refresh } = useFetch<PdfTemplatesResponse>(
    {
      initialValue: {},
      fetchAction: () => api.get(`/pdf?year=${currentTab}`),
      noFetchInitial: true,
    }
  );
  const [qsCurrentTab, setCurrentTab] = useQueryParam(
    'currentTab',
    StringParam
  );
  const currentTab = qsCurrentTab || currentYear;
  const handleCurrentTab = (id: any) => {
    setCurrentTab(id);
  };
  useEffect(() => {
    refresh();
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  const onRowClick = (id: any) => {
    history.push(ROUTES.pdf.edit.to(id));
  };
  const translations = useTranslations();
  const [duplicatingId, setDuplicatingId] = useState<string | undefined>();
  const handleDuplicate = (pdfId: string) => async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDuplicatingId(pdfId);
    try {
      await api.post(`/pdf/${pdfId}/duplicate`);
      refresh();
    } catch {
    } finally {
      setDuplicatingId(undefined);
    }
  };
  const [searchValue, setSearchValue] = useQueryParam('qs', StringParam);
  const handleFilter = (event: any) => {
    setSearchValue(event?.target.value);
  };

  const filtered = useMemo(() => {
    if (!data?.pdfs) {
      return [];
    }
    return data.pdfs.filter((pdf) => {
      if (searchValue) {
        return pdf.name.toLowerCase().includes(searchValue?.toLowerCase());
      }
      return pdf;
    });
  }, [data?.pdfs, searchValue]);

  return (
    <div>
      <UserContext.Consumer>
        {({ data: user }) => (
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item
                linkProps={{ to: ROUTES.pdf.list.path }}
                linkAs={Link}
              >
                Oferty
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{translations.pdf.list}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
              <div className="col-xl-12">
                <Card
                  title={
                    <div className={styles.search}>
                      Lista ofert{' '}
                      <TextField
                        name="search"
                        defaultValue={searchValue}
                        placeholder={translations.global.typeToSearch}
                        className={styles.searchInput}
                        onChange={handleFilter}
                      />
                    </div>
                  }
                  toolbar={
                    <>
                      <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_CREATE]}>
                        <Button
                          onClick={() => {
                            history.push(ROUTES.pdf.create.path);
                          }}
                          color="primary"
                        >
                          Dodaj oferte
                        </Button>
                      </WithRole>
                    </>
                  }
                >
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((tab) => (
                      <li
                        className="nav-item"
                        key={tab.id}
                        role="button"
                        onClick={() => handleCurrentTab(tab.id)}
                      >
                        <span
                          className={`nav-link ${
                            tab.id === currentTab ? 'active' : ''
                          }`}
                          id="home-tab"
                        >
                          <span className="nav-text">{tab.label}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                  {isLoading && (
                    <div>
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}

                  {isLoaded && (
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th> {translations.global.name}</th>
                          <th> {translations.global.createdAt}</th>
                          <th> {translations.global.user}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtered.map((exercice) => (
                          <tr
                            key={exercice.id}
                            onClick={() => onRowClick(exercice.id)}
                            role="button"
                          >
                            <td>{exercice.name}</td>
                            <td>{formatDate(exercice.createdAt)}</td>
                            <td>
                              {exercice.user?.firstName}{' '}
                              {exercice.user?.lastName}{' '}
                              {exercice.user?.id === user.id ? (
                                <span>(you)</span>
                              ) : null}
                            </td>

                            <td>
                              <Button
                                color="primary"
                                disabled={!!duplicatingId}
                                isLoading={duplicatingId === exercice.id}
                                onClick={handleDuplicate(exercice.id)}
                              >
                                Duplikuj
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Card>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default PdfList;
