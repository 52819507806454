import React from 'react';
import { USER_PERMISSION } from 'shared/constants/user';
import UserContext from 'shared/contexts/UserContext';

interface Props {
  children: any;
  roles: USER_PERMISSION[];
}
const WithRole = ({ children, roles = [] }: Props) => {
  return (
    <UserContext.Consumer>
      {(data) => {
        if (data.roles?.some((r) => roles.includes(r))) {
          return children;
        }
        return null;
      }}
    </UserContext.Consumer>
  );
};

export default WithRole;
