import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCustomerFacilityAudits } from 'shared/services/api/facilities/audits';
import { useState } from 'react';
// import { Badge, Button } from '@mantine/core';
import { Badge } from '@mantine/core';
import {
  FACILITY_AUDIT_VISIBILITY_COLOR,
  FacilityAudit,
} from 'shared/services/api/facilities/audits/types';
import CreateFacilityAuditModal from './CreateFacilityAuditModal';
import { useTranslations } from 'shared/hooks/useTranslations';

const FacilitiesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityAudit[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityAudits(customerId, facilityId),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const translations = useTranslations();

  return (
    <div className="">
      <CreateFacilityAuditModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Audyty'}
            toolbar={
              <>
                {/* <Button onClick={() => setCreateFacilityModalOpen(true)}>
                Dodaj audyt
              </Button> */}
              </>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Widoczność</th>
                  <th>Ocena</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((audit) => (
                  <tr key={audit.id}>
                    <td>
                      <Link
                        to={ROUTES.customers.facilities.audits.details.to(
                          customerId,
                          facilityId,
                          audit.id
                        )}
                      >
                        {audit.name}
                      </Link>
                    </td>
                    <td>{audit.description}</td>
                    <td>
                      <Badge
                        color={
                          FACILITY_AUDIT_VISIBILITY_COLOR[audit.visibility]
                        }
                      >
                        {
                          translations.enums.facilityAuditVisibility[
                            audit.visibility
                          ]
                        }
                      </Badge>
                    </td>
                    <td>
                      <Badge
                        size="lg"
                        color={getAuditScoreBadgeColor(
                          Number(audit.auditScore?.percentage || 0)
                        )}
                      >
                        {audit.auditScore?.percentage}%
                      </Badge>
                    </td>
                    <td>{formatDate(audit.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export const getAuditScoreBadgeColor = (score: number) => {
  let color = 'red';
  // let description = 'Do kontroli w ciągu 24 godzin'; // domyślnie dla najniższej wartości

  if (score >= 90) {
    color = 'green';
    // description = 'Akceptowalne';
  } else if (score >= 70) {
    color = 'yellow';
    // description = 'Poprawki do usunięcia w ciągu 7 dni';
  } else if (score >= 50) {
    color = 'orange';
    // description = 'Do gruntownego poprawienia';
  }

  return color;
};

export default FacilitiesList;
