import api from 'shared/services/api';
import { User } from '../../../../types/user';
import { InventoryItem } from 'shared/services/api/company/types';

export interface Company {
  id: string;
  name: string;
}

export const getCurrentUserCompany = () =>
  api.get<{ company: Company }>('/auth/company');

export const getCurrentCompanyUsers = () =>
  api.get<{ rows: User[] }>('/auth/company/users');

export const getCompanyInventoryItems = () =>
  api.get<{ items: InventoryItem[] }>('/inventory/items');
