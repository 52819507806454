import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import ROUTES from "shared/constants/routes";
import Card from "shared/components/Card";
import avatarBoyPlaceholder from "assets/placeholders/avatar_boy.svg";
// import Overall from './Overall';
// import Settings from './Settings';
import Personal from "./Personal";
import { Spinner } from "react-bootstrap";
import { User } from "types/user";
import UserContext from "shared/contexts/UserContext";
import { auth } from "shared/services/firebase";
import Button from "shared/components/Button";
import { useTranslations } from "shared/hooks/useTranslations";

function Account() {
  const isLoading = false;
  const isLoaded = true;
  const logout = () => {
    auth.signOut();
  };
  const translations = useTranslations();
  return (
    <UserContext.Consumer>
      {({ data: user, refresh }: { data: User; refresh: () => void }) => (
        <div className="container">
          <div className="d-flex flex-row">
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!isLoading && isLoaded && (
              <>
                <div className="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
                  <Card>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                        <img
                          src={user?.avatar || avatarBoyPlaceholder}
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                          {user.firstName} {user.lastName}
                        </span>
                        <div className="text-muted">{user.city}</div>
                      </div>
                    </div>
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-10">
                      <div className="navi-item mb-2">
                        <NavLink
                          to={ROUTES.account.personalInformation.path}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <i className="far fa-user navi-icon mr-2"></i>
                          <span className="navi-text font-size-lg">
                            {translations.account.personalInformation}
                          </span>
                        </NavLink>
                      </div>
                      <Button onClick={logout} color="light">
                        {translations.account.signOut}
                      </Button>
                    </div>
                  </Card>
                </div>
                <div className="flex-row-fluid ml-lg-8">
                  <div className="row">
                    <div className="col-lg-12">
                      <Switch>
                        <Route path={ROUTES.account.default.path} exact>
                          {/* <Overall /> */}
                          <Redirect
                            to={ROUTES.account.personalInformation.path}
                          />
                        </Route>
                        <Route path={ROUTES.account.personalInformation.path}>
                          <Personal user={user} refresh={refresh} />
                        </Route>
                        {/* <Route path={ROUTES.account.settings.path} exact>
                          <Settings />
                        </Route> */}
                      </Switch>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}

export default Account;
