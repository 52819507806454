import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Table,
  Textarea,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import Card from 'shared/components/Card';

import useFetch from 'shared/hooks/useFetch';
import {
  getCustomerFacilityOrder,
  postCustomerFacilityOrderComment,
} from 'shared/services/api/facilities/orders';
import { useParams } from 'react-router';
import { useTranslations } from 'shared/hooks/useTranslations';
import { formatDate } from 'shared/utils/time';
import { useForm } from '@mantine/form';
import { auth } from 'shared/services/firebase';
import ROUTES from 'shared/constants/routes';
import { Link } from 'react-router-dom';
import ChangeOrderStatusModal from './ChangeOrderStatusModal';
import {
  FACILITY_ORDER_STATUS_COLOR,
  FacilityOrder,
} from 'shared/services/api/facilities/orders/types';

const OrderDetails = () => {
  const { customerId, facilityId, orderId } = useParams<any>();

  const { data, error, isLoading, refresh } = useFetch<{
    order: FacilityOrder;
  }>({
    initialValue: {},
    fetchAction: () =>
      getCustomerFacilityOrder(customerId, facilityId, orderId),
  });
  const translations = useTranslations();
  const form = useForm({
    initialValues: {
      description: '',
    },
  });
  const [isAddingComment, setIsAddingComment] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsAddingComment(true);
      await postCustomerFacilityOrderComment(
        customerId,
        facilityId,
        orderId,
        values
      );
      refresh();
    } catch {
    } finally {
      setIsAddingComment(false);
    }
  };
  const { currentUser: x } = useCurrentUser();
  const currentUser = x?.claims?.userUuid;
  const [showChangeStatusModal, setShowChangeStatusModal] =
    useState<boolean>(false);

  const handleChangeStatus = () => {
    setShowChangeStatusModal(false);
    refresh();
  };
  return (
    <>
      <ChangeOrderStatusModal
        open={showChangeStatusModal}
        customerId={customerId}
        facilityId={facilityId}
        orderId={orderId}
        onClose={() => setShowChangeStatusModal(false)}
        onSuccess={handleChangeStatus}
        currentStatus={data.order?.status}
      />
      <Card
        title={
          data.order ? (
            <>
              {data.order.name}{' '}
              <Badge
                color={FACILITY_ORDER_STATUS_COLOR[data.order.status]}
                size="lg"
              >
                {translations.enums.facilityOrderStatus[data.order.status]}
              </Badge>
            </>
          ) : null
        }
        toolbar={
          <>
            {data.order && (
              <Button
                variant="outline"
                onClick={() => setShowChangeStatusModal(true)}
              >
                Zmień status
              </Button>
            )}
          </>
        }
      >
        <LoadingOverlay visible={isLoading} />
        {error && (
          <Alert color="red">{translations.global.somethingWentWrong}</Alert>
        )}
        {data.order && (
          <div>
            <strong>Data stworzenia:</strong>
            <br /> {formatDate(data.order.createdAt)}
            <br />
            <br />
            <strong>Stworzone przez:</strong>
            <br /> {data.order.creator?.firstName}{' '}
            {data.order.creator?.lastName}
            <br />
            <br />
            <strong>Opis:</strong>
            <br /> {data.order.description}
            <br />
            <br />
            <strong>Zamówione produkty:</strong>
            <Table>
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Cena</th>
                  <th>Ilość</th>
                  <th></th>
                </tr>
              </thead>
              {data.order.products?.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}.</td>
                  <td>
                    <small>
                      <strong>{product.product?.name || 'Brak produktu w bazie'}</strong>
                    </small>
                  </td>

                  <td>
                    {product.product && (
                      <>
                        {product.product.unitType} /{' '}
                        {Number(product.product.price / 100).toFixed(2)}
                        zł
                      </>
                    )}
                  </td>

                  <td>{product.quantity}</td>

                  <td></td>
                </tr>
              ))}
            </Table>
          </div>
        )}
      </Card>

      {data.order && (
        <Card title="Komentarze">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea
              withAsterisk
              mt={10}
              // TODO: Translations

              label="Komentarz"
              placeholder=""
              {...form.getInputProps('description')}
            />

            <Group position="right" mt="md">
              <Button type="submit" loading={isAddingComment}>
                {translations.global.add}
              </Button>
            </Group>
          </form>
          <Divider mt={20} mb={20} />
          {!data.order.comments?.length && (
            <Alert color="blue" mt={20}>
              Brak komentarzy
            </Alert>
          )}
          <Box mt={20}>
            {data.order.comments?.map((comment) => (
              <div key={comment.id}>
                <Message
                  creator={comment.creator!}
                  isUserComment={comment.creator?.id === currentUser}
                  description={comment.description}
                />
              </div>
            ))}
          </Box>
        </Card>
      )}
    </>
  );
};

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<any>();
  useEffect(() => {
    auth.currentUser?.getIdTokenResult().then((u) => setCurrentUser(u));
  }, []);
  return { currentUser };
};

const Message = ({ creator, description, isUserComment }) => {
  const { firstName, lastName, avatar, createdAt, id } = creator;

  const customerId = 'x';
  const facilityId = 'x';
  const messageStyle = isUserComment
    ? 'd-flex flex-column align-items-end mb-10'
    : 'd-flex flex-column align-items-start mb-10';

  const textStyle = isUserComment
    ? 'p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end'
    : 'p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start';

  return (
    <div className={messageStyle}>
      <div className="d-flex align-items-center mb-2">
        <div className={isUserComment ? 'me-3' : 'ms-3'}>
          <Link
            to={ROUTES.customers.facilities.users.details.to(
              customerId,
              facilityId,
              id
            )}
            className={`fs-5 fw-bold text-gray-900 text-hover-primary ${
              isUserComment ? 'ms-1 mr-3 ml-5' : 'me-1 mr-3 ml-3'
            }`}
          >
            {firstName} {lastName}
          </Link>
        </div>
        <span className="symbol symbol-35">
          {avatar ? (
            <img src={avatar} alt="" />
          ) : (
            <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
              {firstName[0]}
              {lastName[0]}
            </span>
          )}
        </span>
      </div>
      <div className={textStyle} data-kt-element="message-text">
        {description}
      </div>
      <span className="text-muted fs-7 mb-1">{formatDate(createdAt)}</span>
    </div>
  );
};

export default OrderDetails;
