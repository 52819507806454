import React, { useState } from "react";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import TextField from "shared/components/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "types/user";
import EditAvatar from "../EditAvatar";
import api from "shared/services/api";
import { toast } from "react-toastify";
import { useTranslations } from "shared/hooks/useTranslations";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});
interface Props {
  user: User;
  refresh: () => void;
}
const Personal = (props: Props) => {
  const { user, refresh } = props;
  const [isSaving, setSaving] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    // @ts-ignore
    defaultValues: { ...props.user, public: String(props.user.public) },
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      const body = {
        ...data,
      };
      await api.put(`/auth/profile`, body);
      refresh();
      toast.success("Data has been updated");
      setSaving(false);
    } catch (e) {
      toast.error("Something went wrong, please try again later");
    }
  };
  const translations = useTranslations();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        title={translations.account.personalInformation}
        toolbar={
          <div className="d-lex">
            <Button color="primary" className="ml-5" isLoading={isSaving}>
              {translations.global.save}
            </Button>
          </div>
        }
      >
        <div className="form-group row">
          <label className="col-xl-3 col-lg-3 col-form-label">
            {translations.account.avatar}
          </label>
          <div className="col-lg-9 col-xl-6">
            <EditAvatar user={user} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              name="email"
              disabled
              label={translations.account.emailAddress}
              ref={register}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="phoneNumber"
              label={translations.account.phoneNumber}
              ref={register}
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="firstName"
              ref={register}
              label={translations.account.firstName}
              error={errors.firstName}
              required
            />
          </div>
          <div className="col-md-6">
            <TextField
              name="lastName"
              ref={register}
              label={translations.account.lastName}
              error={errors.lastName}
              required
            />
          </div>
        </div>
      </Card>
    </form>
  );
};

export default Personal;
