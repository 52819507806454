import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';

import { useTranslations } from 'shared/hooks/useTranslations';
import { deleteCustomerFacilityInventoryItem } from 'shared/services/api/facilities/inventoryItems';
import { FacilityInventoryItem } from 'shared/services/api/facilities/inventoryItems/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  facilityInventoryItem: FacilityInventoryItem;
}
const DeleteFacilityInventoryItemModal = (props: Props) => {
  const {
    open,
    onClose,
    onSuccess,
    customerId,
    facilityId,
    facilityInventoryItem,
  } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await deleteCustomerFacilityInventoryItem(
        customerId,
        facilityId,
        facilityInventoryItem.id
      );
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń sprzet">
        <Alert color="red">Czy na pewno chcesz usunąć ten sprzęt?</Alert>
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button variant="outline" onClick={onClose}>
            {translations.global.cancel}
          </Button>
          <Button
            type="submit"
            loading={loading}
            color="red"
            onClick={handleSubmit}
          >
            {translations.global.delete}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteFacilityInventoryItemModal;
