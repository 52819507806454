import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Create from './Create';
import ROUTES from 'shared/constants/routes';
import Manage from './Manage';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
function InventoryGroupsRoutes() {
  return (
    <Switch>
      <Route path={ROUTES.inventory.requests.list.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_REQUEST_ADD]}>
          <List />
        </WithRole>
      </Route>
      <Route path={ROUTES.inventory.requests.create.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_REQUEST_ADD]}>
          <Create />
        </WithRole>
      </Route>

      <Route path={ROUTES.inventory.requests.manage.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_REQUEST_MANAGE]}>
          <Manage />
        </WithRole>
      </Route>
    </Switch>
  );
}

export default InventoryGroupsRoutes;
