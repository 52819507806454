import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Controller, FieldError } from 'react-hook-form';
import cn from 'classnames';

interface Option {
  value: string;
  label: string;
}
interface Props {
  label?: string;
  placeholder?: string;
  tip?: string;
  required?: boolean;
  name: string;
  error?: FieldError;
  defaultValue?: any;
  row?: boolean;
  solid?: boolean;
  control: any;
  className?: string;
  options: Option[];
  multi?: boolean;
  creatable?: boolean;
}
const ReactSelectField = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      placeholder,
      tip,
      label,
      required,
      name,
      error,
      row,
      className,
      control,
      options,
      multi,
      defaultValue,
      creatable,
    } = props;
    const inputClasses = cn('', {
      'is-invalid': error,
    });
    const wrapperClasses = cn('form-group', className, {
      row: row,
    });
    const inputWrapperClasses = cn('', {
      'col-lg-9 col-xl-6': row,
    });
    const labelClasses = cn('', {
      'col-xl-3 col-lg-3 col-form-label': row,
    });
    return (
      <div className={wrapperClasses}>
        {label && (
          <label className={labelClasses}>
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <div className={inputWrapperClasses}>
          <Controller
            // @ts-ignore
            as={creatable ? CreatableSelect : Select}
            name={name}
            options={options}
            isMulti={multi}
            placeholder={placeholder}
            isClearable
            defaultValue={defaultValue}
            control={control}
            className={inputClasses}
            noOptionsMessage={() => <>Type to create custom data</>}
          />
          {/* <Select
          ref={ref}
          className={inputClasses}
          placeholder={placeholder}
          type={type}
          required={required}
          name={name}
          defaultValue={defaultValue}
        /> */}
        </div>
        {error && <small className="text-danger">{error.message}</small>}
        {tip && !error && <span className="form-text text-muted">{tip}</span>}
      </div>
    );
  }
);

export default ReactSelectField;
