import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';
import OrderDetails from './Details';

function CustomerFacilityOrdersRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.facilities.orders.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <List />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.customers.facilities.orders.details.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <OrderDetails />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomerFacilityOrdersRoutes;
