import {
  Alert,
  Button,
  Group,
  Modal,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchAuditTask } from 'shared/services/api/company/auditTasks';
import { AuditTask } from 'shared/services/api/company/auditTasks/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  auditTask: AuditTask;
}
const EditAuditTaskModal = (props: Props) => {
  const { open, onClose, onSuccess, auditTask } = props;

  const form = useForm({
    initialValues: {
      name: auditTask?.name,
      order: auditTask?.order,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchAuditTask(auditTask.id, {
        name: values.name,
        order: values.order,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auditTask && open) {
      form.setValues({
        name: auditTask?.name,
        order: auditTask?.order,
      });
    }
    // eslint-disable-next-line
  }, [auditTask, open]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Stwórz zadanie">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <NumberInput
            withAsterisk
            label="Kolejnosc"
            min={0}
            placeholder=""
            {...form.getInputProps('order')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditAuditTaskModal;
