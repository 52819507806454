import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// TODO: prod-deploy - should be in env
// let firebaseConfig = {
//   apiKey: 'AIzaSyC8LGYYkcqlRp__TzRIlNE1hVQ5nagJbKY',
//   authDomain: 'matiko-dev.firebaseapp.com',
//   projectId: 'matiko-dev',
//   storageBucket: 'matiko-dev.appspot.com',
//   messagingSenderId: '482747852235',
//   appId: '1:482747852235:web:ce535f5aa70c78f421424e',
//   measurementId: 'G-76FHVJWBZ8',
// };
// if (process.env.REACT_APP_API_ENV === 'prod') {
//   firebaseConfig = {
//     apiKey: 'AIzaSyCk9XjD2mgMnVqrYo1mR-MJd4n6JSQ8V50',
//     authDomain: 'matiko-prod.firebaseapp.com',
//     projectId: 'matiko-prod',
//     storageBucket: 'matiko-prod.appspot.com',
//     messagingSenderId: '996492017629',
//     appId: '1:996492017629:web:89561bee2454bcf7e50034',
//     measurementId: 'G-S8XW8RKCWD',
//   };
// }
// if (process.env.REACT_APP_API_ENV === 'procleaner') {
const firebaseConfig = {
  apiKey: 'AIzaSyBKZzF7Wm9R_LmkkZDzSf0mK_g88Sxdy-E',
  authDomain: 'procleaner-cloud-prod.firebaseapp.com',
  projectId: 'procleaner-cloud-prod',
  storageBucket: 'procleaner-cloud-prod.appspot.com',
  messagingSenderId: '360841927693',
  appId: '1:360841927693:web:a2e2d1961d9eae0e710944',
  measurementId: 'G-39ZB2PGSXP',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.firestore();

const getFirebaseError = (error: string) => {
  const errors = {
    'auth/wrong-password': 'Błędne hasło lub błędny sposób logowania.',
    'auth/user-not-found': 'Błędne hasło lub błędny sposób logowania.',
    'auth/account-exists-with-different-credential':
      'Account already exists, but is connected to different type of signing in.',
    'auth/weak-password': 'Weak password.Must be at least 6 chars long',
    'auth/email-already-in-use': 'Account already exists.',
  };
  // @ts-ignore
  if (errors[error]) {
    // @ts-ignore
    return errors[error];
  }

  return 'Coś poszło nie tak, spróbuj ponownie później';
};

export const getAuthUserRoles = async () => {
  const user = await auth.currentUser?.getIdTokenResult();
  const roles = user?.claims || [];
  return roles;
};

export const hasUserRole = async (
  role: 'trainer' | 'client' | 'admin' | 'super_admin'
) => {
  const user = await auth.currentUser?.getIdTokenResult();
  const roles = user?.claims?.roles || [];
  return roles?.includes(role) || user?.claims.role === 'SUPER_ADMIN';
};

export { auth, firebase, getFirebaseError, database };
