import {
  Button,
  Grid,
  Group,
  LoadingOverlay,
  NavLink,
  Navbar,
  Table,
} from '@mantine/core';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import Card from 'shared/components/Card';
import api from 'shared/services/api';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import { formatDate } from 'shared/utils/time';

interface FacilityWorkOrganisation {
  id: string;
  customerId: string;
  facilityId: string;
  name: string;
  active: boolean;
  data: {
    name: string;
    rows: {
      no: string;
      placeJobDescription: string;
      hours: string;
      numberOfEmployees: string;
      hrsDayPerson: string;
      numberOfWorkdaysPerWeek: string;
      averageNumberHrsMonth: string;
    }[];
  }[];
}

const CustomerFacilityWorkOrganisations = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event) => {
    uploadFiles(event.target.files);
  };
  const [isUploading, setUploading] = useState(false);

  const { data, refresh, isLoading } = useFetch<{
    rows: FacilityWorkOrganisation[];
  }>({
    initialValue: {},
    fetchAction: () =>
      api.get(
        `/customers/${customerId}/facilities/${facilityId}/work-organisations`
      ),
  });

  const uploadFiles = async (fileList: FileList) => {
    try {
      setUploading(true);
      const files = Array.from(fileList);
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const workOrganisationName = formatDate(new Date());
          formData.append('name', workOrganisationName);
          await api.post(
            `/customers/${customerId}/facilities/${facilityId}/work-organisations`,
            formData,
            {
              headers: {
                'content-type': 'multipart/form-data',
              },
            }
          );
        })
      );
      refresh();
      setUploading(false);
      //   refreshData();
    } catch (error) {
      setUploading(false);
      alert('Failed to upload files');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.type = 'text';
        fileInputRef.current.type = 'file';
      }
    }
  };

  const handleClickOnUpload = (
    e: any //: React.ChangeEventHandler<HTMLInputElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    fileInputRef.current?.click();
  };

  const history = useHistory();
  const navigate = (a) => {
    history.push(a);
  };
  const match = useRouteMatch(
    ROUTES.customers.facilities.workOrganisations.details.path
  );
  // @ts-ignore
  const selectedAreaId = match?.params?.workOrganisationId;

  const values = useMemo(() => {
    if (!data || !data.rows || !data.rows.length) return;
    const activeWorkOrganisation = data.rows[0]?.data;
    const values = Object.values(activeWorkOrganisation);
    return values;
  }, [data]);

  const selectedArea = useMemo(() => {
    if (!data || !data.rows || !data.rows.length) return;
    const activeWorkOrganisation = data.rows[0]?.data;
    const values = Object.values(activeWorkOrganisation);
    return values[Number(selectedAreaId)];
  }, [data, selectedAreaId]);

  useEffect(() => {
    if (!selectedAreaId) {
      navigate(
        ROUTES.customers.facilities.workOrganisations.details.to(
          customerId,
          facilityId,
          '0'
        )
      );
    }
    // eslint-disable-next-line
  }, [selectedAreaId]);
  const moreThanOneSheet = !!values && values?.length > 1;
  return (
    <div>
      <Card
        title="Organizacja pracy"
        toolbar={
          <Group>
            <Button
              onClick={handleClickOnUpload}
              variant="outline"
              loading={isUploading}
            >
              Zaktualizuj organizacje pracy
            </Button>
          </Group>
        }
      >
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {!values && !isLoading && (
          <div>
            Brak aktualnej organizacji pracu. Wczytaj plik .xlsx aby
            zaktualizować aktualną organizację pracy
          </div>
        )}
        {isLoading && <LoadingOverlay visible />}
        {values && (
          <Grid>
            {moreThanOneSheet && (
              <Grid.Col span={3}>
                <Navbar width={{ base: '100%' }}>
                  <Navbar.Section>
                    {values?.map((value, index) => (
                      <NavLink
                        key={value.name}
                        label={value.name}
                        active={selectedAreaId === String(index)}
                        onClick={() => {
                          navigate(
                            ROUTES.customers.facilities.workOrganisations.details.to(
                              customerId,
                              facilityId,
                              String(index)
                            )
                          );
                          // onSelectFacility(facility)
                        }}
                      />
                    ))}
                  </Navbar.Section>
                </Navbar>
              </Grid.Col>
            )}
            <Grid.Col span={moreThanOneSheet ? 9 : 12}>
              {selectedArea && (
                <>
                  {/* <Title order={2}>{selectedArea.name}</Title> */}
                  <div>
                    {/* <Title order={4} mb={10} className={styles.title}>
                      {area.name}
                    </Title> */}
                    <Table mb={20}>
                      <thead>
                        <tr>
                          <th>Lp.</th>
                          <th>Miejsce – Opis pracy</th>
                          <th>Godziny</th>
                          <th>Ilość pracowników</th>
                          <th>rbh/dzień/os.</th>
                          <th>Ilość dni pracy w tygodniu</th>
                          <th>średnia liczba rbh/m-c</th>
                          {/* <th>Strefa</th>
                            <th className={styles.period}>Okres</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedArea.rows?.map((area, index) => (
                          <tr key={index}>
                            <td>
                              <TextWithNewLines>{area.no}</TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>
                                {area.placeJobDescription}
                              </TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>{area.hours}</TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>
                                {area.numberOfEmployees}
                              </TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>
                                {area.hrsDayPerson}
                              </TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>
                                {area.numberOfWorkdaysPerWeek}
                              </TextWithNewLines>
                            </td>
                            <td>
                              <TextWithNewLines>
                                {area.averageNumberHrsMonth}
                              </TextWithNewLines>
                            </td>
                          </tr>
                          /* {area.subAreas.map((subArea, index) => (
                          ))} */
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </Grid.Col>
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default CustomerFacilityWorkOrganisations;

const TextWithNewLines = ({ children }) => {
  if (!children) return null;
  return (
    <div>
      {String(children)
        ?.split('\n')
        .map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
    </div>
  );
};
