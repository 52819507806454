import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Card from 'shared/components/Card';
import { useParams } from 'react-router';
import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import api from 'shared/services/api';
import { useForm } from '@mantine/form';
import { getInventoryItems } from 'shared/services/api/inventory';
import {
  Group,
  Button as MantineBtn,
  LoadingOverlay,
  Affix,
  Transition,
  rem,
  Loader,
  Button,
} from '@mantine/core';
import { formatDate } from 'shared/utils/time';
import { useTranslations } from 'shared/hooks/useTranslations';
import GenerateOutputModal from '../GenerateOutputModal';
import { randomId } from '@mantine/hooks';
import DeletePdfModal from '../DeletePdfModal';

interface Params {
  id: string;
}
export default function EditPdfTemplate() {
  const { id } = useParams<Params>();

  const [outputDownloading, setOutputDownloading] = useState<
    undefined | string
  >();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api.get(`/pdf/${id}`),
  });

  const { data: dataTemplates, isLoading: isLoadingTemplates } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api.get(`/pdf/templates`),
  });
  const { data: dataInventory, isLoading: isLoadingInventory } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryItems(),
  });
  const [isSaving, setSaving] = useState(false);
  const [isGenerating, setGenerating] = useState(false);

  const form = useForm({
    initialValues: {
      name: undefined,
      pages: [],
      templateId: undefined,
      configuration: {
        pages: {},
        inventory: [],
        addIntroDate: 'YES',
        logo: undefined,
        logoInput: undefined,
      },
    },
  });
  useEffect(() => {
    if (!data.pdf) {
      return;
    }
    const configuration = {
      pages: {},
      inventory: [],
      addIntroDate: 'YES',
      logo: undefined,
      logoInput: undefined,
      enTranslation: undefined,
    };
    data.pdf.template?.configuration?.pages?.forEach((page) => {
      const renderValue = get(
        data,
        `pdf.configuration.pages.${page.id}.render`
      );
      configuration.pages[page.id] = {
        render:
          renderValue === undefined ? page.isActive === 'YES' : renderValue, //,
        wysywig: get(data, `pdf.configuration.pages.${page.id}.wysywig`),
        serviceValue: get(
          data,
          `pdf.configuration.pages.${page.id}.serviceValue`
        ) || [{ value: '12 345.00 zł', active: false, key: randomId() }],
        customFilePath: get(
          data,
          `pdf.configuration.pages.${page.id}.customFilePath`
        ),
        customFileName: get(
          data,
          `pdf.configuration.pages.${page.id}.customFileName`
        ),
        enTranslation: get(
          data,
          `pdf.configuration.pages.${page.id}.enTranslation`
        ),
      };
    });
    if (data.pdf?.configuration?.logo) {
      configuration.logo = data.pdf?.configuration.logo;
    }
    if (data.pdf?.configuration?.inventory) {
      Object.values(data.pdf?.configuration?.inventory).forEach(
        (item, index) => {
          // const itemId = Object.keys(data.pdf?.configuration?.inventory)[index];
          // @ts-ignore
          configuration.inventory.push(item);
        }
      );
    }
    if (data.pdf?.configuration?.addIntroDate) {
      configuration.addIntroDate = data.pdf?.configuration?.addIntroDate;
    }
    if (data.pdf?.configuration?.enTranslation) {
      configuration.enTranslation = data.pdf?.configuration?.enTranslation;
    }
    form.setValues({
      name: data.pdf?.name,
      templateId: data.pdf.templateId,
      configuration: configuration || {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form.setValues]);

  const savePdf = async (data: any) => {
    const formData = new FormData();

    // Upload logo
    let logo = data.configuration?.logo;
    if (data.configuration?.logoInput) {
      formData.append('file', data.configuration.logoInput[0]);
      const { data: dataLogo } = await api.post(`/pdf/${id}/logo`, formData, {
        // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      logo = dataLogo.logo;
      form.setFieldValue('configuration.logo', logo);
    }

    // Upload custom files
    await Promise.all(
      Object.keys(data.configuration.pages).map(async (pageId) => {
        if (data.configuration?.pages[pageId]?.customFileInput) {
          const formData = new FormData();
          const file = data.configuration?.pages[pageId]?.customFileInput[0];
          formData.append('file', file);
          const { data: customFileData } = await api.post(
            `/pdf/${id}/custom-file`,
            formData,
            {
              // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
              headers: {
                'content-type': 'multipart/form-data',
              },
            }
          );

          form.setFieldValue(
            `configuration.pages.${pageId}.customFile`,
            customFileData.filePath
          );
          form.setFieldValue(
            `configuration.pages.${pageId}.customFileInput`,
            undefined
          );
          data.configuration.pages[pageId].customFilePath =
            customFileData.filePath;
          data.configuration.pages[pageId].customFileName = file.name;
        }
      })
    );

    await api.patch(`/pdf/${id}`, {
      ...data,
      configuration: { ...data.configuration, logo },
    });
    // @ts-ignore
    form.setFieldValue('configuration.logoInput', undefined);
  };

  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      await savePdf(data);
      refresh();
      toast.success('PDF został zaktualizowany');
      setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error('Coś poszło nie tak, spróbuj za chwilę.');
    }
  };

  const handleGenerate = async (outputFormValues) => {
    const formValues = form.values;
    try {
      setGenerating(true);
      await savePdf(formValues);
      const { data: result } = await api.post(
        `/pdf/${id}/generate-pdf`,
        {
          configuration: form.values.configuration,
          template: data?.pdf?.template,
          outputFormValues,
        },
        {
          baseURL: process.env.REACT_APP_PDF_GENERATOR_ENDPOINT,
        }
      );

      setIsOpenGenerateModal(false);
      refresh();
      window.open(result.url, '_blank');
    } catch {
    } finally {
      setGenerating(false);
    }
  };

  const handleDownload = async (fileId: string) => {
    try {
      setOutputDownloading(fileId);
      const { data } = await api.get(`/pdf/${id}/outputs/${fileId}/signed-url`);
      window.open(data.url, '_blank');
    } catch {
    } finally {
      setOutputDownloading(undefined);
    }
  };
  const [isOpenGenerateModal, setIsOpenGenerateModal] = useState(false);
  const handleOpenGenerateModal = () => {
    setIsOpenGenerateModal(true);
  };
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true);
  };

  const translations = useTranslations();

  return (
    <div className="container">
      <GenerateOutputModal
        loading={isGenerating}
        show={isOpenGenerateModal}
        onSubmit={handleGenerate}
        onClose={() => {
          setIsOpenGenerateModal(false);
        }}
        outputs={data?.pdf?.outputs || []}
      />
      <DeletePdfModal
        pdfId={id}
        show={isOpenDeleteModal}
        onClose={() => {
          setIsOpenDeleteModal(false);
        }}
      />
      <Breadcrumb>
        <Breadcrumb.Item linkProps={{ to: ROUTES.pdf.list.path }} linkAs={Link}>
          PDF
        </Breadcrumb.Item>
        <Breadcrumb.Item linkProps={{ to: ROUTES.pdf.list.path }} linkAs={Link}>
          {translations.pdf.list}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.template?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <Affix position={{ bottom: rem(20), right: rem(20) }}>
        <Transition transition="slide-up" mounted={isGenerating}>
          {(transitionStyles) => (
            <MantineBtn
              leftIcon={<Loader color="white" size="sm" />}
              style={transitionStyles}
              size="lg"
            >
              {translations.pdf.isGenerating}
            </MantineBtn>
          )}
        </Transition>
      </Affix>
      <div className="row">
        <div className="col-xl-7">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && <div className="alert alert-danger">No PDF template</div>}
          {isLoaded && (
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Card
                title={translations.pdf.editPdfFile}
                toolbar={
                  <Group>
                    <Button
                      color="red"
                      type="button"
                      onClick={handleOpenDeleteModal}
                      mr={'auto'}
                    >
                      {translations.global.delete}
                    </Button>
                    <Button
                      color="violet"
                      type="button"
                      onClick={handleOpenGenerateModal}
                    >
                      {translations.pdf.generate}
                    </Button>
                    <Button color="primary" loading={isSaving} type="submit">
                      {translations.global.save}
                    </Button>
                  </Group>
                }
              >
                <LoadingOverlay
                  visible={isLoadingInventory || isLoadingTemplates}
                />
                <ExerciceForm
                  form={form}
                  templates={dataTemplates?.templates || []}
                  mode="EDIT"
                  template={data?.pdf?.template}
                  inventoryItems={dataInventory?.items}
                  pdfData={data}
                />
              </Card>
            </form>
          )}
        </div>
        <div className="col-xl-5">
          <Card title={translations.pdf.outputs}>
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {isLoaded && (
              <>
                {data?.pdf?.outputs?.map((output) => {
                  return (
                    <Group key={output.id} mb={10}>
                      <div>
                        {formatDate(output.createdAt)}
                        <br />
                        {output.configuration?.description}
                      </div>
                      <MantineBtn
                        // style={}
                        onClick={() => handleDownload(output.id)}
                        ml="auto"
                        color="info"
                        size="xs"
                        loading={outputDownloading === output.id}
                        disabled={
                          outputDownloading !== undefined &&
                          outputDownloading !== output.id
                        }
                      >
                        {translations.global.download}
                      </MantineBtn>
                    </Group>
                  );
                })}
              </>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
