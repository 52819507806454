export function createFacilityAreaSelectOptions(data, prefix = '') {
  let options = [];
  data?.forEach((row) => {
    // Dodaj aktualny wiersz jako opcję z odpowiednim prefixem
    // @ts-ignore
    options.push({ value: row.id, label: `${prefix}${row.name}` });
    // Jeśli istnieją subAreas, rekurencyjnie dodaj je do opcji
    if (row.subAreas && row.subAreas.length > 0) {
      const subOptions = createFacilityAreaSelectOptions(
        row.subAreas,
        prefix + '-'
      );
      options = options.concat(subOptions);
    }
  });
  return options;
}
