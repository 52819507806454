import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ExerciceList from 'modules/InventoryItems/List';
import CreateExercice from 'modules/InventoryItems/Create';
import Details from 'modules/InventoryItems/Details';
import ROUTES from 'shared/constants/routes';
import EditExercice from './Edit';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
function Exercices() {
  return (
    <Switch>
      <Route path={ROUTES.inventory.default.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <Redirect to={ROUTES.inventory.items.list.path} />
        </WithRole>
      </Route>
      <Route path={ROUTES.inventory.items.list.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <ExerciceList />
        </WithRole>
      </Route>
      <Route path={ROUTES.inventory.items.create.path} exact>
        {/* <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_CREATE]}> */}
          <CreateExercice />
        {/* </WithRole> */}
      </Route>
      <Route path={ROUTES.inventory.items.details.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <Details />
        </WithRole>
      </Route>
      <Route path={ROUTES.inventory.items.edit.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_EDIT]}>
          <EditExercice />
        </WithRole>
      </Route>
    </Switch>
  );
}

export default Exercices;
