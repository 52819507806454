import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import CustomCard from 'shared/components/Card';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';
import { getCustomerFacilityDashboard } from 'shared/services/api/facilities';
import moment from 'moment';
import { getCustomerFacilityAuditsScore } from 'shared/services/api/facilities/audits';
import { formatDateWithoutTime } from 'shared/utils/time';
import { Box, Button, Group } from '@mantine/core';

const FacilityDashboard = () => {
  const { customerId, facilityId } = useParams<any>();
  const [options, setOptions] = useState({
    // chart: {
    //   type: 'bar',
    //   height: 350,
    // },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: 'Requests (qty)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' requests';
        },
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: 'Total Requests',
      data: [],
    },
    { name: 'Open Requests', data: [] }, // Nowa seria
    { name: 'In Progress Requests', data: [] }, // Nowa seria
    {
      name: 'Closed Requests',
      data: [],
    },
  ]);
  const {
    data: dataDashboard,
    // isLoading,
    // isLoaded,
    // error,
    // refresh,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getCustomerFacilityDashboard(customerId, facilityId),
  });
  const {
    data: dataAudits,
    // isLoading: isLoadingAudits,
    // isLoaded: isLoadedAudits,
    // error: errorAudits,
    // refresh: refreshAudits,
  } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getCustomerFacilityAuditsScore(customerId, facilityId),
  });

  useEffect(() => {
    if (!dataDashboard.requests) return;
    const data = dataDashboard.requests;
    const months = data.map((item) =>
      moment()
        .month(item.month - 1)
        .format('MMMM')
    );
    const openRequests = data.map((item) => item.openRequests); // Nowe dane
    const totalRequests = data.map((item) => item.totalRequests);
    const closedRequests = data.map((item) => item.closedRequests);
    const inProgressRequests = data.map((item) => item.inProgressRequests);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: { categories: months },
    }));

    setSeries([
      { name: 'Suma zgłoszeń', data: totalRequests },
      { name: 'Otwarte', data: openRequests }, // Nowa seria
      { name: 'W trakcie', data: inProgressRequests }, // Nowa seria
      { name: 'Zamknięte', data: closedRequests },
    ]);
  }, [dataDashboard]);

  const chartOptions = useMemo(() => {
    const chartData = dataAudits.audit?.chartData || [];
    return {
      series: [
        {
          name: 'Average Percentage Score',
          // data: chartData,
          data: chartData.map((dataPoint) => dataPoint.y), // Przypisuje tylko wartości y
        },
      ],
      markers: {
        size: 5, // Rozmiar kropek na wykresie
        // colors: undefined, // Domyślne kolory
        // strokeColors: '#fff', // Kolor obramowania kropek
        // strokeWidth: 2, // Szerokość obramowania kropek
        // strokeOpacity: 0.9,
        // strokeDashArray: 0,
        // fillOpacity: 1,
        // discrete: [], // Możesz określić specyficzne ustawienia dla konkretnych kropek
        // shape: "circle", // Kształt kropek, może być "circle", "square" itd.
        // radius: 2, // Promień zaokrąglenia obramowania kropek
        // offsetX: 0,
        // offsetY: 0,
        // onClick: undefined,
        // onDblClick: undefined,
        // showNullDataPoints: true, // Pokazuje kropki nawet dla danych null (możesz to wyłączyć)
        // hover: {
        //   size: undefined,
        //   sizeOffset: 3
        // }
      },
      chart: {
        type: 'line',
        height: 350,
      },
      xaxis: {
        categories: chartData.map((dataPoint) =>
          formatDateWithoutTime(dataPoint.x)
        ), // Ustawia daty audytów jako kategorie
        labels: {
          rotate: -45,
        },
        tickPlacement: 'on',
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy', // Format daty w dymku narzędzi
        },
      },
      dataLabels: {
        enabled: false, // Wyłącza etykiety danych na wykresie, aby uniknąć zatłoczenia
      },
    };
  }, [dataAudits.audit]);
  return (
    <>
      <div style={{}}>
        <Box display="none">
          <Group
            ml="auto"
            mb={20}
            align="flex-end"
            style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
          >
            <Button>Edytuj</Button>
            <Button color="red">Usuń</Button>
          </Group>
        </Box>
        <CustomCard
          title={`Zgłoszenia ${new Date().getFullYear()}`}
          //   toolbar={
          // <Button onClick={() => setCreateCustomerModalOpen(true)}>
          //   {/* TODO: Translations */}
          //   Dodaj klienta
          // </Button>
          //   }
        >
          <Chart options={options} series={series} type="bar" height={320} />
        </CustomCard>
        <CustomCard title={'Jakość audytów'}>
          {dataAudits.audit?.chartData && (
            <Chart
              // options={state.options}
              // series={state.series}
              // @ts-ignore
              options={chartOptions}
              series={chartOptions?.series}
              type="area"
              height={350}
            />
          )}
        </CustomCard>
        {/* <CustomCard title={'Jakość audytów'}>
          <Chart
            options={state.options}
            series={state3.series}
            type="donut"
            width={380}
          />
        </CustomCard> */}
      </div>
    </>
  );
};

export default FacilityDashboard;
