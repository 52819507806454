import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { yupResolver } from '@hookform/resolvers/yup';
import ExerciceForm from '../Form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import validation from '../validation';

import { createInventoryRequest } from 'shared/services/api/inventory';
import { useTranslations } from 'shared/hooks/useTranslations';

export default function CreateInventoryGroup() {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      priority: 'LOW',
      usability: 'PUBLIC',
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await createInventoryRequest(data);
      toast.success('Request has been added');
      history.push(ROUTES.inventory.requests.list.path);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };

  const translations = useTranslations();

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.requests.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.requests.list.path }}
          linkAs={Link}
        >
          {translations.inventory.requests}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {' '}
          {translations.inventory.addRequest}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card
              title={translations.inventory.addRequest}
              toolbar={
                <Button color="primary" isLoading={isLoading}>
                  {translations.global.create}
                </Button>
              }
            >
              <ExerciceForm
                errors={errors}
                register={register}
                control={control}
              />
            </Card>
          </form>
        </div>
      </div>
    </div>
  );
}
