import React from 'react';
import { Spinner } from 'react-bootstrap';
import Card from 'shared/components/Card';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import FacilityDashboard from './FacilityDashboard';
import CustomerFacilityAreasRoutes from './Areas';
import CustomerFacilityUsersRoutes from './Users';
import FacilityFiles from './Files';
import CustomerFacilityRequestsRoutes from './Requests';
import LeftNavLinkItem from 'shared/components/layout/LeftNavLinkItem';
import CustomerFacilityOrdersRoutes from 'modules/Customers/Details/Facilities/Details/Orders';
import CustomerFacilityAdditionalServicesRoutes from 'modules/Customers/Details/Facilities/Details/AdditionalService';
import CustomerFacilityInventoryItemsRoutes from 'modules/Customers/Details/Facilities/Details/InventoryItems';
import CustomerFacilityAuditsRoutes from 'modules/Customers/Details/Facilities/Details/Audits';
import CustomerFacilityWorkSchedules from 'modules/Customers/Details/Facilities/Details/WorkSchedules';
import CustomerFacilityWorkOrganisations from 'modules/Customers/Details/Facilities/Details/WorkOrganisation';
import useFetch from 'shared/hooks/useFetch';
import { Customer } from 'shared/services/api/customers/types';
import { getCustomerFacility } from 'shared/services/api/facilities';
import { getCustomer } from 'shared/services/api/customers';
import { LoadingOverlay } from '@mantine/core';
import { Facility } from 'shared/services/api/facilities/types';

const CustomerFacilityDetails = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();

  const isLoading = false;

  const links = [
    {
      route: ROUTES.customers.facilities.details.to(customerId, facilityId),
      icon: 'fas fa-chart-line',
      text: 'Informacje o obiekcie',
      exact: true,
    },
    {
      route: ROUTES.customers.facilities.files.to(customerId, facilityId),
      icon: 'fas fa-folder',
      text: 'Dokumenty',
    },
    {
      route: ROUTES.customers.facilities.workSchedules.to(
        customerId,
        facilityId
      ),
      icon: 'fas fa-calendar-alt',
      text: 'Harmonogram pracy',
    },
    {
      route: ROUTES.customers.facilities.workOrganisations.to(
        customerId,
        facilityId
      ),
      icon: 'fas fa-calendar-alt',
      text: 'Organizacja pracy',
    },

    {
      route: ROUTES.customers.facilities.orders.to(customerId, facilityId),
      icon: 'fas fa-shopping-cart',
      text: 'Zamówienia',
    },
    {
      route: ROUTES.customers.facilities.additionalServices.to(
        customerId,
        facilityId
      ),
      icon: 'fas fa-concierge-bell',
      text: 'Usługi dodatkowe',
    },
    {
      route: ROUTES.customers.facilities.audits.to(customerId, facilityId),
      icon: 'fas fa-list',
      text: 'Audyty',
    },
    {
      route: ROUTES.customers.facilities.requests.to(customerId, facilityId),
      icon: 'fas fa-tools',
      text: 'Zgłoszenia',
    },
    {
      route: ROUTES.customers.facilities.inventoryItems.to(
        customerId,
        facilityId
      ),
      icon: 'fas fa-car',
      text: 'Sprzęt',
    },

    {
      route: ROUTES.customers.facilities.areas.to(customerId, facilityId),
      icon: 'fas fa-warehouse',
      text: 'Strefy',
    },
    {
      route: ROUTES.customers.facilities.users.to(customerId, facilityId),
      icon: 'fas fa-users',
      text: 'Użytkownicy',
    },
  ];
  const { data: customerData, isLoading: isCustomerLoading } = useFetch<{
    customer: Customer;
  }>({
    initialValue: {},
    fetchAction: () => getCustomer(customerId),
  });

  const { data: facilityData, isLoading: isLoadingFacility } = useFetch<{
    facility: Facility;
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacility(customerId, facilityId),
  });

  return (
    <>
      <LoadingOverlay visible={isCustomerLoading || isLoadingFacility} />
      <div className="" style={{ marginLeft: '-2rem' }}>
        <div className="d-flex flex-row">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {!isLoading && (
            <>
              <div className="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
                <Card>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                      {/* <img src={user?.avatar || avatarBoyPlaceholder} alt="" /> */}
                    </div>
                    <div>
                      <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                        {facilityData.facility?.name}

                        {/* TODO: add company name  */}
                        <br />
                        <small>
                          firma:{' '}
                          <Link to={ROUTES.customers.details.to(customerId)}>
                            {customerData.customer?.name}
                          </Link>
                        </small>
                      </span>
                      <div>
                        <strong>Adres:</strong>
                        <br />
                        {facilityData.facility?.address}
                      </div>
                      {/* <div className="text-muted">{user.city}</div> */}
                    </div>
                  </div>
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-10">
                    <div className="navi-item mb-2">
                      {links.map((link) => (
                        <LeftNavLinkItem
                          key={link.text}
                          exact={link.exact}
                          to={link.route}
                          icon={link.icon}
                          text={link.text}
                        />
                      ))}
                    </div>
                  </div>
                </Card>
              </div>
              <div className="flex-row-fluid ml-lg-8">
                {/* <div className="row"> */}
                {/* <div className="col-lg-12"> */}
                <Switch>
                  <Route path={ROUTES.customers.facilities.details.path} exact>
                    <FacilityDashboard />
                    {/* <CustomerMainData /> */}
                  </Route>
                  <Route path={ROUTES.customers.facilities.files.path}>
                    <FacilityFiles />
                  </Route>
                  <Route path={ROUTES.customers.facilities.areas.path}>
                    <CustomerFacilityAreasRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.users.path}>
                    <CustomerFacilityUsersRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.requests.path}>
                    <CustomerFacilityRequestsRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.orders.path}>
                    <CustomerFacilityOrdersRoutes />
                  </Route>
                  <Route
                    path={ROUTES.customers.facilities.additionalServices.path}
                  >
                    <CustomerFacilityAdditionalServicesRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.inventoryItems.path}>
                    <CustomerFacilityInventoryItemsRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.audits.path}>
                    <CustomerFacilityAuditsRoutes />
                  </Route>
                  <Route path={ROUTES.customers.facilities.workSchedules.path}>
                    <CustomerFacilityWorkSchedules />
                  </Route>
                  <Route
                    path={ROUTES.customers.facilities.workOrganisations.path}
                  >
                    <CustomerFacilityWorkOrganisations />
                  </Route>
                </Switch>
                {/* </div> */}
                {/* </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerFacilityDetails;
