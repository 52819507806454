import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';

function CustomerCompanyServicesRoutes() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <Switch>
            <Route path={[ROUTES.company.services.path]} exact>
              {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
              <List />
              {/* </WithRole> */}
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default CustomerCompanyServicesRoutes;
