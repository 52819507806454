import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Card from 'shared/components/Card';
import useFetch from 'shared/hooks/useFetch';

import RemoveCompanyModal from '../RemoveCompanyModal';

import UserContext from 'shared/contexts/UserContext';
import { getCurrentUserCompany } from 'shared/services/api/company';

export default function EditExercice() {
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const { data, isLoading, isLoaded, error } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getCurrentUserCompany(),
  });

  return (
    <UserContext.Consumer>
      {({ data: user }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.company.default.path }}
              linkAs={Link}
            >
              Firma
            </Breadcrumb.Item>

            <Breadcrumb.Item active>{data.company?.name}</Breadcrumb.Item>
          </Breadcrumb>
          {isLoaded && (
            <>
              <RemoveCompanyModal
                show={removeModal}
                onClose={() => setRemoveModal(false)}
                exercice={data.item}
              />
            </>
          )}
          <div className="row">
            <div className="col-xl-12">
              {isLoading && (
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {error && <div className="alert alert-danger">No company</div>}
              {isLoaded && (
                <Card title="Informacje o firmie">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-5">
                        <div>
                          <strong>Nazwa:</strong>
                        </div>
                        {data.company?.name}
                      </div>
                      {/* <div className="mb-5">
                        <div>
                          <strong>Slug:</strong>
                        </div>
                        {data.company?.slug}
                      </div> */}
                      {/* <div className="mb-5">
                        <div>
                          <strong>Created At:</strong>
                        </div>
                        {formatDate(data.company?.createdAt)}
                      </div> */}
                    </div>
                    {/* <div className="col-md-4">
                      <h4>Statistics</h4>
                      <div className="mb-5">
                        <div>
                          <strong>Inventory items:</strong>
                        </div>
                        {data.company?.inventoryItemsCount} /{' '}
                        {data.company.inventoryItemsLimit}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>Users:</strong>
                        </div>
                        {data.company?.usersCount} / {data.company.usersLimit}
                      </div>
                    </div> */}
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
