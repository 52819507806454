import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getAuditTasks } from 'shared/services/api/company/auditTasks';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import { AuditTask } from 'shared/services/api/company/auditTasks/types';
import CreateAuditTaskModal from './CreateCompanyAuditTaskModal';
import EditAuditTaskModal from 'modules/Company/AuditTasks/List/EditCompanyAuditTaskModal';
import RemoveAuditTaskModal from 'modules/Company/AuditTasks/List/RemoveCompanyAuditTaskModal';
import { IconPencil, IconTrash } from '@tabler/icons';

const CompanyAuditTasks = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: AuditTask[];
  }>({
    initialValue: {},
    fetchAction: () => getAuditTasks(),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  useState(false);
  const [editAuditTaskModalOpen, setEditAuditTaskModalOpen] = useState<
    AuditTask | undefined
  >();
  const [removeAuditTaskModalOpen, setRemoveAuditTaskModalOpen] = useState<
    AuditTask | undefined
  >();

  const handleOnEdit = () => {
    setEditAuditTaskModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveAuditTaskModalOpen(undefined);
    refresh();
  };

  const handleEdit = (auditTask: AuditTask) => () => {
    setEditAuditTaskModalOpen(auditTask);
  };
  const handleRemove = (auditTask: AuditTask) => () => {
    setRemoveAuditTaskModalOpen(auditTask);
  };
  return (
    <div className="">
      <CreateAuditTaskModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />
      <EditAuditTaskModal
        auditTask={editAuditTaskModalOpen!}
        open={!!editAuditTaskModalOpen}
        onClose={() => setEditAuditTaskModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveAuditTaskModal
        auditTask={removeAuditTaskModalOpen!}
        open={!!removeAuditTaskModalOpen}
        onClose={() => setRemoveAuditTaskModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />
      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Zadania w audycie'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj zadanie
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Typy stref</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((auditTask) => (
                  <tr key={auditTask.id}>
                    <td>{auditTask.name}</td>
                    <td></td>
                    <td>{formatDate(auditTask.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(auditTask)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(auditTask)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompanyAuditTasks;
