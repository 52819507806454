import React, { useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import Form from '../Form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import UserContext from 'shared/contexts/UserContext';
import api from 'shared/services/api';
import { useForm } from '@mantine/form';
import useFetch from 'shared/hooks/useFetch';
import { LoadingOverlay } from '@mantine/core';
import { useTranslations } from 'shared/hooks/useTranslations';

export default function CreatePdf() {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { data: dataFiles, isLoading: isLoadingFiles } = useFetch<any>({
    initialValue: [],
    fetchAction: () => api.get(`/files`),
  });
  const translations = useTranslations();
  const form = useForm({
    initialValues: {
      name: undefined,
      configuration: {
        pages: [],
        pageBackgroundFileId: undefined,
        isActive: 'YES',
        isDefault: 'NO',
      },
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await api.post('/pdf/templates', data);
      toast.success(translations.templates.addSuccess);
      history.push(ROUTES.pdf.templates.list.path);
    } catch (e) {
      setLoading(false);
      toast.error('Something went wrong.');
    }
  };

  return (
    <UserContext.Consumer>
      {({ selectedCompany }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.pdf.list.path }}
              linkAs={Link}
            >
              Oferty
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.pdf.templates.list.path }}
              linkAs={Link}
            >
              {translations.templates.templates}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {translations.templates.createTemplate}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Card
                  title={translations.templates.createTemplate}
                  toolbar={
                    <Button color="primary" isLoading={isLoading}>
                      {translations.global.add}
                    </Button>
                  }
                >
                  <Form form={form} files={dataFiles?.files || []} />
                  <LoadingOverlay visible={isLoadingFiles} />
                </Card>
              </form>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
