import React from 'react';
import Card from 'shared/components/Card';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { getBadgeColorByInvitationStatus } from 'shared/utils/status';
import { getUsers } from 'shared/services/api/users';
import { formatDate } from 'shared/utils/time';
import { COMMON_USER_TYPE_BADGE_COLOR } from 'types/user';
import { useTranslations } from 'shared/hooks/useTranslations';
import { ActionIcon, Badge, Button, Group } from '@mantine/core';
import CreateCompanyUserModal from 'modules/Users/List/CreateCompanyUserModal';
import { IconPencil } from '@tabler/icons';

export default function ManageTrainers() {
  const history = useHistory();
  const { data: responseData, refresh } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getUsers(),
  });
  const data = responseData.users; // TODO
  const translations = useTranslations();
  const [openAddUserModal, setOpenAddUserModal] = React.useState(false);
  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
    // TODO
  };
  const handleSuccess = () => {
    setOpenAddUserModal(false);
    refresh();
    // TODO
  };
  return (
    <div className="container">
      <CreateCompanyUserModal
        open={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
        onSuccess={() => handleSuccess()}
      />
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.users.default.path }}
          linkAs={Link}
        >
          Użytkownicy
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Lista</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title="Użytkownicy"
            toolbar={<Button onClick={handleOpenAddUserModal}>Dodaj</Button>}
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th colSpan={2}>Nazwa</th>
                  <th>Adres email</th>
                  <th>Typ</th>
                  <th>Status</th>
                  <th>Klient</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((user) => (
                  <tr key={user.id}>
                    {/* <td>{user?.user?.uniqueId}</td> */}
                    <td>
                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <a href="/metronic8/demo20/../demo20/apps/user-management/users/view.html">
                          <div className="symbol-label">
                            {user?.user?.avatar ? (
                              <img
                                src={user?.user?.avatar}
                                alt="Emma Smith"
                                className="w-100"
                              />
                            ) : (
                              <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"></span>
                            )}
                          </div>
                        </a>
                      </div>
                    </td>
                    <td>
                      {user?.user?.firstName} {user?.user?.lastName}
                    </td>
                    <td>{user?.user?.email}</td>
                    <td>
                      {user?.type && (
                        <Badge color={COMMON_USER_TYPE_BADGE_COLOR[user?.type]}>
                          {translations.enums.commonUserType[user?.type]}
                        </Badge>
                      )}
                    </td>
                    <td>
                      {
                        <Badge
                          color={getBadgeColorByInvitationStatus(user.status)}
                        >
                          {user.status === 'ACTIVE' ? 'Aktywny' : 'Nieaktywny'}
                        </Badge>
                      }
                    </td>
                    <td>
                      {user.user?.customers?.map((customer) => (
                        <div>{customer.name}</div>
                      ))}
                    </td>
                    <td>{formatDate(user.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={() =>
                            history.push(ROUTES.users.details.to(user.id))
                          }
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        {/* <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(areaType)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon> */}
                      </Group>
                      {/* <Button
                        type="button"
                        color="light"
                        className="btn-sm btn-icon mr-5 ml-auto"
                        // onClick={handleEdit(user)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Button> */}
                      {/* <Button
                        type="button"
                        color="danger"
                        className="btn-sm btn-icon"
                        // onClick={handleRemove(user)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
}
