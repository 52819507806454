import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import SelectField from 'shared/components/SelectField';
import TextField from 'shared/components/TextField';
import {
  patchTrainerInvitation,
  postTrainerInvitation,
} from 'shared/services/api/admin';
import { Company } from 'shared/services/api/admin/types';
interface Props {
  show: boolean;
  onClose: Function;
  invitation?: any;
  companies: Company[];
}

const ManageTrainerInvitationModal = (props: Props) => {
  const { show, onClose, invitation, companies } = props;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({});
  useEffect(() => {
    setValue('name', invitation?.name);
  }, [invitation, setValue]);

  const handleClose = () => onClose();

  const onSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (invitation?.email) {
        await patchTrainerInvitation(invitation.email, formValues);
        toast.success('Zaproszenie zostało zmodyfikowane');
      } else {
        await postTrainerInvitation(formValues.email, formValues.company);
        toast.success('Zaproszenie zostało dodane');
      }
      onClose(true);
    } catch (e) {
      toast.error('Coś poszło nie tak. Spróbuj ponownie później.');
    } finally {
      setLoading(false);
    }
  };
  const companyOptions =
    companies?.map((option) => ({
      value: option.id,
      label: option.name,
    })) || [];
  return (
    // @ts-ignore
    <Modal show={show} onHide={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {invitation ? 'Edytuj' : 'Dodaj'} zaproszenie
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            name="email"
            ref={register}
            label="Adres e-mail"
            error={errors.email}
            required
          />
          <SelectField
            name="company"
            ref={register}
            label="Firma"
            options={companyOptions}
            error={errors.company}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Anuluj
          </Button>
          <Button color="primary" isLoading={isLoading}>
            {invitation ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default ManageTrainerInvitationModal;
