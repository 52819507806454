import React from 'react';
import { FieldError } from 'react-hook-form';
interface Props {
  label?: string;
  placeholder?: string;
  tip?: string;
  required?: boolean;
  name: string;
  error?: FieldError;
  defaultValue?: any;
}
const TextareaField = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const { placeholder, tip, label, required, name, defaultValue, error } =
      props;

    return (
      <div className="form-group">
        {label && (
          <label>
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <textarea
          ref={ref}
          className="form-control"
          placeholder={placeholder}
          required={required}
          name={name}
          defaultValue={defaultValue}
        ></textarea>
        {error && <small className="text-danger">{error.message}</small>}
        {tip && <span className="form-text text-muted">{tip}</span>}
      </div>
    );
  }
);

export default TextareaField;
