/**
 * src/Login.js
 */
import React from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { auth, firebase, getFirebaseError } from "shared/services/firebase";
import PasswordLogin from "../PasswordLogin";
import PasswordRegister from "../PasswordRegister";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import { getAppConfiguration } from "../../../config";
import { useTranslations } from "shared/hooks/useTranslations";

import ProcleanerBg from "../../../assets/img/procleaner_auth_bg.jpg";
// import MatikoBg from "../../../assets/img/procleaner_auth_bg.jpg";

export default function Login() {
  const history = useHistory();
  const googleLogin = async () => {
    //1 - init Google Auth Provider
    const provider = new firebase.auth.GoogleAuthProvider();
    //2 - create the popup signIn
    await auth.signInWithPopup(provider).then(
      async (result) => {
        //3 - pick the result and store the token
        const token = await auth?.currentUser?.getIdToken(true);
        //4 - check if have token in the current user
        if (token) {
          //6 - navigate user to the book list
          history.push("/");
        }
      },
      function (error) {
        toast.error(getFirebaseError(error.code), { autoClose: false });
      }
    );
  };
  const appConf = getAppConfiguration();
  const translations = useTranslations();
  return (
    <div className="d-flex h-100">
      <div
        className={styles.left}
        style={{
          backgroundImage: `url(${ProcleanerBg})`,
        }}
      >
        <div className={styles.leftOverlay}>
          <div className={styles.leftText}>
            <div className={styles.title}>{appConf.mainTitle}</div>
            <div className={styles.slogan}>{appConf.mainSlogan}</div>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className="row">
          <div className="col-12">
            <Switch>
              <Route path="/" exact>
                <div className="mb-10">
                  <h3>{translations.auth.signIn}</h3>
                </div>
                <PasswordLogin />
                <p className="mt-10">
                  {translations.auth.noAccount}{" "}
                  <Link to="/auth/register">{translations.auth.joinUs}</Link>
                </p>
              </Route>
              <Route path="/auth/register" exact>
                <div className="mb-10">
                  <h3>Create account</h3>
                </div>
                <PasswordRegister />
                <p className="mt-10">
                  Got account? <Link to="/auth/login">Sign In!</Link>
                </p>
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
            <div className={styles.divider} />
            <div className={styles.buttons}>
              <button className="btn btn-google" onClick={googleLogin}>
                <i className="fab fa-google"></i>{" "}
                {translations.auth.signInWithGoogle}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
