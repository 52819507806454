export const USER_PERMISIONS = [
  'USERS_LIST',
  'USERS_INVITE',
  'USERS_MANAGE',
  'INVENTORY_ITEM_CREATE',
  'INVENTORY_ITEM_LIST',
  'INVENTORY_ITEM_REMOVE',
  'INVENTORY_ITEM_EDIT',
  'INVENTORY_ITEM_TAKE',
  'INVENTORY_GROUP_CREATE',
  'INVENTORY_GROUP_LIST',
  'INVENTORY_GROUP_REMOVE',
  'INVENTORY_GROUP_EDIT',
];
export enum USER_PERMISSION {
  'USERS_LIST' = 'USERS_LIST',
  'USERS_INVITE' = 'USERS_INVITE',
  'USERS_EDIT' = 'USERS_EDIT',
  'USERS_REMOVE' = 'USERS_RMOVE',
  'USERS_INVITATIONS_LIST' = 'USERS_INVITATIONS_LIST',
  'USERS_INVITATIONS_INVITE' = 'USERS_INVITATIONS_INVITE',
  'USERS_INVITATIONS_REMOVE' = 'USERS_INVITATIONS_REMOVE',
  'INVENTORY_ITEM_CREATE' = 'INVENTORY_ITEM_CREATE',
  'INVENTORY_ITEM_LIST' = 'INVENTORY_ITEM_LIST',
  'INVENTORY_ITEM_REMOVE' = 'INVENTORY_ITEM_REMOVE',
  'INVENTORY_ITEM_EDIT' = 'INVENTORY_ITEM_EDIT',
  'INVENTORY_ITEM_EDIT_UNIQUE_ID' = 'INVENTORY_ITEM_EDIT_UNIQUE_ID',
  'INVENTORY_ITEM_ASSIGN_TO_USER' = 'INVENTORY_ITEM_ASSIGN_TO_USER',
  'INVENTORY_ITEM_TAKE' = 'INVENTORY_ITEM_TAKE',
  'INVENTORY_GROUP_CREATE' = 'INVENTORY_GROUP_CREATE',
  'INVENTORY_GROUP_LIST' = 'INVENTORY_GROUP_LIST',
  'INVENTORY_GROUP_REMOVE' = 'INVENTORY_GROUP_REMOVE',
  'INVENTORY_GROUP_EDIT' = 'INVENTORY_GROUP_EDIT',
  'INVENTORY_REQUEST_ADD' = 'INVENTORY_REQUEST_ADD',
  'INVENTORY_REQUEST_MANAGE' = 'INVENTORY_REQUEST_MANAGE',
  'INVENTORY_REQUEST_LIST_ALL' = 'INVENTORY_REQUEST_LIST_ALL',
  'COMPANY_EDIT' = 'COMPANY_EDIT',
  'COMPANY_DETAILS' = 'COMPANY_DETAILS',

  'PDF_LIST' = 'PDF_LIST',
  'PDF_LIST_ALL' = 'PDF_LIST_ALL',
  'PDF_CREATE' = 'PDF_CREATE',
  'PDF_EDIT' = 'PDF_EDIT',
  'PDF_DELETE' = 'PDF_DELETE',

  'PDF_TEMPLATE_LIST' = 'PDF_TEMPLATE_LIST',
  'PDF_TEMPLATE_CREATE' = 'PDF_TEMPLATE_CREATE',
  'PDF_TEMPLATE_EDIT' = 'PDF_TEMPLATE_EDIT',
  'PDF_TEMPLATE_DELETE' = 'PDF_TEMPLATE_DELETE',

  'FILES_LIST' = 'FILES_LIST',
  'FILES_CREATE' = 'FILES_CREATE',
  'FILES_EDIT' = 'FILES_EDIT',
  'FILES_DELETE' = 'FILES_DELETE',
}
