import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Alert, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { yupResolver } from '@hookform/resolvers/yup';
import ExerciceForm from '../Form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import validation from '../validation';
import map from 'lodash/map';
import {
  countInventoryItems,
  createInventoryItem,
} from 'shared/services/api/inventory';
import UserContext from 'shared/contexts/UserContext';
import useFetch from 'shared/hooks/useFetch';
import api from 'shared/services/api';
import { useTranslations } from 'shared/hooks/useTranslations';

export default function CreateInventoryGroup() {
  const translations = useTranslations();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { data: countInventoryItemsData } = useFetch<any>({
    initialValue: [],
    fetchAction: () => countInventoryItems(),
  });
  const { register, handleSubmit, errors, control, watch } = useForm({
    resolver: yupResolver(validation),
    defaultValues: {
      inventoryGroupId: null,
    },
  });

  const onSubmit = async (formValues: any) => {
    setLoading(true);
    try {
      const metaData = map(formValues.metaData, (o, index) => {
        return {
          id: o.uuid,
          key: o.key?.value || o.key,
          value: o.value,
        };
      });
      const { data } = await createInventoryItem({
        ...formValues,
        metaData,
        inventoryGroupId: formValues.inventoryGroupId?.value,
      });
      if (formValues.image) {
        const formData = new FormData();
        // @ts-ignore
        formData.append('file', formValues.image);
        // @ts-ignore
        await api.post(`/inventory/items/${data.item.id}/image`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
      }
      toast.success(translations.inventory.createSuccess);
      history.push(ROUTES.inventory.items.list.path);
    } catch (e) {
      toast.error('Something went wrong.');
    }
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metaData',
    keyName: 'keyName',
  });
  const reachedLimit = (limit?: number) => {
    if (!limit) return true;
    return countInventoryItemsData?.count >= limit;
  };
  return (
    <UserContext.Consumer>
      {({ selectedCompany }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.inventory.items.list.path }}
              linkAs={Link}
            >
              {translations.inventory.inventory}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.inventory.items.list.path }}
              linkAs={Link}
            >
              {translations.global.list}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {translations.inventory.createItem}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Card
                  title={translations.inventory.createItem}
                  toolbar={
                    <Button
                      color="primary"
                      isLoading={isLoading}
                      disabled={reachedLimit(
                        selectedCompany?.inventoryItemsLimit
                      )}
                    >
                      {translations.global.create}
                    </Button>
                  }
                >
                  {reachedLimit(selectedCompany?.inventoryItemsLimit) && (
                    <Alert variant="warning">
                      You reached the limit of your devices. Upgrade plan to add
                      more.
                    </Alert>
                  )}
                  <ExerciceForm
                    errors={errors}
                    register={register}
                    metaData={fields}
                    append={append}
                    remove={remove}
                    watch={watch}
                    control={control}
                  />
                </Card>
              </form>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
