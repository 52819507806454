import React, { forwardRef, useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import ContentLoader from 'shared/components/ContentLoader';

import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  CloseButton,
  FileInput,
  Group,
  Image,
  MultiSelect,
  NumberInput,
  Overlay,
  Radio,
  SegmentedControl,
  Select,
  SelectItemProps,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import RTEField from './RTEField';
import { useTranslations } from 'shared/hooks/useTranslations';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import get from 'lodash/get';
import * as arrayMove from 'array-move';
import { IconDragDrop, IconTrash } from '@tabler/icons';
import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { randomId } from '@mantine/hooks';

const DragHandle = SortableHandle(() => (
  <span>
    <IconDragDrop color="#d4d4d4" />
  </span>
));

export const DefaultItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, ...others }: SelectItemProps, ref) => (
    <div ref={ref} {...others} style={{ display: 'flex' }}>
      <Image
        // @ts-ignore
        src={others.imageUrl}
        withPlaceholder
        height={40}
        width={40}
      />
      {label || value}
    </div>
  )
);

const SortableItem = SortableElement(
  ({ item, form, translations, nameById, imageUrlById, itemIndex }) => {
    const index = itemIndex;
    // (item, index) => {
    return (
      <tr key={item.id}>
        <td>
          <DragHandle />
        </td>
        <td>
          <div>
            <Image
              src={imageUrlById[item.id]}
              withPlaceholder
              height={80}
              width={80}
            />
          </div>
        </td>
        <td>{nameById[item.id]}</td>
        <td>
          <SegmentedControl
            size={'xs'}
            {...form.getInputProps(`configuration.inventory.${index}.type`)}
            data={[
              {
                label: translations.pdf.asNeeded,
                value: 'AS_NEEDED',
              },
              {
                label: translations.global.amount,
                value: 'AMOUNT',
              },
            ]}
          />
        </td>
        <td>
          <NumberInput
            size="xs"
            defaultValue={1}
            withAsterisk
            style={{ width: 100 }}
            disabled={
              form.values?.configuration?.inventory[index]?.type !== 'AMOUNT'
            }
            {...form.getInputProps(`configuration.inventory.${index}.amount`)}
          />
        </td>
        <td>
          <CloseButton
            onClick={() => {
              form.removeListItem(`configuration.inventory`, index);
            }}
          ></CloseButton>
        </td>
      </tr>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, form, translations, nameById, imageUrlById }) => {
    return (
      <Table verticalSpacing="xs" horizontalSpacing={'xs'}>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              itemIndex={index}
              item={value}
              form={form}
              translations={translations}
              nameById={nameById}
              imageUrlById={imageUrlById}
            />
          );
        })}
      </Table>
    );
  }
);

interface Props {
  form: UseFormReturnType<any>;
  templates: any[];
  mode: 'CREATE' | 'EDIT';
  template?: any;
  inventoryItems?: any[];
  pdfData?: any;
}

export default function ExerciceForm(props: Props) {
  const { form, templates, mode, template, inventoryItems, pdfData } = props;
  const [searchValue, onSearchChange] = useState('');
  // const { form, templates, mode, template, inventoryItems, pdfData } = props;
  // const [searchValue, onSearchChange] = useState('');

  const handleSelectItem = (ids) => {
    if (form.values.configuration.inventory?.find((i) => i.id === ids[0])) {
      return;
    }
    form.insertListItem(
      'configuration.inventory',
      { id: ids[0], type: 'AS_NEEDED', amount: 1 },
    );
    onSearchChange('');
  };

  const items =
    inventoryItems?.map((item) => ({
      label: item.name,
      value: item.id,
      group: get(item, `groups.0.name`) || '-',
      disabled: form.values.configuration.inventory?.find(
        (i) => i.id === item.id
      ),
      imageUrl: item.imageUrl,
    })) || [];

  const nameById = {};
  const imageUrlById = {};
  items.forEach((i) => {
    nameById[i.value] = i.label;
    imageUrlById[i.value] = i.imageUrl;
  });
  const translations = useTranslations();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    form.setFieldValue(
      'configuration.inventory',
      arrayMove.arrayMoveImmutable(
        form.values.configuration?.inventory,
        oldIndex,
        newIndex
      )
    );
  };
  return (
    <ContentLoader isLoading={false}>
      <div className="row">
        <div className="col-md-3">
          <TextInput
            name="name"
            label={translations.global.name}
            required
            {...form.getInputProps(`name`)}
          />
        </div>
        <div className="col-md-3">
          <Select
            name={`templateId`}
            withAsterisk
            label={translations.pdf.template}
            data={templates.map((file) => ({
              label: file.name,
              value: file.id,
            }))}
            required
            disabled={mode === 'EDIT'}
            {...form.getInputProps(`templateId`)}
          />
        </div>
        <div className="col-md-3">
          <Radio.Group
            label="Data"
            description="Dodaj datę na pierwszej stronie pliku PDF"
            {...form.getInputProps(`configuration.addIntroDate`)}
          >
            <Group mt={8}>
              <Radio value="YES" label="Tak" defaultChecked />
              <Radio value="NO" label="Nie" />
            </Group>
          </Radio.Group>
        </div>
        <div className="col-md-3">
          <Checkbox
            mb={15}
            mt={10}
            label={'Przetłumacz na język angielski'}
            {...form.getInputProps(`configuration.enTranslation`, {
              type: 'checkbox',
            })}
          />
        </div>
        <div className="col-md-6">
          <FileInput
            multiple
            accept={[...IMAGE_MIME_TYPE].join(',')}
            label="Logo"
            description="Aby wykorzystać logo w ofercie, umieść znaczniki [LOGO] w edytorze tekstowym w wybranym miejscu"
            required
            {...form.getInputProps(`configuration.logoInput`)}
          />
        </div>
        <div className="col-md-6">
          {pdfData?.logoUrl && (
            <img
              src={pdfData.logoUrl}
              alt="logo"
              style={{ maxWidth: '200px', marginTop: 30 }}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {template?.configuration?.pages?.map((page) => {
            return (
              <Box key={page.id} mb={20} mt={15}>
                <Title order={5}>
                  <Group position="apart">
                    {translations.pdf.page}: {page.name}
                  </Group>
                </Title>
                <Checkbox
                  mb={15}
                  mt={10}
                  label={translations.pdf.renderPdfPage}
                  defaultChecked
                  {...form.getInputProps(
                    `configuration.pages.${page.id}.render`,
                    { type: 'checkbox' }
                  )}
                />
                <div style={{ position: 'relative' }}>
                  {get(form.values, `configuration.pages.${page.id}.render`) ===
                    false && <Overlay blur={2} color={'rgba(0,0,0,0.6)'} />}

                  {page.type === 'FILE' && (
                    <div>{translations.pdf.fileWillBeAdded}</div>
                  )}

                  {page.type === 'CUSTOM_FILE' && (
                    <div>
                      Wybierz własny plik PDF, który zostanie dołączony do
                      pliku. <br />
                      Aktualny plik:{' '}
                      <strong>
                        {get(
                          pdfData,
                          `pdf.configuration.pages.${page.id}.customFileName`
                        ) || 'brak'}
                      </strong>
                      <FileInput
                        multiple
                        accept={[...PDF_MIME_TYPE].join(',')}
                        label="Własny plik"
                        required
                        {...form.getInputProps(
                          `configuration.pages.${page.id}.customFileInput`
                        )}
                      />
                    </div>
                  )}
                  {page.type === 'INVENTORY' && (
                    <div style={{ width: '400px' }}>
                      <MultiSelect
                        data={items}
                        label={translations.pdf.findInventoryItem}
                        placeholder={translations.pdf.selectInventoryItem}
                        searchable
                        onSearchChange={onSearchChange}
                        searchValue={searchValue}
                        value={[]}
                        multiple
                        onChange={handleSelectItem}
                        itemComponent={DefaultItem}

                        // @ts-ignore
                        // itemComponentX={(item, a) => {
                        //   // console.log(item.imageUrl, item.label, item.value);
                        //   // console.log(item)

                        //   // form.setFieldValue('')
                        //   return (
                        //     <div
                        //       onClick={() => handleSelectItem(item.value)}
                        //       style={{
                        //         display: 'flex',
                        //         alignItems: 'center',
                        //         marginBottom: 10,
                        //       }}
                        //     >
                        //       <Image
                        //         src={imageUrlById[item.value]}
                        //         withPlaceholder
                        //         height={40}
                        //         width={40}
                        //       />
                        //       <div style={{ marginLeft: 15 }}>
                        //         {nameById[item.value]}
                        //       </div>
                        //     </div>
                        //   );
                        // }}
                      />

                      <SortableList
                        items={form.values.configuration?.inventory}
                        onSortEnd={onSortEnd}
                        form={form}
                        translations={translations}
                        nameById={nameById}
                        imageUrlById={imageUrlById}
                        useDragHandle={true}
                      />
                    </div>
                  )}
                  {page.type === 'SERVICE_VALUE' && (
                    <div>
                      {form.values.configuration.pages[
                        page.id
                      ]?.serviceValue?.map((item, index) => (
                        <Group key={item.key} mt="xs">
                          <TextInput
                            placeholder="np. 12 220.00 zł"
                            withAsterisk
                            sx={{ flex: 1 }}
                            {...form.getInputProps(
                              `configuration.pages.${page.id}.serviceValue.${index}.value`
                            )}
                          />
                          <TextInput
                            placeholder="np. miesięcznie netto stawka do końca 2023"
                            withAsterisk
                            sx={{ flex: 1 }}
                            {...form.getInputProps(
                              `configuration.pages.${page.id}.serviceValue.${index}.text`
                            )}
                          />

                          <ActionIcon
                            color="red"
                            onClick={() =>
                              form.removeListItem(
                                `configuration.pages.${page.id}.serviceValue`,
                                index
                              )
                            }
                          >
                            <IconTrash size="1rem" />
                          </ActionIcon>
                        </Group>
                      ))}
                      <Group position="left" mt="md">
                        <Button
                          mb={25}
                          size="xs"
                          onClick={() =>
                            form.insertListItem(
                              `configuration.pages.${page.id}.serviceValue`,
                              {
                                name: '',
                                active: false,
                                key: randomId(),
                              }
                            )
                          }
                        >
                          Dodaj wartość
                        </Button>
                      </Group>
                      <div>
                        <RTEField
                          defaultValue={
                            form.getInputProps(
                              `configuration.pages.${page.id}.wysywig`
                            ).value
                          }
                          onChange={
                            form.getInputProps(
                              `configuration.pages.${page.id}.wysywig`
                            ).onChange
                          }
                        />
                      </div>
                    </div>
                  )}
                  {page.type === 'WYSYWIG' && (
                    <div>
                      <RTEField
                        defaultValue={
                          form.getInputProps(
                            `configuration.pages.${page.id}.wysywig`
                          ).value
                        }
                        onChange={
                          form.getInputProps(
                            `configuration.pages.${page.id}.wysywig`
                          ).onChange
                        }
                      />
                    </div>
                  )}
                </div>
              </Box>
            );
          })}
        </div>
      </div>
    </ContentLoader>
  );
}
