import { Badge, Checkbox, Group, Radio } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslations } from 'shared/hooks/useTranslations';
import { CustomerUser } from 'shared/services/api/customers/types';
import { FacilityUser } from 'shared/services/api/facilities/types';
import { COMMON_USER_TYPE_BADGE_COLOR, FACILITY_USER_ROLE_BADGE_COLOR } from 'types/user';

interface Props {
  form: UseFormReturnType<any>;
  users: CustomerUser[] | FacilityUser[];
  customer?: boolean;
}

const FileAccessForm = (props: Props) => {
  const { form, users, customer } = props;
  const translations = useTranslations();
  return (
    <>
      <Radio.Group
        label="Dostępność"
        mt={20}
        description=""
        defaultValue="PUBLIC"
        {...form.getInputProps(`visibility`)}
      >
        <Group mt="xs">
          <Radio value="PUBLIC" label="Wszyscy w obiekcie" />
          <Radio value="PRIVATE" label="Wybrani uzytkownicy" />
        </Group>
      </Radio.Group>
      {form.values.visibility === 'PRIVATE' && (
        <>
          <Checkbox.Group
            mt={20}
            label="Uzytkownicy z dostepem"
            description=" Wybierz uzytkowników, którzy będą mieli dostęp do pliku"
            {...form.getInputProps('users')}
          >
            {users?.map((user) => (
              <Checkbox
                mt={15}
                value={user.userId}
                key={user.userId}
                label={
                  <span>
                    {user.user.firstName} {user.user.lastName}
                    {customer && (
                      <Badge color={COMMON_USER_TYPE_BADGE_COLOR[user.type]}>
                        {translations.enums.commonUserType[user.type]}
                      </Badge>
                    )}
                    {!customer && (
                      <Badge color={FACILITY_USER_ROLE_BADGE_COLOR[user.role]}>
                        {translations.enums.facilityUserRole[user.role]}
                      </Badge>
                    )}
                  </span>
                }
                mb={10}
              />
            ))}
          </Checkbox.Group>
        </>
      )}
    </>
  );
};

export default FileAccessForm;
