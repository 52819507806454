import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCustomerFacilityRequests } from 'shared/services/api/facilities';
import { useState } from 'react';
import { Badge, Button } from '@mantine/core';
import {
  FACILITY_REQUEST_STATUS_COLOR,
  FacilityRequest,
} from 'shared/services/api/facilities/types';
import CreateFacilityRequestModal from './CreateFacilityRequestModal';
import { useTranslations } from 'shared/hooks/useTranslations';

const FacilitiesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityRequest[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityRequests(customerId, facilityId),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const translations = useTranslations();

  return (
    <div className="">
      <CreateFacilityRequestModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Zgłoszenia'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj zgłoszenie
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Strefa</th>
                  <th>Status</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((request) => (
                  <tr key={request.id}>
                    <td>
                      <Link
                        to={ROUTES.customers.facilities.requests.details.to(
                          customerId,
                          facilityId,
                          request.id
                        )}
                      >
                        {request.name}
                      </Link>
                    </td>
                    <td>{request.description}</td>
                    <td>{request.facilityArea?.name || '-'}</td>
                    <td>
                      <Badge
                        color={FACILITY_REQUEST_STATUS_COLOR[request.status]}
                      >
                        {
                          translations.enums.facilityRequestStatus[
                            request.status
                          ]
                        }
                      </Badge>
                    </td>
                    <td>{formatDate(request.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesList;
