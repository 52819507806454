import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import Button from 'shared/components/Button';
import api from 'shared/services/api';
import avatarBoyPlaceholder from 'assets/placeholders/avatar_boy.svg';
import { getCroppedBase64Img, getCroppedImg } from './helpers';
import { Modal } from 'react-bootstrap';
interface File {
  data?: any;
  url?: string;
}
const EditAvatar = (props: any) => {
  const { user } = props;
  const [tempImage, setTempImage] = useState('');
  const imageInputRef = React.useRef<HTMLInputElement>(null); //See Supporting Documentation #2

  const [crop, setCrop] = useState({
    aspect: 4 / 4,
    unit: 'px', // default, can be 'px' or '%'
    x: 130,
    y: 50,
    width: 200,
    height: 200,
  });
  const [file, setFile] = useState<File>({});
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const handleClose = () => {
    setShow(false);
    // @ts-ignore
    imageInputRef.current.value = '';
  };
  const handleShow = () => setShow(true);

  const onChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFile({
        // @ts-ignore
        url: URL.createObjectURL(file),
        data: file,
      });
      handleShow();
    }
  };

  const save = async () => {
    setSaving(true);
    const img = await getCroppedImg(image, crop, 'me.jpg');
    setTempImage(getCroppedBase64Img(image, crop, 'me.jpg'));
    const formData = new FormData();
    // @ts-ignore
    formData.append('file', img);
    const { data } = await api.post(`/auth/avatar`, formData, {
      // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    setTempImage(data.avatarUrl);
    setSaving(false);
    setFile({});
    handleClose();
  };
  const handleSave = () => {
    save();
  };
  // @ts-ignore
  const onCrop = (newCrop: any) => {
    setCrop(newCrop);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dostosuj avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Avatar zostanie zapisany po kliknięciu przycisku "Zapisz"</p>
          {file?.url && (
            <ReactCrop
              // @ts-ignore
              src={file?.url}
              // @ts-ignore
              crop={crop}
              // @ts-ignore
              onImageLoaded={(i) => setImage(i)}
              // @ts-ignore
              onChange={onCrop}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose}>
            Anuluj
          </Button>
          <Button color="primary" onClick={handleSave} isLoading={isSaving}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="image-input image-input-outline">
        <div className="image-input-wrapper">
          <img
            src={tempImage || user?.avatar || avatarBoyPlaceholder}
            alt=""
            style={{ maxWidth: '100%' }}
          />
        </div>
        <label
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="change"
          data-toggle="tooltip"
          title=""
          data-original-title="Change avatar"
        >
          <i className="fa fa-pen icon-sm text-muted"></i>
          <input
            type="file"
            name="profile_avatar"
            accept=".png, .jpg, .jpeg"
            onChange={onChange}
            ref={imageInputRef}
          />
        </label>
        <span
          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
          data-action="cancel"
          data-toggle="tooltip"
          title=""
          data-original-title="Cancel avatar"
        >
          <i className="ki ki-bold-close icon-xs text-muted"></i>
        </span>
      </div>
      <span className="form-text text-muted">Extensions: png, jpg, jpeg.</span>
    </div>
  );
};

export default EditAvatar;
