export enum FacilityAuditStatus {
  'OPEN' = 'OPEN',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CLOSED' = 'CLOSED',
}

export interface FacilityAuditComment {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export enum FacilityAuditVisibility {
  PUBLIC = 'PUBLIC',
  COMPANY_INTERNAL = 'COMPANY_INTERNAL',
  CUSTOMER_INTERNAL = 'CUSTOMER_INTERNAL',
}

export const FACILITY_AUDIT_VISIBILITY_COLOR = {
  [FacilityAuditVisibility.CUSTOMER_INTERNAL]: 'yellow',
  [FacilityAuditVisibility.COMPANY_INTERNAL]: 'green',
  [FacilityAuditVisibility.PUBLIC]: 'blue',
};

export interface FacilityAudit {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  parentAreaId?: string;
  customerId: string;
  companyId: string;
  facilityId: string;
  status: FacilityAuditStatus;
  visibility: FacilityAuditVisibility;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  auditScore: {
    maxScore: number;
    percentage: string;
    ratingScore: number;
    totalScore: number;
  };
  comments?: FacilityAuditComment[];
  auditAreas: {
    id: string;
    facilityArea: {
      id: string;
      name: string;
    };
    tasks: {
      companyAuditTask: {
        name: string;
        order: number;
        id: string;
      };
      id: string;
      score: number;
      description: string;
    }[];
  }[];
}
export interface FacilityAuditBody {}

export interface AuditPrepare {
  audit: {
    id: string;
    name: string;
    companyAreaType?: {
      id: string;
      name: string;
      auditTasks?: {
        id: string;
        name: string;
      }[];
    };
  }[];
}
