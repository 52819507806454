import React, { useState } from 'react';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { Button } from '@mantine/core';
import api from 'shared/services/api';
import { toast } from 'react-toastify';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Props {
  onChange: any;
  defaultValue: any;
}

export default function RTEField(props: Props) {
  const { onChange, defaultValue } = props;
  const translations = useTranslations();
  const [isTranslating, setIsTranslating] = useState(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Color,
      TextStyle,
      // Superscript,
      // SubScript,
      // Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    content: defaultValue,
  });
  const handleChange = (value) => {};
  const handleTranslate = async () => {
    try {
      setIsTranslating(true);
      const { data } = await api.post('pdf/translate', {
        text: editor?.getHTML(),
        language: 'en',
      });
      onChange(data.text);
      editor?.commands.setContent(data.text);
      setIsTranslating(false);
    } catch (e) {
      setIsTranslating(false);
      toast.error(translations.global.somethingWentWrong);
    }
  };

  return (
    <div>
      <Button onClick={handleTranslate} loading={isTranslating} size="xs">
        Przetłumacz na angielski
      </Button>
      <RichTextEditor
        editor={editor}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
            <RichTextEditor.H5 />
            <RichTextEditor.H6 />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <input
              type="color"
              onInput={(event) =>
                // @ts-ignore
                editor?.chain().focus().setColor(event.target.value).run()
              }
              value={editor?.getAttributes('textStyle').color}
            />
            <RichTextEditor.Color color="#05a6d8" />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
    </div>
  );
}
