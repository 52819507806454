import api from 'shared/services/api';
import {
  CustomerPostBody,
  Customer,
  CustomerPatchBody,
  CustomerUser,
  CustomerUserBody,
} from './types';

export const getCustomer = (id: string) =>
  api.get<{ customer: Customer }>(`/customers/${id}`);

export const getCustomers = () => api.get<{ rows: Customer[] }>('/customers');

export const patchCustomer = (
  companyCustomerId: string,
  body: CustomerPatchBody
) => api.patch(`/customers/${companyCustomerId}`, body);

export const postCustomer = (body: CustomerPostBody) =>
  api.post('/customers', body);

export const deleteCustomer = (companyCustomerId: string) =>
  api.delete(`/customers/${companyCustomerId}`);

// Users

export const getCustomerUser = (customerId: string, userId: string) =>
  api.get<{ customer: CustomerUser }>(
    `/customers/${customerId}/users/${userId}`
  );

export const getCustomerUsers = (customerId: string) =>
  api.get<{ rows: CustomerUser[] }>(`/customers/${customerId}/users`);

export const patchCustomerUser = (
  customerId: string,
  userId: string,
  body: CustomerUserBody
) => api.patch(`/customers/${customerId}/users/${userId}`, body);

export const postCustomerUser = (customerId: string, body: CustomerUserBody) =>
  api.post(`/customers/${customerId}/users`, body);

export const deleteCustomerUser = (customerId: string, userId: string) =>
  api.delete(`/customers/${customerId}/users/${userId}`);
