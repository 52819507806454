import React, { useEffect, useRef, useState } from 'react';
import Login from 'modules/Auth/Login';
import Users from 'modules/Users';
import { Redirect, Route, Switch } from 'react-router-dom';
import { auth, hasUserRole } from 'shared/services/firebase';
import api, { apiService } from 'shared/services/api';
import Navigation from 'shared/components/Navigation';
import ROUTES from 'shared/constants/routes';
import InventoryGroups from 'modules/InventoryGroups';
import InventoryItems from 'modules/InventoryItems';
import InventoryRequests from 'modules/InventoryRequests';
import Company from 'modules/Company';
// import Dashboard from 'modules/Dashboard';
import Account from 'modules/Account';
import CompleteRegistration from 'modules/Auth/CompleteRegistration';
import UserContext from 'shared/contexts/UserContext';
import Button from 'shared/components/Button';
import GlobalError from 'shared/components/GlobalError';
import Admin from 'modules/Admin';
import get from 'lodash/get';
import { USER_PERMISSION } from 'shared/constants/user';
import Pdf from './modules/Pdf';
import Files from './modules/Files';
import { getAppConfiguration } from './config';
import CustomersRoutes from './modules/Customers';

function App() {
  useEffect(() => {
    document.title = getAppConfiguration().mainTitle;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      // @ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    //   <link
    //   rel="icon"
    //   href="https://procleaner.pl/wp-content/uploads/2021/10/cropped-procleaner-150x150.png"
    //   sizes="32x32"
    // />
    // <link
    //   rel="icon"
    //   href="https://procleaner.pl/wp-content/uploads/2021/10/cropped-procleaner-300x300.png"
    //   sizes="192x192"
    // />
    if (getAppConfiguration().favicon) {
      // @ts-ignore
      link.href = getAppConfiguration().favicon;
    }
  }, []);
  const [isLogged, setLogged] = useState(false);
  const [userRoles, setUserRoles] = useState<USER_PERMISSION[]>([]);
  const refFirebaseUser = useRef<any>(null);
  const [user, setUser] = useState<any>({});
  const [firebaseUser, setFirebaseUser] = useState<any>({});

  const [isSigned, setSigned] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any | undefined>(
    undefined
  );
  const [signError, setSignError] = useState<any>();
  const [isFirebaseLoading, setFirebaseLoading] = useState(true);
  const [isUserLoading, setUserLoading] = useState(true);
  const [isRegistrationCompleted, setRegistrationCompleted] = useState(true);
  const [isFinishingRegistration, setFinishingRegistration] = useState(false);
  const logout = () => {
    setSignError(undefined);
    auth.signOut();
  };
  // Watch firebase status
  auth.onAuthStateChanged((user) => {
    const isAuthorizedInFirebase = !!user?.email;
    if (!isAuthorizedInFirebase) {
      setUserLoading(false);
    }
    setFirebaseLoading(false);
    setLogged(!!user?.email);
    setFirebaseUser(user);
    refFirebaseUser.current = user;
  });
  // Login to our database when Firebase is logged
  useEffect(() => {
    if (!isLogged) {
      return;
    }

    (async function signIn() {
      try {
        setUserLoading(true);
        const { data } = await api.post('/auth/sign');
        const {
          data: { user },
        } = await api.get('/auth/profile');
        refFirebaseUser?.current?.getIdTokenResult().then((a) => {
          if (!a.claims.userUuid) {
            refFirebaseUser?.current?.getIdTokenResult(true);
          }
        });
        if (user.type === 'CUSTOMER') {
          setSignError({ type: 'not-companies' });
          setUserLoading(false);
          return;
        }
        setRegistrationCompleted(data.user.finishedRegistration);
        const company = get(user, 'companies.0');
        if (company) {
          setSelectedCompany(company);
          apiService.setCompany(company.id);
          await new Promise((resolve) => setTimeout(resolve, 1000)); // TODO: I need it?
        }
        const isSuperAdmin = await hasUserRole('super_admin');
        if (company) {
          const {
            data: { roles },
          } = await api.get('/auth/roles');
          setUserRoles(roles);
        }
        if (!company && !isSuperAdmin) {
          setSignError({ type: 'not-companies' });
          setUserLoading(false);
          return;
        }
        // TODO throw error when no company and not super admin?
        setUser(user);
        setSigned(true);
        setUserLoading(false);
      } catch (e) {
        // @ts-ignore
        if (e?.response?.data) {
          // @ts-ignore
          setSignError(e?.response?.data);
        }
        setSigned(false); // todo remove
        setUserLoading(false);
      }
    })();
  }, [isLogged]);

  const onFinishRegistration = async (body: any) => {
    try {
      setFinishingRegistration(true);
      const { data } = await api.post('/auth/complete', body);
      setRegistrationCompleted(data.user.finishedRegistration);
      const {
        data: { user },
      } = await api.get('/auth/profile');
      setUser(user);
      setSigned(true);
      setFinishingRegistration(false);
    } catch (e) {
      setFinishingRegistration(false);
    }
  };

  if (isFirebaseLoading || isUserLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100%' }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Ładowanie</span>
        </div>
      </div>
    );
  }
  if (!isLogged) {
    return <Login />;
  }
  // !isS

  if (signError) {
    return (
      <div className=" h-100">
        <div className="d-flex h-100 justify-content-center align-items-center">
          {signError?.type === 'not-companies' && (
            <div className="">
              <div className="card card-custom gutter-b bg-light-danger">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap">
                    <div className="d-flex flex-column mr-5">
                      <span className="h4 text-dark text-hover-primary mb-5">
                        Wystąpił błąd
                      </span>
                      <p className="text-dark-50">
                        Nie masz dostępu do tej aplikacji.
                        <br />
                        Jeżeli uważasz, że to błąd, skontaktuj się z nami.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Button onClick={logout} color="secondary">
                Wyloguj
              </Button>
            </div>
          )}
          {signError?.type === 'not-invited' && (
            <div className="">
              <div className="card card-custom gutter-b bg-light-danger">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between p-4 flex-lg-wrap flex-xl-nowrap">
                    <div className="d-flex flex-column mr-5">
                      <span className="h4 text-dark text-hover-primary mb-5">
                        Wystąpił błąd
                      </span>
                      <p className="text-dark-50">
                        Nie masz dostępu do tej aplikacji.
                        <br />
                        Jeżeli uważasz, że to błąd, skontaktuj się z nami.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Button onClick={logout} color="secondary">
                Wyloguj
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (!isSigned) {
    return <GlobalError />;
  }
  if (!isRegistrationCompleted) {
    return (
      <CompleteRegistration
        onFinish={onFinishRegistration}
        defaultValues={user}
        isLoading={isFinishingRegistration}
      />
    );
  }

  const onRefresh = async () => {
    const {
      data: { user },
    } = await api.get('/auth/profile');
    setUser(user);
  };
  return (
    <UserContext.Provider
      value={{
        data: user,
        firebaseUser,
        refresh: onRefresh,
        selectedCompany,
        roles: userRoles,
      }}
    >
      <div>
        <Navigation />
        <div className="content d-flex flex-column flex-column-fluid">
          <Switch>
            {/* <Route path="/" exact component={Dashboard} /> */}
            <Route
              path="/"
              exact
              component={() => <Redirect to={ROUTES.pdf.default.path} />}
            />
            <Route path={ROUTES.users.default.path} component={Users} />
            <Route path={ROUTES.admin.default.path} component={Admin} />
            <Route path={ROUTES.account.default.path} component={Account} />
            <Route path={ROUTES.pdf.default.path} component={Pdf} />
            <Route path={ROUTES.files.default.path} component={Files} />
            <Route
              exact
              path={ROUTES.inventory.default.path}
              component={InventoryItems}
            />
            <Route
              path={ROUTES.inventory.groups.path}
              component={InventoryGroups}
            />

            <Route
              path={ROUTES.inventory.requests.path}
              component={InventoryRequests}
            />
            <Route
              path={ROUTES.inventory.items.path}
              component={InventoryItems}
            />
            <Route path={ROUTES.company.default.path} component={Company} />
            <Route
              path={ROUTES.customers.default.path}
              component={CustomersRoutes}
            />
          </Switch>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default App;
