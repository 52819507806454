import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ROUTES from "shared/constants/routes";
import WithRole from "shared/components/WithRole";
import { USER_PERMISSION } from "shared/constants/user";
import CreatePdf from "./Create";
import PdfList from "./List";
import EditPdfTemplate from "./Edit";

function PdfTemplates() {
  return (
    <Switch>
      <Route path={ROUTES.pdf.templates.default.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <Redirect to={ROUTES.pdf.templates.list.path} />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.templates.list.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <PdfList />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.templates.create.path} exact>
        <CreatePdf />
      </Route>
      <Route path={ROUTES.pdf.templates.edit.path} exact>
        <EditPdfTemplate />
      </Route>
    </Switch>
  );
}

export default PdfTemplates;
