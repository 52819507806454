import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
// import List from './List';
// import InvitationsList from './InvitationsList';
// import Details from './Details';
// import Edit from './Edit';
import FacilityUsersList from './List';
// import CustomerDetails from './Details';

function CustomerFacilityUsersRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.facilities.users.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <FacilityUsersList />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomerFacilityUsersRoutes;
