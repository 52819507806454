import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import ROUTES from 'shared/constants/routes';
import { takeInventoryItem } from 'shared/services/api/inventory';

interface Props {
  show: boolean;
  onClose: Function;
  exercice: any;
}

const ConfirmTakeModal = (props: Props) => {
  const { show, onClose, exercice } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const onSave = async () => {
    try {
      setLoading(true);
      await takeInventoryItem(exercice.id);
      onClose(true);
      toast.success('Item has been taken.');
      history.push(ROUTES.inventory.items.list.path);
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Take over inventory item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to take over the item from{' '}
        <strong>
          {exercice?.owner?.firstName} {exercice.owner?.lastName}
        </strong>
        ?
      </Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="info" onClick={onSave} isLoading={isLoading}>
          Take over
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmTakeModal;
