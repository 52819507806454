import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import ManageTrainers from './ManageTrainers';
import Companies from './Companies';

function Admin() {
  return (
    <Switch>
      <Route
        path={[ROUTES.admin.default.path, ROUTES.admin.invites.path]}
        exact
      >
        <ManageTrainers />
      </Route>
      <Route path={[ROUTES.admin.companies.path]}>
        <Companies />
      </Route>
    </Switch>
  );
}

export default Admin;
