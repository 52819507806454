import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';
import {
  postCompany,
} from 'shared/services/api/admin';
interface Props {
  show: boolean;
  onClose: Function;
  company?: any;
}

const InviteCompanyModal = (props: Props) => {
  const { show, onClose, company } = props;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({});
  useEffect(() => {
    setValue('name', company?.name);
  }, [company, setValue]);

  const handleClose = () => onClose();

  const onSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      if (company?.name) {
        // await patchTrainerInvitation(company.email, formValues);
        toast.success('Zaproszenie zostało zmodyfikowane');
      } else {
        await postCompany(formValues.name);
        toast.success('Firma została dodana');
      }
      onClose(true);
    } catch (e) {
      toast.error('Coś poszło nie tak. Spróbuj ponownie później.');
    } finally {
      setLoading(false);
    }
  };

  return (
    // @ts-ignore
    <Modal show={show} onHide={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {company ? 'Edytuj' : 'Dodaj'} firmę 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            name="name"
            ref={register}
            label="Nazwa"
            error={errors.name}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Anuluj
          </Button>
          <Button color="primary" isLoading={isLoading}>
            {company ? 'Zapisz' : 'Dodaj'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default InviteCompanyModal;
