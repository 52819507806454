import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExerciceList from 'modules/InventoryGroups/List';
import CreateExercice from 'modules/InventoryGroups/Create';
import ROUTES from 'shared/constants/routes';
import EditExercice from './Edit';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
function InventoryGroupsRoutes() {
  return (
    <Switch>
      <Route path={ROUTES.inventory.groups.list.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_GROUP_LIST]}>
          <ExerciceList />
        </WithRole>
      </Route>
      <Route path={ROUTES.inventory.groups.create.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_GROUP_CREATE]}>
          <CreateExercice />
        </WithRole>
      </Route>

      <Route path={ROUTES.inventory.groups.edit.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_GROUP_EDIT]}>
          <EditExercice />
        </WithRole>
      </Route>
    </Switch>
  );
}

export default InventoryGroupsRoutes;
