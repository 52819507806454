import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteCompanyService } from 'shared/services/api/company/services';

import { CompanyService } from '../../../../../shared/services/api/company/services/types';

interface Props {
  service: CompanyService;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
}
const DeleteCompanyServiceModal = (props: Props) => {
  const { open, onClose, onSuccess, service } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await deleteCompanyService(service.id);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń usługę">
        Czy na pewno chcesz usunąć<strong>{service?.name}</strong>
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button
            type="submit"
            loading={loading}
            onClick={onClose}
            variant="outline"
          >
            Anuluj
          </Button>
          <Button
            type="submit"
            loading={loading}
            onClick={handleSubmit}
            color="red"
          >
            Usuń
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteCompanyServiceModal;
