import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Details from './Details';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import CustomerCompanyServicesRoutes from 'modules/Company/Services';
import CompanyAuditTasks from 'modules/Company/AuditTasks';
import CompanyAreaTypes from 'modules/Company/AreaTypes';
import CompanyProducts from 'modules/Company/CompanyProducts';

function CompanyRoutes() {
  return (
    <Switch>
      {/* <Route path={ROUTES.company.default.path} exact>
        <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_LIST]}>
          <Redirect to={ROUTES.inventory.items.list.path} />
        </WithRole>
      </Route> */}
      <Route path={ROUTES.company.default.path} exact>
        <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}>
          <Details />
        </WithRole>
      </Route>
      <Route path={ROUTES.company.services.path} exact>
        {/* <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}> */}
        <CustomerCompanyServicesRoutes />
        {/* </WithRole> */}
      </Route>
      <Route path={ROUTES.company.auditTasks.path} exact>
        {/* <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}> */}
        <CompanyAuditTasks />
        {/* </WithRole> */}
      </Route>
      <Route path={ROUTES.company.companyProducts.path} exact>
        {/* <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}> */}
        <CompanyProducts />
        {/* </WithRole> */}
      </Route>
      <Route path={ROUTES.company.areaTypes.path} exact>
        {/* <WithRole roles={[USER_PERMISSION.COMPANY_DETAILS]}> */}
        <CompanyAreaTypes />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CompanyRoutes;
