import React from 'react';
import { FieldError } from 'react-hook-form';
interface Option {
  label: string;
  value: string;
}
interface Props {
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: any;
  options: Option[];
  error?: FieldError;
  inline?: boolean;
}
const RadioField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { label, required, name, options, error, inline } = props;

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className={inline ? 'radio-inline' : 'radio-list'}>
        {options.map((option) => (
          <label className="radio" key={option.value}>
            <input
              type="radio"
              value={option.value}
              name={name}
              ref={ref}
              required={required}
            />
            <span></span>
            {option.label}
          </label>
        ))}
      </div>
      {error && <small className="text-danger">{error.message}</small>}
    </div>
  );
});

export default RadioField;
