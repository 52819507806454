import { AreaType } from 'shared/services/api/company/areaTypes/types';
import { CommonUserType, FacilityUserRole, User } from '../../../../types/user';

export interface FacilityPostBody {
  name: string;
  address?: string;
}
export interface FacilityAreaBody {
  name: string;
  parentAreaId?: string;
  companyAreaTypeId?: string;
  order?: number;
}
export interface FacilityPatchBody {}
export interface Facility {
  id: string;
  name: string;
  address: string;
  createdAt: string;
  customerId: string;
}

export enum FacilityRequestStatus {
  'OPEN' = 'OPEN',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CLOSED' = 'CLOSED',
}

export const FACILITY_REQUEST_STATUS_COLOR = {
  [FacilityRequestStatus.OPEN]: 'yellow',
  [FacilityRequestStatus.IN_PROGRESS]: 'violet',
  [FacilityRequestStatus.CLOSED]: 'green',
};

export interface FacilityRequestComment {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface FacilityRequest {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  facilityAreaId?: string;
  facilityArea?: FacilityArea;
  parentAreaId?: string;
  customerId: string;
  companyId: string;
  facilityId: string;
  status: FacilityRequestStatus;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };

  comments?: FacilityRequestComment[];
}
export interface FacilityRequestBody {}
export interface FacilityArea {
  id: string;
  name: string;
  createdAt: string;
  parentArea?: FacilityArea;
  subAreas?: FacilityArea[];
  parentAreaId?: string;
  customerId: string;
  companyId: string;
  facilityId: string;
  companyAreaTypeId?: string;
  companyAreaType?: AreaType;
  order: number;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface FacilityUser {
  id: string;
  role: FacilityUserRole;
  type: CommonUserType;
  user: User;
  createdAt: string;
  userId: string;
}

export interface FacilityUserBody {
  userId?: string;
  type: string;
  // id: string;
  // name: string;
}

export interface FacilityUserPatchBody {
  role: string;
  // userId?: string;
  // type: string;
  // id: string;
  // name: string;
}
