import {
  Alert,
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import useFetch from 'shared/hooks/useFetch';
import { patchAreaType } from 'shared/services/api/company/areaTypes';
import { AreaType } from 'shared/services/api/company/areaTypes/types';
import { getAuditTasks } from 'shared/services/api/company/auditTasks';
import { AuditTask } from 'shared/services/api/company/auditTasks/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  areaType: AreaType;
}
const EditAreaTypeModal = (props: Props) => {
  const { open, onClose, onSuccess, areaType } = props;
  const {
    data,
  } = useFetch<{
    rows: AuditTask[];
  }>({
    initialValue: {},
    fetchAction: () => getAuditTasks(),
  });
  const form = useForm({
    initialValues: {
      name: areaType?.name,
      auditTasks: areaType?.auditTasks?.map((task) => task.id) || [],
      order: areaType?.order,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchAreaType(areaType.id, {
        name: values.name,
        auditTasks: values.auditTasks,
        order: values.order,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (areaType && open) {
      form.setValues({
        name: areaType?.name,
        order: areaType?.order,
        auditTasks: areaType?.auditTasks?.map((task) => task.id) || [],
      });
    }
    // eslint-disable-next-line
  }, [areaType, open]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj typ strefy">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <NumberInput
            withAsterisk
            label="Kolejnosc"
            min={0}
            placeholder=""
            {...form.getInputProps('order')}
          />
          <Checkbox.Group
            value={form.values.auditTasks}
            // @ts-ignore
            onChange={(value) => form.setFieldValue('auditTasks', value)}
          >
            {data?.rows?.map((auditTask) => (
              <Checkbox
                key={auditTask.id}
                label={auditTask.name}
                mt="sm"
                value={auditTask.id}
              />
            ))}
          </Checkbox.Group>
          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditAreaTypeModal;
