import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteCustomerFacility } from 'shared/services/api/facilities';
import { Facility } from 'shared/services/api/facilities/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  facility: Facility;
  customerId: string;
}
const RemoveFacilityModal = (props: Props) => {
  const { open, onClose, onSuccess, facility, customerId } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomerFacility(customerId, facility.id);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń obiekt">
        Czy na pewno chcesz usunąć obiekt?
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Usuń
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default RemoveFacilityModal;
