import { CommonUserType } from 'types/user';
import { z } from 'zod';

export const createUserUserBaseSchema = z.object({
  email: z.string().email('Błędny email'),
});

export const createUserUserExtendedSchema =
  createUserUserBaseSchema.extend({
    firstName: z.string().min(1, 'Imię jest wymagane'),
    lastName: z.string().min(1, 'Nazwisko jest wymagane'),
    type: z.nativeEnum(CommonUserType),
  });
