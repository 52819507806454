import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';
import InvitationsList from './InvitationsList';
import Details from './Details';
import Edit from './Edit';
import { USER_PERMISSION } from 'shared/constants/user';
import WithRole from 'shared/components/WithRole';

function Users() {
  return (
    <Switch>
      <Route path={[ROUTES.users.invitations.path]} exact>
        <WithRole roles={[USER_PERMISSION.USERS_INVITATIONS_LIST]}>
          <InvitationsList />
        </WithRole>
      </Route>
      <Route path={[ROUTES.users.default.path]} exact>
        <WithRole roles={[USER_PERMISSION.USERS_LIST]}>
          <Redirect to={ROUTES.users.list.path} />
        </WithRole>
      </Route>
      <Route path={[ROUTES.users.list.path]} exact>
        <WithRole roles={[USER_PERMISSION.USERS_LIST]}>
          <List />
        </WithRole>
      </Route>
      <Route path={[ROUTES.users.details.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_EDIT]}> */}
          <Details />
        {/* </WithRole> */}
      </Route>
      <Route path={[ROUTES.users.edit.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_EDIT]}> */}
          <Edit />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default Users;
