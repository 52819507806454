import React, { useCallback, useMemo } from 'react';
import { FieldErrors, Controller } from 'react-hook-form';
import TextField from 'shared/components/TextField';
import ReactSelectField from 'shared/components/ReactSelectField';
import useFetch from 'shared/hooks/useFetch';
import {
  getInventoryGroups,
  getInventoryItemsMetaDataOptions,
  InventoryGroup,
} from 'shared/services/api/inventory';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
// import { USER_PERMISSION } from 'shared/constants/user';
// import WithRole from 'shared/components/WithRole';
import TextareaField from 'shared/components/TextareaField';
import { FileInput, Image } from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Props {
  errors: FieldErrors<any>; // TODO
  register: any; // TODO
  control: any;
  [key: string]: any;
  item?: {
    imageUrl?: string;
    inventoryGroupId: string;
  };
  setValue?: any;
  getValues?: any;
}
export default function ExerciceForm(props: Props) {
  const { errors, register, control, remove, item } = props;
  const {
    data,
    isLoading: isLoadingGroups,
    isLoaded: isLoadedGroups,
  } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getInventoryGroups(),
  });
  const {
    data: metaDataOptions,
    isLoaded: isLoadedOptions,
    isLoading: isLoadingOptions,
  } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getInventoryItemsMetaDataOptions(),
  });
  const options =
    data?.groups?.map((group: InventoryGroup) => ({
      label: group.name,
      value: String(group.id),
    })) || [];

  const keyOptions = useMemo(
    () =>
      uniqBy(
        [
          ...(metaDataOptions?.metaData?.map((option) => ({
            value: option,
            label: option,
          })) || []),
        ],
        'value'
      ),
    [metaDataOptions]
  );

  const getKeyOptions = useCallback(
    // @ts-ignore
    (key) => {
      const usedKeys = props.metaData.map((k) => k.key?.value || k.key) || [];
      return keyOptions.filter((v) => !usedKeys.includes(v.value)) || [];
    },
    [props.metaData, keyOptions]
  );
  const translations = useTranslations();

  return (
    <ContentLoader isLoading={isLoadingGroups || isLoadingOptions}>
      <div className="row">
        <div className="col-md-3">
          <TextField
            name="name"
            ref={register}
            label={translations.global.name}
            tip="e.g. iPhone 5"
            error={errors.name}
          />
        </div>
        <div className="col-md-3">
          <TextareaField
            name="description"
            ref={register}
            label={translations.global.description}
            tip="e.g. Best item in the world"
            error={errors.name}
          />
        </div>
        <div className="col-md-3">
          <TextField
            name="order"
            ref={register}
            label={translations.global.order}
            tip="e.g. 1"
            error={errors.name}
          />
        </div>
        {/* <WithRole roles={[USER_PERMISSION.INVENTORY_ITEM_EDIT_UNIQUE_ID]}>
          <div className="col-md-3">
            <TextField
              name="uniqueId"
              ref={register}
              label={translations.inventory.uniqueCode}
              tip="e.g. 1"
              error={errors.name}
            />
          </div>
        </WithRole> */}
        <div className="col-md-3">
          {isLoadedGroups && (
            <ReactSelectField
              name="inventoryGroupId"
              control={control}
              label={translations.inventory.group}
              placeholder={translations.inventory.selectGroup}
              defaultValue={
                // item.inventoryGroupId
                item?.inventoryGroupId
                  ? options?.find((o) => o.value === item?.inventoryGroupId)
                  : item?.inventoryGroupId
              }
              options={options}
              ref={register()}
            />
          )}
        </div>
        <div className="col-md-3 mb-20">
          <Image src={item?.imageUrl} style={{ maxWidth: '200px' }} />
          <Controller
            control={control}
            name="image"
            render={(field) => {
              return (
                <FileInput
                  accept={IMAGE_MIME_TYPE.join(',')}
                  label={translations.global.image}
                  placeholder={translations.inventory.selectImage}
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
      <h4> {translations.global.metadata}</h4>
      <div className="row">
        <div className="col-md-12">
          {isLoadedOptions &&
            props.metaData?.map((d, i) => (
              <div className="row align-items-end" key={d.id}>
                <div className="col-md-3">
                  <ReactSelectField
                    name={`metaData[${i}].key`}
                    control={control}
                    label={translations.global.key}
                    creatable
                    defaultValue={
                      d.key
                        ? keyOptions?.find((o) => o.value === d?.key)
                        : d.key
                    }
                    // @ts-ignore
                    options={getKeyOptions(d.key)}
                    tip="BRAND_NAME"
                    ref={register()}
                    error={
                      get(errors, `metaData[${i}].key.value`) ||
                      get(errors, `metaData[${i}].key`)
                    }
                  />
                </div>
                <div className="col-md-7">
                  <TextareaField
                    defaultValue={d.value}
                    name={`metaData[${i}].value`}
                    // {...register(`metaData[${i}].value`)}
                    ref={register()}
                    label={translations.global.value}
                    tip="e.g. APPLE"
                    error={get(errors, `metaData[${i}].value`)}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    color="secondary"
                    className="mb-13"
                    type="button"
                    onClick={() => remove(i)}
                  >
                    X
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Button
        onClick={() => props.append({ key: undefined, value: undefined })}
        color="light"
        type="button"
      >
        {translations.global.add}
      </Button>
    </ContentLoader>
  );
}
