import {
  Alert,
  Button,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import React, { useEffect, useMemo, useState } from 'react';
import { getCustomers } from 'shared/services/api/customers';
import useFetch from 'shared/hooks/useFetch';
import { Customer } from 'shared/services/api/customers/types';
import { createUserUserExtendedSchema } from './validate';
import { CommonUserType, FacilityUserRole } from 'types/user';
import { getCustomerFacilities } from 'shared/services/api/facilities';
import api from 'shared/services/api';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateCompanyUserModal = (props: Props) => {
  const { open, onClose, onSuccess } = props;

  const { data: customersData, isLoading: isLoadingCustomers } = useFetch<{
    rows: Customer[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomers(),
  });

  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [facilityOptions, setFacilityOptions] = useState<any[]>([]);

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await api.post('/users', {
        ...values,
      });
      onSuccess();
      form.reset();
    } catch (e) {
      const errors = {
        USER_ALREADY_EXISTS: 'Użytkownik o podanym adresie e-mail już istnieje',
      };
      // console.log(JSON.stringify())
      const code = errors[e.response?.data?.code] || e.message;
      setError(code);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const form = useForm({
    initialValues: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      type: undefined,
      customerIds: [],
      facilityIds: [],
      role: undefined,
    },

    validate: zodResolver(createUserUserExtendedSchema),
  });

  const typeData = [
    {
      label: 'Klient',
      value: CommonUserType.CUSTOMER,
    },
    {
      label: 'Pracownik naszej firmy',
      value: CommonUserType.COMPANY_EMPLOYEE,
    },
    {
      label: 'Administrator naszej firmy',
      value: CommonUserType.COMPANY_ADMIN,
    },
  ];

  const customerSelectData = useMemo(() => {
    return (
      customersData?.rows?.map((item) => ({
        label: `${item?.name}`,
        value: item?.id,
      })) || []
    );
  }, [customersData]);

  const customerIdsMemo = useMemo(() => {
    return (form.values?.customerIds as string[]) || ([] as string[]);
  }, [form.values.customerIds]);

  const customerDataMemo = useMemo(() => {
    return customersData;
  }, [customersData]);
  const previousCustomerIds = React.useRef<string[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingFacilities(true);
        const result = await Promise.all(
          customerIdsMemo.map((id) => getCustomerFacilities(id))
        );
        const options = result.flatMap((r) =>
          r.data.rows.map((item) => {
            const customer = customerDataMemo.rows.find(
              (customer) => customer.id === item.customerId
            );
            return {
              label: `${item?.name} (${customer?.name || ''})`,
              value: item?.id,
            };
          })
        );
        setFacilityOptions(options);
      } finally {
        setIsLoadingFacilities(false);
      }
    };

    const isTheSame = customerIdsMemo.every((id) =>
      previousCustomerIds.current.includes(id)
    );
    if (customerIdsMemo.length && !isTheSame) {
      fetch();
      previousCustomerIds.current = customerIdsMemo;
    }
  }, [customerIdsMemo, customerDataMemo]);

  const isLoadingAll = isLoadingCustomers;
  const roleData = [
    {
      label: 'Koordynator',
      value: FacilityUserRole.COORDINATOR,
    },
    {
      label: 'Klient',
      value: FacilityUserRole.CUSTOMER,
    },
    {
      label: 'Handlowiec',
      value: FacilityUserRole.SALES,
    },
    {
      label: 'Dzial Sprzedazy',
      value: FacilityUserRole.EQ,
    },
  ];

  // console.log(form.values);
  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj użytkownika">
        {isLoadingAll && <LoadingOverlay visible />}
        <form onSubmit={form.onSubmit(handleSubmit)} style={{ minHeight: 200 }}>
          <TextInput
            withAsterisk
            label="Email"
            description="Wpisz adres e-mail aby dodać nowego użytkownika"
            placeholder=""
            {...form.getInputProps('email')}
          />
          <Select
            mt={10}
            withAsterisk
            label="Typ konta"
            data={typeData}
            searchable
            placeholder=""
            {...form.getInputProps('type')}
          />

          <>
            <TextInput
              mt={10}
              withAsterisk
              label="Imię"
              placeholder=""
              {...form.getInputProps('firstName')}
            />
            <TextInput
              mt={10}
              withAsterisk
              label="Nazwisko"
              placeholder=""
              {...form.getInputProps('lastName')}
            />
          </>

          <Divider mt={20} mb={20} />
          <MultiSelect
            mt={10}
            label="Klient"
            placeholder="Wybierz klientów"
            data={customerSelectData}
            {...form.getInputProps('customerIds')}
          />

          <MultiSelect
            mt={10}
            rightSection={isLoadingFacilities ? <Loader size="xs" /> : null}
            disabled={!form.values?.customerIds.length}
            label="Wybierz placówki"
            placeholder="Wybierz placówki do których ma mieć dostęp użytkownik"
            data={facilityOptions}
            {...form.getInputProps('facilityIds')}
          />

          <Select
            mt={10}
            label="Rola w placówkach"
            data={roleData}
            searchable
            placeholder=""
            {...form.getInputProps('role')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="button" variant="outline" onClick={onClose}>
              Anuluj
            </Button>
            <Button
              type="submit"
              loading={loading}
              disabled={!form.values.email}
            >
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateCompanyUserModal;
