import { Alert, Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';

import { useTranslations } from 'shared/hooks/useTranslations';
import useFetch from 'shared/hooks/useFetch';
import { FacilityUser } from 'shared/services/api/facilities/types';
import FileAccessForm from 'shared/components/FileAccessForm';
import { CustomerFile } from 'shared/services/api/customers/files/types';
import { patchCustomerFacilityFile } from 'shared/services/api/files';
import { getCustomerFacilityUsers } from 'shared/services/api/facilities';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  file: CustomerFile;
  currentFolderId?: string;
}
const UpdateFileModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, file, facilityId } = props;
  const form = useForm({
    initialValues: {
      name: '',
      visibility: 'PUBLIC',
      users: [] as string[],
    },

    validate: {},
  });
  const [error, setError] = React.useState<string | null>(null);
  const { data } = useFetch<{
    rows: FacilityUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityUsers(customerId, facilityId),
  });

  const [isUploading, setUploading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setUploading(true);

      await patchCustomerFacilityFile(customerId, facilityId, file.id, {
        name: values.name,
        visibility: values.visibility,
        users: values.users,
      });

      onSuccess();
    } catch (error) {
      setError(error);
      alert('Failed to upload files');
    } finally {
      setUploading(false);
      form.reset();
    }
  };
  useEffect(() => {
    if (!open) {
      form.reset();
    }
    if (open) {
      form.setFieldValue('name', file.name);
      form.setFieldValue('visibility', file.visibility);
      form.setFieldValue(
        'users',
        file.userAccess?.map((user) => user.userId) || []
      );
    }
    // eslint-disable-next-line
  }, [open, file]);
  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj plik">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Plik"
            disabled
            withAsterisk
            multiple
            {...form.getInputProps('name')}
          />

          <FileAccessForm form={form} users={data.rows || []} customer />

          {!!error && (
            <Alert color="red">{translations.global.somethingWentWrong}</Alert>
          )}
          <Group position="right" mt="md">
            <Button type="button" variant="outline" onClick={onClose}>
              {translations.global.cancel}
            </Button>
            <Button type="submit" loading={isUploading}>
              {translations.global.save}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default UpdateFileModal;
