import React from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Card from 'shared/components/Card';

import { Spinner } from 'react-bootstrap';
import useFetch from 'shared/hooks/useFetch';
import { Customer } from 'shared/services/api/customers/types';
import { getCustomer } from 'shared/services/api/customers';
import CustomerFacilitiesRoutes from './Facilities';
import CustomerUsersRoutes from './Users';
import CustomerFiles from 'modules/Customers/Details/Files';

function CustomerDetails() {
  const { customerId } = useParams<{ customerId: string }>();
  const location = useLocation();
  const { data, isLoading } = useFetch<{ customer: Customer }>({
    initialValue: {},
    fetchAction: () => getCustomer(customerId),
  });
  const isLoaded = true;

  const hideSidebar = matchPath(location.pathname, {
    path: ROUTES.customers.facilities.details.path,
    exact: false,
  });
  return (
    <div className="container">
      <div className="d-flex flex-row">
        {isLoading && (
          <div>
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!isLoading && isLoaded && data.customer && (
          <>
            {!hideSidebar && (
              <div className="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
                <Card>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                      {/* <img src={user?.avatar || avatarBoyPlaceholder} alt="" /> */}
                    </div>
                    <div>
                      <span className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                        {data.customer.name}
                      </span>
                      {/* <div className="text-muted">{user.city}</div> */}
                    </div>
                  </div>
                  <div className="navi navi-bold navi-hover navi-active navi-link-rounded mt-10">
                    <div className="navi-item mb-2">
                      {/* <NavLink
                        to={ROUTES.customers.details.to(customerId)}
                        exact
                        className="navi-link py-4"
                        activeClassName="active"
                      >
                        <i className="fas fa-id-card navi-icon mr-2"></i>
                        <span className="navi-text font-size-lg">
                          Informacje o kliencie
                        </span>
                      </NavLink> */}
                      <NavLink
                        to={ROUTES.customers.facilities.list.to(customerId)}
                        className="navi-link py-4"
                        activeClassName="active"
                      >
                        <i className="fas fa-warehouse navi-icon mr-2"></i>
                        <span className="navi-text font-size-lg">
                          {/* TODO: Translate */}
                          Obiekty
                        </span>
                      </NavLink>
                      <NavLink
                        to={ROUTES.customers.files.to(customerId)}
                        className="navi-link py-4"
                        activeClassName="active"
                      >
                        <i className="fa fa-file navi-icon mr-2"></i>
                        <span className="navi-text font-size-lg">
                          {/* TODO: Translate */}
                         Dokumenty 
                        </span>
                      </NavLink>
                      <NavLink
                        to={ROUTES.customers.users.to(customerId)}
                        className="navi-link py-4"
                        activeClassName="active"
                      >
                        <i className="fas fa-users navi-icon mr-2"></i>
                        <span className="navi-text font-size-lg">
                          {/* TODO: Translate */}
                          Użytkownicy
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </Card>
              </div>
            )}
            <div className="flex-row-fluid ml-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <Switch>
                    <Route path={ROUTES.customers.details.path} exact>
                      <Redirect to={ROUTES.customers.facilities.list.to(customerId)} />
                      {/* <CustomerMainData /> */}
                    </Route>
                    <Route path={ROUTES.customers.facilities.default.path}>
                      <CustomerFacilitiesRoutes />
                    </Route>
                    <Route path={ROUTES.customers.users.default.path}>
                      <CustomerUsersRoutes />
                    </Route>
                    <Route path={ROUTES.customers.files.path}>
                      <CustomerFiles />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CustomerDetails;
