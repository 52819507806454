import { Alert, Button, Group, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchCustomerFacilityUser } from 'shared/services/api/facilities';
import { FacilityUser } from 'shared/services/api/facilities/types';
import { FacilityUserRole } from 'types/user';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  user: FacilityUser;
  facilityId: string;
}
const EditFacilityUserModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, user, facilityId } = props;

  const form = useForm({
    initialValues: {
      role: user?.role,
    },
    validate: {
      role: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCustomerFacilityUser(customerId, facilityId, user?.userId, {
        role: values.role,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && open) {
      form.setValues(user);
    }
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [user, open]);

  const roleData = [
    {
      label: 'Klient',
      value: FacilityUserRole.CUSTOMER,
    },
    {
      label: 'Koordynator',
      value: FacilityUserRole.COORDINATOR,
    },
    {
      label: 'Handlowiec',
      value: FacilityUserRole.SALES,
    },
    {
      label: 'Dzial Sprzedazy',
      value: FacilityUserRole.EQ,
    },
  ];
  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj użytkownika">
        <form onSubmit={form.onSubmit(handleSubmit)} style={{ minHeight: 400 }}>
          <TextInput
            disabled
            withAsterisk
            value={`${user?.user.firstName} ${user?.user.lastName}`}
            label="Użytkownik"
            placeholder=""
            mb={10}
          />
          <Select
            mt={10}
            withAsterisk
            label="Rola"
            data={roleData}
            searchable
            placeholder=""
            {...form.getInputProps('role')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditFacilityUserModal;
