import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import { deleteUserInvitation } from 'shared/services/api/users';

interface Props {
  show: boolean;
  onClose: Function;
  invitation?: any;
}

const RemoveTrainerInvitationModal = (props: Props) => {
  const { show, onClose, invitation } = props;
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => onClose();
  const onSave = async () => {
    try {
      setLoading(true);
      await deleteUserInvitation(invitation!.id);
      onClose(true);
      toast.success('Invitation has been removed');
    } catch (e) {
      toast.error('Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Remove invitation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to remove invitation?</Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={onSave} isLoading={isLoading}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default RemoveTrainerInvitationModal;
