/**
 * src/Login.js
 */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "shared/components/Button";
import { auth, firebase, getFirebaseError } from "shared/services/firebase";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "shared/components/TextField";
import { useTranslations } from "shared/hooks/useTranslations";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

interface Props {}
export default function PasswordLogin(props: Props) {
  const [error, setError] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: any) => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async (user) => {
        const token = await auth?.currentUser?.getIdToken(true);
        if (token) {
          setLoading(false);
          history.push("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.code);
        // var errorCode = error.code;
        // var errorMessage = error.message;
      });
  };
  const translations = useTranslations();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-md-12">
          <TextField
            name="email"
            ref={register}
            label={translations.auth.emailAddress}
            error={errors.email}
            required
          />
        </div>
        <div className="col-md-12">
          <TextField
            name="password"
            type="password"
            ref={register}
            label={translations.auth.password}
            error={errors.password}
            required
          />
        </div>
        <div className="col-md-12">
          <Button
            color="primary"
            className="btn btn-primary mr-2"
            isLoading={isLoading}
          >
            {translations.auth.signIn}
          </Button>
        </div>
      </div>
      {error && (
        <div
          className="alert alert-custom alert-outline-danger fade show mt-5"
          role="alert"
        >
          <div className="alert-text">
            {" "}
            {error ? getFirebaseError(error) : ""}
          </div>
        </div>
      )}
    </form>
  );
}
