import React from 'react';
import cs from 'classnames';
import styles from './styles.module.scss';
interface Props {
  label?: string;
  required?: boolean;
  name: string;
  defaultValue?: any;
  onChange?: any;
  className?: string;
}
const CheckboxField = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { label, required, name, defaultValue, onChange, className } = props;

    return (
      <label className={cs('checkbox', className, styles.root)}>
        <input
          ref={ref}
          type="checkbox"
          required={required}
          name={name}
          onChange={onChange}
          className={styles.input}
          defaultChecked={defaultValue}
        />
        <span></span>
        <div className={styles.label}>{label}</div>
      </label>
    );
  }
);

export default CheckboxField;
