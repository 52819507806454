import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCustomerFacilityAreas } from 'shared/services/api/facilities';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import CreateFacilityAreaModal from './CreateFacilityAreaModal';
import { FacilityArea } from 'shared/services/api/facilities/types';
import EditFacilityAreaModal from 'modules/Customers/Details/Facilities/Details/Areas/List/EditFacilityAreaModal';
import { IconPencil } from '@tabler/icons';

const FacilitiesList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityArea[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityAreas(customerId, facilityId),
  });

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };

  const [editFacilityArea, setEditFacilityArea] =
    useState<FacilityArea | null>();
  const handleOnEdit = () => {
    setEditFacilityArea(null);
    refresh();
  };

  const handleOpenEditModal = (facilityArea: FacilityArea) => () => {
    setEditFacilityArea(facilityArea);
  };

  return (
    <div className="">
      <CreateFacilityAreaModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />
      <EditFacilityAreaModal
        facilityId={facilityId}
        open={!!editFacilityArea}
        onClose={() => setEditFacilityArea(null)}
        onSuccess={handleOnEdit}
        customerId={customerId}
        facilityArea={editFacilityArea!}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Strefy'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj strefe
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Typ strefy</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {renderRows(
                  data?.rows || [],
                  0,
                  customerId,
                  facilityId,
                  handleOpenEditModal
                )}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

function renderRows(
  rows,
  depth = 0,
  customerId,
  facilityId,
  handleOpenEditModal
) {
  const depthChar = new Array(depth + 1).join('- ');
  return rows.map((area: FacilityArea) => (
    <React.Fragment key={area.id}>
      <tr>
        <td>
          {depthChar}
          <Link
            to={ROUTES.customers.facilities.areas.details.to(
              customerId,
              facilityId,
              area.id
            )}
          >
            {area.name}
          </Link>
        </td>
        <td>{area.companyAreaType?.name}</td>
        <td>{formatDate(area.createdAt)}</td>
        <td>
          {/* <Button onClick={}>Edytuj</Button> */}
          <Group spacing="xs" noWrap>
            <ActionIcon
              type="button"
              color="blue"
              onClick={handleOpenEditModal(area)}
            >
              <IconPencil size={15} />
            </ActionIcon>
            {/* <ActionIcon
              type="button"
              color="red"
              onClick={handleRemove(inventoryItem)}
            >
              <IconTrash size={15} color="red" />
            </ActionIcon> */}
          </Group>
        </td>
      </tr>
      {area.subAreas &&
        area.subAreas.length > 0 &&
        renderRows(
          area.subAreas,
          depth + 1,
          customerId,
          facilityId,
          handleOpenEditModal
        )}
    </React.Fragment>
  ));
}

export default FacilitiesList;
