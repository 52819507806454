import React from 'react';
import { FieldErrors } from 'react-hook-form';
import TextField from 'shared/components/TextField';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  errors: FieldErrors<any>; // TODO
  register: any; // TODO
  control: any;
}
export default function ExerciceForm(props: Props) {
  const { errors, register } = props;
  const translations = useTranslations();
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <TextField
            name="name"
            ref={register}
            label={translations.global.name}
            tip={translations.groups.groupExample}
            error={errors.name}
          />
          <TextField
            name="order"
            ref={register}
            label={translations.global.order}
            error={errors.order}
          />
        </div>
        <div className="col-md-3">
          {/* <ReactSelectField
            name="parentInventoryGroupId"
            control={control}
            label="Nadrzędna grupa"
            placeholder="Wybierz nadrzędną grupe"
            error={errors.name}
            options={options}
          /> */}
        </div>
      </div>
    </>
  );
}
