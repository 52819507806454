import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import ReactSelectField from 'shared/components/ReactSelectField';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import { takeInventoryItem } from 'shared/services/api/inventory';
import { getUsers } from 'shared/services/api/users';

interface Props {
  show: boolean;
  onClose: Function;
  exercice: any;
}

const AssignToUserModal = (props: Props) => {
  const { show, onClose, exercice } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const {
    data: usersResponse,
    refresh,
    isLoading: isLoadingUsers,
    isLoaded,
  } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getUsers(),
  });
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      user: undefined,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const userId = data?.user?.value;
      setLoading(true);
      await takeInventoryItem(exercice.id, { userId });
      onClose(true);
      toast.success('Item has been taken.');
      history.push(ROUTES.inventory.items.list.path);
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoaded || isLoadingUsers) {
      return;
    }

    if (show) {
      refresh();
    }
  }, [refresh, show, isLoaded, isLoadingUsers]);

  const handleClose = () => onClose();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign inventory item to user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want take away this item from{' '}
          <strong>
            {exercice?.owner?.firstName} {exercice.owner?.lastName}
          </strong>
          ?
          <ReactSelectField
            name="user"
            control={control}
            label="New user"
            placeholder="Select user"
            defaultValue={undefined}
            options={
              usersResponse?.users?.map((user) => ({
                value: user?.user.id,
                label: `${user?.user?.firstName} ${user?.user?.lastName}`,
              })) || []
            }
            ref={register()}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            color="info"
            isLoading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Set
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};
export default AssignToUserModal;
