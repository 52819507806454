import api from 'shared/services/api';
import {
  FacilityFile,
  FacilityFileBody,
  FacilityFolder,
  FacilityFolderBody,
} from './types';

export const getCustomerFacilityFolder = (
  customerId: string,
  facilityId: string,
  folderId: string
) =>
  api.get<{ customer: FacilityFolder }>(
    `/customers/${customerId}/facilities/${facilityId}/folders/${folderId}`
  );

export const getCustomerFacilityFolders = (
  customerId: string,
  facilityId: string,
  parentFolderId?: string,
  rootOnly?: boolean
) =>
  api.get<{ rows: FacilityFolder[] }>(
    `/customers/${customerId}/facilities/${facilityId}/folders`,
    { params: { parentFolderId, rootOnly } }
  );

export const patchCustomerFacilityFolder = (
  customerId: string,
  facilityId: string,
  folderId: string,
  body: FacilityFolderBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/folders/${folderId}`,
    body
  );

export const postCustomerFacilityFolder = (
  customerId: string,
  facilityId: string,
  body: FacilityFolderBody
) =>
  api.post(`/customers/${customerId}/facilities/${facilityId}/folders`, body);

export const deleteCustomerFacilityFolder = (
  customerId: string,
  facilityId: string,
  folderId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/folders/${folderId}`
  );

// FILES

export const getCustomerFacilityFile = (
  customerId: string,
  facilityId: string,
  fileId: string
) =>
  api.get<{ customer: FacilityFile }>(
    `/customers/${customerId}/facilities/${facilityId}/files/${fileId}`
  );

export const getCustomerFacilityFileSignedUrl = (
  customerId: string,
  facilityId: string,
  fileId: string
) =>
  api.get<{ url: string }>(
    `/customers/${customerId}/facilities/${facilityId}/files/${fileId}/signed-url`
  );

export const getCustomerFacilityFiles = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityFile[] }>(
    `/customers/${customerId}/facilities/${facilityId}/files`
  );

export const patchCustomerFacilityFile = (
  customerId: string,
  facilityId: string,
  fileId: string,
  body: FacilityFileBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/files/${fileId}`,
    body
  );

export const postCustomerFacilityFile = (
  customerId: string,
  facilityId: string,
  body: any
) =>
  api.post(`/customers/${customerId}/facilities/${facilityId}/files`, body, {
    // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const deleteCustomerFacilityFile = (
  customerId: string,
  facilityId: string,
  fileId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/files/${fileId}`
  );
