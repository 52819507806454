import api from 'shared/services/api';
import {} from './types';

export const getTrainerInvitations = () =>
  api.get('/admin/trainer-invitations');

export const postTrainerInvitation = (email: string, company: string) =>
  api.post('/admin/trainer-invitations', { email, company });

export const deleteTrainerInvitation = (email: string) =>
  api.delete(`/admin/trainer-invitations/${email}`);

export const patchTrainerInvitation = (email: string, body: any) =>
  api.patch(`/admin/trainer-invitations/${email}`, body);

export const getCompaniesList = () => api.get('/admin/companies');
export const getCompany = (id: string) => api.get(`/admin/companies/${id}`);
export const patchCompany = (id: string, data: any) =>
  api.patch(`/admin/companies/${id}`, data);

export const postCompany = (name: string) =>
  api.post('/admin/companies', { name });
