import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchCustomerFacilityArea } from 'shared/services/api/facilities';
import useFetch from 'shared/hooks/useFetch';
import { FacilityArea } from 'shared/services/api/facilities/types';
import { useTranslations } from 'shared/hooks/useTranslations';
import { AreaType } from 'shared/services/api/company/areaTypes/types';
import { getAreaTypes } from 'shared/services/api/company/areaTypes';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  facilityArea: FacilityArea;
}
const EditFacilityAreaModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId, facilityArea } =
    props;

  const {
    data: areaTypesData,
    isLoading,
    isLoaded,
  } = useFetch<{
    rows: AreaType[];
  }>({
    initialValue: {},
    fetchAction: () => getAreaTypes(),
  });

  const form = useForm<{
    name: string;
    parentAreaId?: string;
    companyAreaTypeId?: string;
    order?: number;
  }>({
    initialValues: {
      name: '',
      parentAreaId: undefined,
      companyAreaTypeId: undefined,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCustomerFacilityArea(customerId, facilityId, facilityArea.id, {
        name: values.name,
        parentAreaId: values.parentAreaId,
        companyAreaTypeId: values.companyAreaTypeId,
        order: values.order,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const translations = useTranslations();

  useEffect(() => {
    if (open && facilityArea) {
      form.setValues({
        name: facilityArea.name,
        parentAreaId: facilityArea.parentAreaId,
        companyAreaTypeId: facilityArea.companyAreaTypeId,
        order: facilityArea.order,
      });
    }
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open, facilityArea]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj strefe">
        {isLoading && <LoadingOverlay visible />}
        {isLoaded && (
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ minHeight: 400 }}
          >
            <TextInput
              withAsterisk
              // TODO: Translations
              label="Nazwa"
              placeholder=""
              {...form.getInputProps('name')}
            />
            <Select
              mt={10}
              clearable
              searchable
              description="Pole wykorzystywane podczas audytu, aby wyświetlić odpowiednie pytania."
              data={
                areaTypesData?.rows?.map((areaType) => ({
                  value: areaType.id,
                  label: areaType.name,
                })) || []
              }
              // TODO: Translations
              label="Typ strefy dla audytu"
              placeholder=""
              {...form.getInputProps('companyAreaTypeId')}
            />
            {/* <Select
              mt={10}
              withAsterisk
              clearable
              searchable
              data={areas}
              // TODO: Translations
              label="Nadrzędna strefa"
              placeholder=""
              {...form.getInputProps('parentAreaId')}
            /> */}
            <NumberInput
              name="order"
              label={translations.global.order}
              {...form.getInputProps('order')}
            />

            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button
                type="button"
                onClick={onClose}
                loading={loading}
                variant="outline"
              >
                {translations.global.cancel}
              </Button>
              <Button type="submit" loading={loading}>
                {translations.global.add}
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default EditFacilityAreaModal;
