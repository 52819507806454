import { CompanyService } from 'shared/services/api/company/services/types';

export enum FacilityAdditionalServiceStatus {
  'OPEN' = 'OPEN',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'CLOSED' = 'CLOSED',
}

export const FACILITY_ADDITIONAL_SERVICE_STATUS_COLOR = {
  [FacilityAdditionalServiceStatus.OPEN]: 'yellow',
  [FacilityAdditionalServiceStatus.IN_PROGRESS]: 'violet',
  [FacilityAdditionalServiceStatus.CLOSED]: 'green',
};

export interface FacilityAdditionalServiceComment {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

export interface FacilityAdditionalService {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  parentAreaId?: string;
  customerId: string;
  companyId: string;
  companyServiceId?: string;
  companyService?: CompanyService;
  facilityId: string;
  status: FacilityAdditionalServiceStatus;
  creator?: {
    id: string;
    firstName: string;
    lastName: string;
  };

  comments?: FacilityAdditionalServiceComment[];
}
export interface FacilityAdditionalServiceBody {}
