import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { toast } from 'react-toastify';
import RejectRequestModal from '../RejectRequestModal';
import CancelRequestModal from '../CancelRequestModal';
import {
  getInventoryRequest,
  postInventoryRequestAction,
} from 'shared/services/api/inventory';
import Badge from 'shared/components/Badge';
import {
  getBadgeColorByRequestPriority,
  getBadgeColorByRequestStatus,
} from 'shared/utils/status';
import { dateFromNow } from 'shared/utils/time';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Params {
  id: string;
}
export default function Edit() {
  const translations = useTranslations();
  const [isSaving, setSaving] = useState(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const { id } = useParams<Params>();
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryRequest(id),
  });

  const handleStatusChange = (status: string) => async () => {
    setSaving(true);
    try {
      await postInventoryRequestAction(id, {
        status,
      });
      toast.success('Request has been accepted');
      refresh();
      setSaving(false);
    } catch (e) {
      setSaving(false);
      toast.error('Coś poszło nie tak, spróbuj za chwilę.');
    }
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.requests.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.requests.list.path }}
          linkAs={Link}
        >
          {translations.inventory.requests}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.request?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <RejectRequestModal
        show={removeModal}
        onClose={() => setRemoveModal(false)}
        request={data.request}
        refresh={refresh}
      />
      <CancelRequestModal
        show={cancelModal}
        onClose={() => setCancelModal(false)}
        request={data.request}
        refresh={refresh}
      />
      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && (
            <div className="alert alert-danger">No inventory group</div>
          )}

          {isLoaded && (
            <Card
              title={
                <>
                  {data.request?.name}{' '}
                  <Badge
                    color={getBadgeColorByRequestStatus(data.request?.status)}
                  >
                    {translations.inventory.status[data.request?.status] ||
                      data.request?.status}
                  </Badge>
                </>
              }
              toolbar={
                <WithRole roles={[USER_PERMISSION.INVENTORY_REQUEST_MANAGE]}>
                  <>
                    {data.request?.status === 'CREATED' && (
                      <Button
                        color="danger"
                        onClick={() => setRemoveModal(true)}
                        className="mr-5"
                        type="button"
                      >
                        {translations.global.reject}
                      </Button>
                    )}
                    {data.request?.status === 'IN_PROGRESS' && (
                      <Button
                        color="danger"
                        onClick={() => setCancelModal(true)}
                        className="mr-5"
                        type="button"
                      >
                        {translations.global.cancel}
                      </Button>
                    )}
                    {data.request?.status === 'IN_PROGRESS' && (
                      <Button
                        color="success"
                        onClick={handleStatusChange('DONE')}
                        className="mr-5"
                        type="button"
                      >
                        {translations.global.done}
                      </Button>
                    )}
                    {data.request?.status === 'CREATED' && (
                      <Button
                        color="primary"
                        isLoading={isSaving}
                        onClick={handleStatusChange('IN_PROGRESS')}
                      >
                        {translations.global.accept}
                      </Button>
                    )}
                  </>
                </WithRole>
              }
            >
              <div className="row">
                <div className="col-md-3">
                  <strong>{translations.global.name}</strong>
                  <div>{data.request?.name}</div>
                </div>
                <div className="col-md-3">
                  <strong>{translations.global.description}</strong>
                  <div>{data.request?.description}</div>
                </div>
                <div className="col-md-2">
                  <strong>{translations.global.priority}</strong>
                  <div>
                    {' '}
                    <Badge
                      color={getBadgeColorByRequestPriority(
                        data.request.priority
                      )}
                    >
                      {data.request.priority}
                    </Badge>
                  </div>
                </div>
                <div className="col-md-2">
                  <strong>{translations.global.usability}</strong>
                  <div>{data.request?.name}</div>
                </div>
                <div className="col-md-2">
                  <strong>{translations.global.user}</strong>
                  <div>
                    <span className="symbol symbol-80">
                      {data.request?.creator?.avatar ? (
                        <img src={data.request?.creator?.avatar} alt="" />
                      ) : (
                        <span className="symbol-label font-size-h5 font-weight-bold"></span>
                      )}
                    </span>
                    <div>
                      <strong>
                        {data.request?.creator?.firstName}{' '}
                        {data.request?.creator?.lastName}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-10">
                <div className="col-md-12">
                  <h3>{translations.global.actions}</h3>
                  {data.request?.actions?.map((action) => (
                    <div key={action.id} className="mb-5">
                      <Badge
                        color={getBadgeColorByRequestStatus(action.toStatus)}
                      >
                        {translations.inventory.status[action.toStatus] ||
                          action.toStatus}
                      </Badge>{' '}
                      {action.user?.firstName} {action.user?.lastName}/{' '}
                      {dateFromNow(action.createdAt)}
                      {action.description && (
                        <div className="alert alert-light" role="alert">
                          {action.description}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}
