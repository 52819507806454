import React, { useState } from 'react';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import Badge from 'shared/components/Badge';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import RemoveTrainerInvitationModal from '../RemoveTrainerInvitationModal';
import ManageTrainerInvitationModal from '../ManageTrainerInvitationModal';
import {
  getCompaniesList,
  getTrainerInvitations,
} from 'shared/services/api/admin';
import {
  TrainerInvitationsResponse,
  TrainerInvitation,
  CompaniesListResponse,
} from 'shared/services/api/admin/types';
import { getBadgeColorByInvitationStatus } from 'shared/utils/status';

export default function ManageTrainers() {
  const [selectedInvitation, setSelectedInvitation] = useState<
    undefined | TrainerInvitation
  >();
  const [manageModal, setManageModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const { data: responseData, refresh } = useFetch<TrainerInvitationsResponse>({
    initialValue: {},
    fetchAction: () => getTrainerInvitations(),
  });
  const { data: companiesData } = useFetch<CompaniesListResponse>({
    initialValue: {},
    fetchAction: () => getCompaniesList(),
  });
  const data = responseData.invitations; // TODO

  const handleEdit = (invitation: TrainerInvitation) => () => {
    setSelectedInvitation(invitation);
    setManageModal(true);
  };
  const handleCreate = () => {
    setSelectedInvitation(undefined);
    setManageModal(true);
  };
  const handleRemove = (invitation: TrainerInvitation) => (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedInvitation(invitation);
    setRemoveModal(true);
  };
  const onClose = (shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      refresh();
    }
    setSelectedInvitation(undefined);
    setRemoveModal(false);
    setManageModal(false);
  };
  return (
    <div className="container">
      <ManageTrainerInvitationModal
        companies={companiesData.companies}
        show={manageModal}
        invitation={selectedInvitation}
        onClose={onClose}
      />
      <RemoveTrainerInvitationModal
        show={removeModal}
        invitation={selectedInvitation}
        onClose={onClose}
      />
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.admin.default.path }}
          linkAs={Link}
        >
          Admin
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Company admin invitations</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title="Company admin invitations"
            toolbar={
              <Button color="primary" onClick={handleCreate}>
                Send invitation
              </Button>
            }
          >
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Email address</th>
                  <th>Status</th>
                  <th>Expires at</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((invitation) => (
                  <tr key={invitation.id}>
                    <td>{invitation.email}</td>
                    <td>{<Badge color={getBadgeColorByInvitationStatus(invitation.status)}>{invitation.status}</Badge>}</td>
                    <td>{invitation.expiresAt}</td>
                    <td>
                      <Button
                        type="button"
                        color="light"
                        className="btn-sm btn-icon mr-5 ml-auto"
                        onClick={handleEdit(invitation)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Button>
                      <Button
                        type="button"
                        color="danger"
                        className="btn-sm btn-icon"
                        onClick={handleRemove(invitation)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
}
