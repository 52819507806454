import React, { useState } from 'react';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import Badge from 'shared/components/Badge';
import { Alert, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';
import RemoveUserInvitationModal from '../RemoveUserInvitationModal';
import ManageUserInvitationModal from '../ManageUserInvitationModal';

import { TrainerInvitation } from 'shared/services/api/admin/types';
import { getBadgeColorByInvitationStatus } from 'shared/utils/status';
import { getUsersInvitations } from 'shared/services/api/users';
import WithRole from 'shared/components/WithRole';
import { USER_PERMISSION } from 'shared/constants/user';
import { dateFromNow } from 'shared/utils/time';

export default function ManageTrainers() {
  const [selectedInvitation, setSelectedInvitation] = useState<
    undefined | TrainerInvitation
  >();
  const [manageModal, setManageModal] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const { data: responseData, refresh } = useFetch<any>({
    initialValue: {},
    fetchAction: () => getUsersInvitations(),
  });
  const data = responseData.invitations; // TODO

  const handleCreate = () => {
    setSelectedInvitation(undefined);
    setManageModal(true);
  };
  const handleRemove = (invitation: TrainerInvitation) => (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedInvitation(invitation);
    setRemoveModal(true);
  };
  const onClose = (shouldRefresh?: boolean) => {
    if (shouldRefresh) {
      refresh();
    }
    setSelectedInvitation(undefined);
    setRemoveModal(false);
    setManageModal(false);
  };
  return (
    <div className="container">
      <ManageUserInvitationModal show={manageModal} onClose={onClose} />
      <RemoveUserInvitationModal
        show={removeModal}
        invitation={selectedInvitation}
        onClose={onClose}
      />
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.users.default.path }}
          linkAs={Link}
        >
          Użytkownicy
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Zaproszenia</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title="Zaproszenia użytkowników"
            toolbar={
              <>
                <WithRole roles={[USER_PERMISSION.USERS_INVITE]}>
                  <Button
                    color="primary"
                    onClick={handleCreate}
                    disabled={responseData.limitReached}
                  >
                    Wyślij zaproszenie
                  </Button>
                </WithRole>
              </>
            }
          >
            {responseData.limitReached && (
              <Alert variant="warning">
                You have reached user limit. Upgrade plan to invite more users
                or remove pending invitations.
              </Alert>
            )}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Adres e-mail</th>
                  <th>Status</th>
                  <th>Wygasa</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((invitation) => (
                  <tr key={invitation.id}>
                    <td>{invitation.email}</td>
                    <td>
                      {
                        <Badge
                          color={getBadgeColorByInvitationStatus(
                            invitation.status
                          )}
                        >
                          {invitation.status}
                        </Badge>
                      }
                    </td>
                    <td>{dateFromNow(invitation.expiresAt)}</td>
                    <td>
                      {invitation.status === 'PENDING' && (
                        <Button
                          type="button"
                          color="danger"
                          className="btn-sm btn-icon"
                          onClick={handleRemove(invitation)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
}
