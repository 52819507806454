import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import ROUTES from 'shared/constants/routes';
import { unassignInventoryItem } from 'shared/services/api/inventory';

interface Props {
  show: boolean;
  onClose: Function;
  exercice: any;
}

const UnassignFromUserModal = (props: Props) => {
  const { show, onClose, exercice } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const { handleSubmit } = useForm({
    defaultValues: {
      user: undefined,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await unassignInventoryItem(exercice.id, { userId: null });
      onClose(true);
      toast.success('Item has been unassigned.');
      history.push(ROUTES.inventory.items.list.path);
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    } finally {
      setLoading(false);
    }
  };
  const handleClose = () => onClose();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Unassign inventory item from user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to unassign this item from{' '}
          <strong>
            {exercice?.owner?.firstName} {exercice.owner?.lastName}
          </strong>
          ?
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Cancel
          </Button>
          <Button
            color="info"
            isLoading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Unassign
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};
export default UnassignFromUserModal;
