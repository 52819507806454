import {
  Alert,
  Button,
  Group,
  Modal,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { postCompanyProduct } from 'shared/services/api/company/companyProducts';

import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
}
const CreateCompanyProductModal = (props: Props) => {
  const { open, onClose, onSuccess } = props;
  const form = useForm({
    initialValues: {
      name: '',
      order: 0,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCompanyProduct({
        name: values.name,
        order: values.order,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj zadanie">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            // TODO: Translations
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <NumberInput
            withAsterisk
            label="Kolejnosc"
            min={0}
            placeholder=""
            {...form.getInputProps('order')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              {translations.global.add}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default CreateCompanyProductModal;
