import { z } from 'zod';
import { CommonUserType } from '../../../../../../types/user';

export const createCustomerUserBaseSchema = z.object({
  email: z.string().email('Błędny email'),
});

export const createCustomerUserExtendedSchema =
  createCustomerUserBaseSchema.extend({
    firstName: z.string().min(1, 'Imię jest wymagane'),
    lastName: z.string().min(1, 'Nazwisko jest wymagane'),
    type: z.nativeEnum(CommonUserType),
  });
