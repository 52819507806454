import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ROUTES from "shared/constants/routes";
import WithRole from "shared/components/WithRole";
import { USER_PERMISSION } from "shared/constants/user";
import CreatePdf from "./Create";
import PdfList from "./List";
import PdfTemplates from "../Templates";
import EditPdfTemplate from "./Edit";

function Pdf() {
  return (
    <Switch>
      <Route path={ROUTES.pdf.default.path} exact>
        <WithRole roles={[USER_PERMISSION.PDF_LIST]}>
          <Redirect to={ROUTES.pdf.list.path} />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.list.path} exact>
        <WithRole roles={[USER_PERMISSION.PDF_LIST]}>
          <PdfList />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.create.path} exact>
        <WithRole roles={[USER_PERMISSION.PDF_CREATE]}>
          <CreatePdf />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.templates.default.path}>
        <WithRole roles={[USER_PERMISSION.PDF_TEMPLATE_LIST]}>
          <PdfTemplates />
        </WithRole>
      </Route>
      <Route path={ROUTES.pdf.edit.path} exact>
        <WithRole roles={[USER_PERMISSION.PDF_EDIT]}>
          <EditPdfTemplate />
        </WithRole>
      </Route>
    </Switch>
  );
}

export default Pdf;
