import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router';
import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';

import { toast } from 'react-toastify';
import validation from '../validation';
import RemoveExerciceModal from '../RemoveExerciceModal';
import {
  editInventoryGroup,
  getInventoryGroup,
} from 'shared/services/api/inventory';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Params {
  id: string;
}
export default function EditExercice() {
  const translations = useTranslations();
  const { data, isLoading, isLoaded, error } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryGroup(id),
  });
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [isSaving, setSaving] = useState(false);
  const { id } = useParams<Params>();
  const history = useHistory();
  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      order: undefined,
      name: undefined,
    },
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    reset(data.group);
  }, [data, reset]);

  const onSubmit = async (data: any) => {
    setSaving(true);
    try {
      await editInventoryGroup(id, {
        name: data.name,
        order: data.order,
        // ...data,
        // parentInventoryGroupId: data.parentInventoryGroupId.value,
      });
      toast.success(translations.groups.editSuccess);
      setSaving(false);
      history.push(ROUTES.inventory.groups.list.path);
    } catch (e) {
      toast.error('Coś poszło nie tak, spróbuj za chwilę.');
    }
  };
  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.groups.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.groups.list.path }}
          linkAs={Link}
        >
          {translations.groups.groups}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.group?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <RemoveExerciceModal
        show={removeModal}
        onClose={() => setRemoveModal(false)}
        exercice={data.group}
      />
      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && (
            <div className="alert alert-danger">No inventory group</div>
          )}
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                title={translations.groups.editGroup}
                toolbar={
                  <>
                    <Button
                      color="danger"
                      onClick={() => setRemoveModal(true)}
                      className="mr-5"
                      type="button"
                    >
                      {translations.global.remove}
                    </Button>
                    <Button color="primary" isLoading={isSaving}>
                      {translations.global.save}
                    </Button>
                  </>
                }
              >
                <ExerciceForm
                  errors={errors}
                  register={register}
                  control={control}
                />
              </Card>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
