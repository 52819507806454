import React from 'react';
import { FieldErrors } from 'react-hook-form';
import RadioField from 'shared/components/RadioField';
import TextareaField from 'shared/components/TextareaField';
import TextField from 'shared/components/TextField';
import { useTranslations } from 'shared/hooks/useTranslations';
import {
  INVENTORY_PRIORITY,
  INVENTORY_USABILITY,
} from 'shared/utils/status';

interface Props {
  errors: FieldErrors<any>; // TODO
  register: any; // TODO
  control: any;
}
export default function ExerciceForm(props: Props) {
  const { errors, register } = props;
  const translations = useTranslations();
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <TextField
            name="name"
            ref={register}
            label={translations.global.name}
            tip={translations.global.tip}
            error={errors.name}
          />
        </div>
        <div className="col-md-4">
          <TextareaField
            name="description"
            ref={register}
            label={translations.global.description}
            error={errors.description}
          />
        </div>
        <div className="col-md-2">
          <RadioField
            name="priority"
            ref={register}
            label={translations.global.priority}
            error={errors.priority}
            options={[
              {
                label: translations.inventory.priority[INVENTORY_PRIORITY.HIGH],
                value: 'LOW',
              },
              {
                label:
                  translations.inventory.priority[INVENTORY_PRIORITY.MEDIUM],
                value: 'MEDIUM',
              },
              {
                label: translations.inventory.priority[INVENTORY_PRIORITY.HIGH],
                value: 'HIGH',
              },
            ]}
          />
        </div>
        <div className="col-md-2">
          <RadioField
            name="usability"
            ref={register}
            label={translations.global.usability}
            error={errors.usability}
            options={[
              {
                label:
                  translations.inventory.usability[INVENTORY_USABILITY.PUBLIC],
                value: 'PUBLIC',
              },
              {
                label:
                  translations.inventory.usability[INVENTORY_USABILITY.PRIVATE],
                value: 'PRIVATE',
              },
            ]}
          />
        </div>

        <div className="col-md-3">
          {/* <ReactSelectField
            name="parentInventoryGroupId"
            control={control}
            label="Nadrzędna grupa"
            placeholder="Wybierz nadrzędną grupe"
            error={errors.name}
            options={options}
          /> */}
        </div>
      </div>
    </>
  );
}
