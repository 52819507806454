import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteAreaType } from 'shared/services/api/company/areaTypes';
import { AreaType } from 'shared/services/api/company/areaTypes/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  areaType: AreaType;
}
const RemoveAreaTypeModal = (props: Props) => {
  const { open, onClose, onSuccess, areaType } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteAreaType(areaType.id);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń typ strefy">
        Czy na pewno chcesz usunąć typ strefy?
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Usuń
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default RemoveAreaTypeModal;
