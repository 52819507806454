import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import Form from '../Form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import UserContext from 'shared/contexts/UserContext';
import api from 'shared/services/api';
import { useForm } from '@mantine/form';
import useFetch from 'shared/hooks/useFetch';
import { useTranslations } from 'shared/hooks/useTranslations';
import { LoadingOverlay } from '@mantine/core';

export default function CreatePdf() {
  const [isLoading, setLoading] = useState(false);
  const { data: dataTemplates, isLoading: isLoadingTemplates } = useFetch<any>({
    initialValue: { templates: [] },
    fetchAction: () => api.get(`/pdf/templates`),
  });
  const history = useHistory();

  const form = useForm({
    initialValues: {
      name: undefined,
      templateId: undefined,
      configuration: {
        addIntroDate: 'YES',
        logo: undefined,
        logoInput: undefined,
      },
    },
    validate: {
      templateId: (value) => (!value ? 'Wybierz szablon' : null),
    },
  });
  
  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const { data: result } = await api.post('/pdf', data);
      const formData = new FormData();
      let logo = data.configuration?.logo;
      if (data.configuration?.logoInput) {
        formData.append('file', data.configuration.logoInput[0]);
        const { data: dataLogo } = await api.post(
          `/pdf/${result.pdf.id}/logo`,
          formData,
          {
            // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        );
        logo = dataLogo.logo;
        form.setFieldValue('configuration.logo', logo);
      }
      toast.success('Item has been added');
      history.push(ROUTES.pdf.edit.to(result.pdf.id));
    } catch (e) {
      setLoading(false);
      toast.error('Something went wrong.');
    }
  };
  const translations = useTranslations();
  const filteredTemplates = useMemo(
    () =>
      dataTemplates?.templates?.filter(
        (template) => template.configuration?.isActive === 'YES'
      ),
    [dataTemplates.templates]
  );

  useEffect(() => {
    const defaultTemplateId = filteredTemplates?.find(
      (template) => template.configuration?.isDefault === 'YES'
    )?.id;
    if (defaultTemplateId) {
      form.setFieldValue('templateId', defaultTemplateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTemplates]);
  return (
    <UserContext.Consumer>
      {({ selectedCompany }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.pdf.list.path }}
              linkAs={Link}
            >
              Oferty
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {translations.pdf.createPdf}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="row">
            <div className="col-xl-12">
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Card
                  title={translations.pdf.createPdf}
                  toolbar={
                    <Button color="primary" isLoading={isLoading}>
                      {translations.global.create}
                    </Button>
                  }
                >
                  <Form
                    form={form}
                    templates={filteredTemplates}
                    mode="CREATE"
                  />
                  <LoadingOverlay visible={isLoadingTemplates} />
                </Card>
              </form>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
