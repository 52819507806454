import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Textarea,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import Card from 'shared/components/Card';

import useFetch from 'shared/hooks/useFetch';
import {
  getCustomerFacilityAdditionalService,
  postCustomerFacilityAdditionalServiceComment,
} from 'shared/services/api/facilities/additionalServices';
import { useParams } from 'react-router';
import { useTranslations } from 'shared/hooks/useTranslations';
import { formatDate } from 'shared/utils/time';
import { useForm } from '@mantine/form';
import { auth } from 'shared/services/firebase';
import ROUTES from 'shared/constants/routes';
import { Link } from 'react-router-dom';
import ChangeAdditionalServiceStatusModal from './ChangeAdditionalServiceStatusModal';
import {
  FACILITY_ADDITIONAL_SERVICE_STATUS_COLOR,
  FacilityAdditionalService,
} from 'shared/services/api/facilities/additionalServices/types';

const AdditionalServiceDetails = () => {
  const { customerId, facilityId, additionalServiceId } = useParams<any>();

  const { data, error, isLoading, refresh } = useFetch<{
    additionalService: FacilityAdditionalService;
  }>({
    initialValue: {},
    fetchAction: () =>
      getCustomerFacilityAdditionalService(
        customerId,
        facilityId,
        additionalServiceId
      ),
  });
  const translations = useTranslations();
  const form = useForm({
    initialValues: {
      description: '',
    },
  });
  const [isAddingComment, setIsAddingComment] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setIsAddingComment(true);
      await postCustomerFacilityAdditionalServiceComment(
        customerId,
        facilityId,
        additionalServiceId,
        values
      );
      refresh();
    } catch {
    } finally {
      setIsAddingComment(false);
    }
  };
  const { currentUser: x } = useCurrentUser();
  const currentUser = x?.claims?.userUuid;
  const [showChangeStatusModal, setShowChangeStatusModal] =
    useState<boolean>(false);

  const handleChangeStatus = () => {
    setShowChangeStatusModal(false);
    refresh();
  };

  return (
    <>
      <ChangeAdditionalServiceStatusModal
        open={showChangeStatusModal}
        customerId={customerId}
        facilityId={facilityId}
        additionalServiceId={additionalServiceId}
        onClose={() => setShowChangeStatusModal(false)}
        onSuccess={handleChangeStatus}
        currentStatus={data.additionalService?.status}
      />
      <Card
        title={
          data.additionalService ? (
            <>
              {data.additionalService.name}{' '}
              <Badge
                color={
                  FACILITY_ADDITIONAL_SERVICE_STATUS_COLOR[
                    data.additionalService.status
                  ]
                }
                size="lg"
              >
                {
                  translations.enums.facilityAdditionalServiceStatus[
                    data.additionalService.status
                  ]
                }
              </Badge>
            </>
          ) : null
        }
        toolbar={
          <Button
            variant="outline"
            onClick={() => setShowChangeStatusModal(true)}
          >
            Zmień status
          </Button>
        }
      >
        <LoadingOverlay visible={isLoading} />
        {data.additionalService && (
          <div>
            <strong>Data stworzenia:</strong>
            <br /> {formatDate(data.additionalService.createdAt)}
            <br />
            <br />
            <strong>Stworzone przez:</strong>
            <br /> {data.additionalService.creator?.firstName}{' '}
            {data.additionalService.creator?.lastName}
            <br />
            <br />
            <strong>Opis:</strong>
            <br /> {data.additionalService.description}
            <br />
            <br />
            <strong>Wybrana usługa:</strong>
            <br /> {data.additionalService?.companyService?.name || '-'}
            <br />
            <br />
          </div>
        )}
      </Card>
      {data.additionalService && (
        <Card title="Komentarze">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Textarea
              withAsterisk
              mt={10}
              // TODO: Translations

              label="Komentarz"
              placeholder=""
              {...form.getInputProps('description')}
            />

            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button type="submit" loading={isAddingComment}>
                {translations.global.add}
              </Button>
            </Group>
          </form>
          <Divider mt={20} mb={20} />
          {!data.additionalService.comments?.length && (
            <Alert color="blue" mt={20}>
              Brak komentarzy
            </Alert>
          )}
          <Box mt={20}>
            {data.additionalService.comments?.map((comment) => (
              <div key={comment.id}>
                <Message
                  creator={comment.creator!}
                  isUserComment={comment.creator?.id === currentUser}
                  description={comment.description}
                />
              </div>
            ))}
          </Box>
        </Card>
      )}
    </>
  );
};

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<any>();
  useEffect(() => {
    auth.currentUser?.getIdTokenResult().then((u) => setCurrentUser(u));
  }, []);
  return { currentUser };
};

const Message = ({ creator, description, isUserComment }) => {
  const { firstName, lastName, avatar, createdAt, id } = creator;

  const customerId = 'x';
  const facilityId = 'x';
  const messageStyle = isUserComment
    ? 'd-flex flex-column align-items-end mb-10'
    : 'd-flex flex-column align-items-start mb-10';

  const textStyle = isUserComment
    ? 'p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end'
    : 'p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start';

  return (
    <div className={messageStyle}>
      <div className="d-flex align-items-center mb-2">
        <div className={isUserComment ? 'me-3' : 'ms-3'}>
          <Link
            to={ROUTES.customers.facilities.users.details.to(
              customerId,
              facilityId,
              id
            )}
            className={`fs-5 fw-bold text-gray-900 text-hover-primary ${
              isUserComment ? 'ms-1 mr-3 ml-5' : 'me-1 mr-3 ml-3'
            }`}
          >
            {firstName} {lastName}
          </Link>
        </div>
        <span className="symbol symbol-35">
          {avatar ? (
            <img src={avatar} alt="" />
          ) : (
            <span className="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
              {firstName[0]}
              {lastName[0]}
            </span>
          )}
        </span>
      </div>
      <div className={textStyle} data-kt-element="message-text">
        {description}
      </div>
      <span className="text-muted fs-7 mb-1">{formatDate(createdAt)}</span>
    </div>
  );
};

export default AdditionalServiceDetails;
