import api from 'shared/services/api';
import { FacilityOrder, FacilityOrderBody } from './types';
// Orders
export const getCustomerFacilityOrder = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.get<{ customer: FacilityOrder }>(
    `/customers/${customerId}/facilities/${facilityId}/orders/${orderId}`
  );

export const getCustomerFacilityOrders = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityOrder[] }>(
    `/customers/${customerId}/facilities/${facilityId}/orders`
  );

export const patchCustomerFacilityOrder = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: FacilityOrderBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/orders/${orderId}`,
    body
  );

export const postCustomerFacilityOrder = (
  customerId: string,
  facilityId: string,
  body: FacilityOrderBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/orders`, body);

export const postCustomerFacilityOrderComment = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: {
    description: string;
  }
) =>
  api.post(
    `/customers/${customerId}/facilities/${facilityId}/orders/${orderId}/comments`,
    body
  );

export const deleteCustomerFacilityOrder = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/orders/${orderId}`
  );
