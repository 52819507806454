import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';

import { useTranslations } from 'shared/hooks/useTranslations';
import { deleteCustomerFacilityFolder } from 'shared/services/api/files';
import { FacilityFolder } from 'shared/services/api/files/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  folder: FacilityFolder;
}
const DeleteFilesFolderModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId, folder } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await deleteCustomerFacilityFolder(customerId, facilityId, folder.id);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Usuń folder">
        <Alert color="red">
          Czy na pewno chcesz usunąć ten folder? Wszystkie kategorie i pliki w
          nim zawarte zostanę odpięte.
        </Alert>
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button variant="outline" onClick={onClose}>
            {translations.global.cancel}
          </Button>
          <Button
            type="submit"
            loading={loading}
            color="red"
            onClick={handleSubmit}
          >
            {translations.global.delete}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteFilesFolderModal;
