import React, { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';

const ContentLoader = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: ReactNode;
}) => {
  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <>
          <div className={styles.bg} />
          <div className={styles.spinner}>
            <Spinner animation="border" variant="light" />;
          </div>
        </>
      )}
      {children}
    </div>
  );
};
export default ContentLoader;
