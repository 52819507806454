import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteCustomerFacilityUser } from 'shared/services/api/facilities';
import { FacilityUser } from 'shared/services/api/facilities/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  user: FacilityUser;
}
const RemoveFacilityUserModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, user, facilityId } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomerFacilityUser(customerId, facilityId, user.userId);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Odepnij Użytkownika">
        Czy na pewno chcesz odpiąć użytkownika od tej placówki?
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Zapisz
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default RemoveFacilityUserModal;
