import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomerFacilities } from 'shared/services/api/facilities';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import CreateFacilityModal from './CreateFacilityModal';
import { Facility } from 'shared/services/api/facilities/types';
import { IconPencil, IconTrash } from '@tabler/icons';
import EditFacilityModal from './EditFacilityModal';
import RemoveFacilityModal from './RemoveFacilityModal';

const FacilitiesList = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{ rows: Facility[] }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilities(customerId),
  });
  const translations = useTranslations();
  const [editFacilityModalOpen, setEditFacilityModalOpen] = useState<
    Facility | undefined
  >();
  const [removeFacilityModalOpen, setRemoveFacilityModalOpen] = useState<
    Facility | undefined
  >();
  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };

  const handleOnEdit = () => {
    setEditFacilityModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveFacilityModalOpen(undefined);
    refresh();
  };

  const handleEdit = (customer: Facility) => () => {
    setEditFacilityModalOpen(customer);
  };
  const handleRemove = (customer: Facility) => () => {
    setRemoveFacilityModalOpen(customer);
  };

  return (
    <div className="">
      <CreateFacilityModal
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />
      <EditFacilityModal
        customerId={customerId}
        facility={editFacilityModalOpen!}
        open={!!editFacilityModalOpen}
        onClose={() => setEditFacilityModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveFacilityModal
        customerId={customerId}
        facility={removeFacilityModalOpen!}
        open={!!removeFacilityModalOpen}
        onClose={() => setRemoveFacilityModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />
      <div className="row">
        <div className="col-xl-12">
          <Card
            title={translations.facilities.facilities}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj obiekt
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Adres</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.rows?.map((facility: any) => (
                  <tr key={facility.id}>
                    <td>
                      <Link
                        to={ROUTES.customers.facilities.details.to(
                          customerId,
                          facility.id
                        )}
                      >
                        {facility?.name}
                      </Link>
                    </td>
                    <td style={{ whiteSpace: 'pre-line' }}>
                      {facility.address}
                    </td>
                    <td>{formatDate(facility.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap>
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(facility)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(facility)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesList;
