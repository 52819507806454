import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import ROUTES from 'shared/constants/routes';
import { removeInventoryGroup } from 'shared/services/api/inventory';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Props {
  show: boolean;
  onClose: Function;
  exercice: any;
}

const RemoveExerciceModal = (props: Props) => {
  const translations = useTranslations();
  const { show, onClose, exercice } = props;
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const onSave = async () => {
    try {
      setLoading(true);
      await removeInventoryGroup(exercice.id);
      onClose(true);
      toast.success(translations.groups.removeSuccess);
      history.push(ROUTES.inventory.groups.list.path);
    } catch (e) {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => onClose();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{translations.groups.removeGroupTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{translations.groups.removeGroupDescription}</Modal.Body>
      <Modal.Footer>
        <Button color="white" onClick={handleClose}>
          {translations.global.cancel}
        </Button>
        <Button color="danger" onClick={onSave} isLoading={isLoading}>
          {translations.global.remove}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default RemoveExerciceModal;
