import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';

import { postUserInvitation } from 'shared/services/api/users';

interface Props {
  show: boolean;
  onClose: Function;
}

const ManageTrainerInvitationModal = (props: Props) => {
  const { show, onClose } = props;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({});
  const handleClose = () => onClose();

  const onSubmit = async (formValues: any) => {
    try {
      setLoading(true);
      await postUserInvitation(formValues.email);
      toast.success('Invitation has been send');
      onClose(true);
    } catch (e) {
      toast.error('Coś poszło nie tak. Spróbuj ponownie później.');
    } finally {
      setLoading(false);
    }
  };

  return (
    // @ts-ignore
    <Modal show={show} onHide={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Wyślij zaproszenie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            name="email"
            ref={register}
            label="Adres email"
            error={errors.email}
            required
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="white" onClick={handleClose} type="button">
            Anuluj
          </Button>
          <Button color="primary" isLoading={isLoading}>
            Wyślij
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default ManageTrainerInvitationModal;
