import { DefaultMantineColor } from '@mantine/core';
import { Customer } from 'shared/services/api/customers/types';
import { Facility } from 'shared/services/api/facilities/types';

export type Gender = 'MALE' | 'FEMALE';
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  description: string;
  avatar: string;
  gender: Gender;
  public: boolean;
  instagram: string;
  facebook: string;
  youtube: string;
  roles: string[];
  type?: CommonUserType;
  facilities?: Facility[];
  customers?: Customer[];
}

export interface CompanyUser {
  user: User;
}

export enum CommonUserType {
  CUSTOMER = 'CUSTOMER',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
}

export enum FacilityUserRole {
  'CUSTOMER' = 'CUSTOMER',
  'SALES' = 'SALES',
  'COORDINATOR' = 'COORDINATOR',
  'EQ' = 'EQ',
  'CLEANING' = 'CLEANING',
}

export const COMMON_USER_TYPE_BADGE_COLOR = {
  [CommonUserType.COMPANY_EMPLOYEE]: 'green' as DefaultMantineColor,
  [CommonUserType.CUSTOMER]: 'yellow' as DefaultMantineColor,
  [CommonUserType.COMPANY_ADMIN]: 'violet' as DefaultMantineColor,
};

export const FACILITY_USER_ROLE_BADGE_COLOR = {
  [FacilityUserRole.CUSTOMER]: 'yellow',
  [FacilityUserRole.COORDINATOR]: 'blue',
  [FacilityUserRole.SALES]: 'violet',
  [FacilityUserRole.EQ]: 'orange',
  [FacilityUserRole.CLEANING]: 'gray',
};
