import React from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import useFetch from 'shared/hooks/useFetch';
import { getInventoryRequests } from 'shared/services/api/inventory';
import { dateFromNow } from 'shared/utils/time';
import {
  getBadgeColorByRequestPriority,
  getBadgeColorByRequestStatus,
} from 'shared/utils/status';
import Badge from 'shared/components/Badge';
import { useTranslations } from 'shared/hooks/useTranslations';

export default function InventoryRequestsList() {
  const translations = useTranslations();
  const { data, isLoading, isLoaded } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryRequests(),
  });
  const history = useHistory();

  const onAdd = () => {
    history.push(ROUTES.inventory.requests.create.path);
  };
  const onRowClick = (id: number) => () => {
    history.push(ROUTES.inventory.requests.manage.to(id));
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.requests.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {translations.inventory.requests}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title={translations.inventory.requests}
            toolbar={
              <>
                <Button onClick={onAdd} color="primary">
                  {translations.global.create}
                </Button>
              </>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {isLoaded && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{translations.global.name}</th>
                    <th>{translations.global.user}</th>
                    <th>{translations.global.priority}</th>
                    <th>{translations.global.usability}</th>
                    <th>{translations.global.status}</th>
                    <th>{translations.global.createdAt}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.requests.map((request) => (
                    <tr
                      key={request.id}
                      onClick={onRowClick(request.id)}
                      role="button"
                    >
                      <td>{request.name}</td>
                      <td>
                        {request.creator?.firstName} {request.creator?.lastName}
                      </td>
                      <td>
                        <Badge
                          color={getBadgeColorByRequestPriority(
                            request.priority
                          )}
                        >
                          {translations.inventory.priority[request.priority] ||
                            request.priority}
                        </Badge>
                      </td>
                      <td>
                        {translations.inventory.usability[request.usability] ||
                          request.usability}
                      </td>
                      <td>
                        <Badge
                          color={getBadgeColorByRequestStatus(request.status)}
                        >
                          {translations.inventory.status[request.status] ||
                            request.status}
                        </Badge>
                      </td>
                      <td>{dateFromNow(request.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
