import React from 'react';
import { FieldErrors } from 'react-hook-form';
import RadioField from 'shared/components/RadioField';
import TextField from 'shared/components/TextField';

interface Props {
  errors: FieldErrors<any>; // TODO
  register: any; // TODO
  control: any;
}
export default function ExerciceForm(props: Props) {
  const { errors, register } = props;

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <TextField
            name="name"
            ref={register}
            label="Nazwa"
            error={errors.name}
          />
        </div>
        <div className="col-md-3">
          <TextField
            name="usersLimit"
            ref={register}
            label="Users limit"
            error={errors.name}
          />
        </div>
        <div className="col-md-3">
          <TextField
            name="inventoryItemsLimit"
            ref={register}
            label="Inventory items limit"
            error={errors.name}
          />
        </div>
        <div className="col-md-3">
          <RadioField
            label="Status"
            name="status"
            ref={register}
            options={[
              { label: 'Active', value: 'ACTIVE' },
              { label: 'Inactive', value: 'INACTIVE' },
            ]}
          />
        </div>
      </div>
    </>
  );
}
