import React, { useMemo, useRef, useState } from 'react';
import Card from 'shared/components/Card';
import styles from './styles.module.scss';
import {
  ActionIcon,
  Button,
  Group,
  Loader,
  LoadingOverlay,
} from '@mantine/core';
import {
  IconChevronRight,
  IconFolder,
  IconLock,
  IconMoodEmpty,
  IconPencil,
  IconTrash,
  IconUpload,
} from '@tabler/icons';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import CreateFilesFolderModal from './CreateFilesFolderModal';
import useFetch from 'shared/hooks/useFetch';
import {
  CustomerFile,
  CustomerFolder,
} from 'shared/services/api/customers/files/types';
import {
  getCustomerCustomerFileSignedUrl,
  getCustomerCustomerFiles,
  getCustomerCustomerFolders,
  postCustomerCustomerFile,
} from 'shared/services/api/customers/files';
import { formatDate } from 'shared/utils/time';
import DeleteFilesFolderModal from './DeleteFilesFolderModal';
import DeleteFilesFileModal from './DeleteFilesFileModal';
import { useTranslations } from 'shared/hooks/useTranslations';
import UpdateFilesFolderModal from './UpdateFilesFolderModal';
import UploadFilesModal from 'modules/Customers/Details/Files/UploadFilesModal';
import UpdateFileModal from 'modules/Customers/Details/Files/UpdateFileModal';

const CustomerFiles = () => {
  const { customerId } = useParams<{
    customerId: string;
  }>();
  const match = useRouteMatch();

  const filePath = match.params[0]?.split('/');
  const folderId = filePath[filePath.length - 1] || undefined;

  const {
    data,
    isLoading,
    refresh: refreshFolders,
  } = useFetch<{
    rows: CustomerFolder[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerCustomerFolders(customerId),
  });

  const { data: dataFiles, refresh: refreshFiles } = useFetch<{
    rows: CustomerFile[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerCustomerFiles(customerId),
  });

  const refreshData = () => {
    refreshFiles();
    refreshFolders();
  };
  const currentFolderId = folderId;

  const filesAndFolders = useMemo(() => {
    let folders: FileOrFolder[] = [];
    let files: FileOrFolder[] = [];

    if (data && data.rows) {
      folders = data.rows
        .filter((folder) => {
          return !currentFolderId
            ? !folder.parentFolderId
            : folder.parentFolderId === currentFolderId;
        })
        .map((folder) => ({
          id: folder.id,
          name: folder.name,
          type: 'DIRECTORY',
          parentFolderId: folder.parentFolderId,
          createdAt: folder.createdAt,
          visibility: folder.visibility,
        }));
    }

    if (dataFiles && dataFiles.rows) {
      files = dataFiles.rows
        .filter((file) => {
          return !currentFolderId
            ? !file.folderId
            : file.folderId === currentFolderId;
        })
        .map((file) => ({
          id: file.id,
          name: file.name,
          type: 'FILE',
          parentFolderId: file.folderId,
          createdAt: file.createdAt,
          size: file.size,
          creatorName: `${file.creator?.firstName} ${file.creator?.lastName}`,
          visibility: file.visibility,
        }));
    }

    return [...folders, ...files]; // Folders first, then files
  }, [data, dataFiles, currentFolderId]);
  const files = filesAndFolders;

  const [showAddFolderModal, setShowAddFolderModal] = React.useState(false);
  const handleOnAddFolder = () => {
    setShowAddFolderModal(true);
  };

  const handleOnSuccessAddFolderModal = () => {
    setShowAddFolderModal(false);
    refreshData();
  };

  const handleOnCloseAddFolderModal = () => {
    setShowAddFolderModal(false);
  };

  const breadcrumbs = useMemo(() => {
    let path: any[] = [];
    let current = folderId;

    while (current) {
      // eslint-disable-next-line
      const currentFolder = data.rows?.find((folder) => folder.id === current);
      if (currentFolder) {
        // @ts-ignore
        path.unshift(currentFolder);
        current = currentFolder.parentFolderId;
      } else {
        break;
      }
    }
    if (path.length === 0) {
      return [];
    }
    path.unshift({
      id: 'root',
      name: 'Pliki',
      isRoot: true,
    });
    return path;
  }, [data, folderId]);

  // const [files, setFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsHovering(false);
    const files = event.dataTransfer.files;
    uploadFiles(files);
  };

  const uploadFiles = async (fileList: FileList) => {
    try {
      setUploading(true);
      const files = Array.from(fileList);
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          if (currentFolderId) {
            formData.append('folderId', currentFolderId);
          }
          await postCustomerCustomerFile(customerId, formData);
        })
      );
      setUploading(false);
      refreshData();
    } catch (error) {
      setUploading(false);
      alert('Failed to upload files');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.type = 'text';
        fileInputRef.current.type = 'file';
      }
    }
  };

  const [isHovering, setIsHovering] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsHovering(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsHovering(false);
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (event) => {
    uploadFiles(event.target.files);
  };

  const handleClickOnUpload = (
    e: any //: React.ChangeEventHandler<HTMLInputElement>
  ) => {
    handleOnUploadFiles();
    // e.stopPropagation();
    // e.preventDefault();
    // @ts-ignore
    // fileInputRef.current?.click();
  };
  const [downloadingFiles, setDownloadingFiles] = useState<string[]>([]);
  const handleFileDownload = (fileId) => async (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      setDownloadingFiles([...downloadingFiles, fileId]);
      const { data } = await getCustomerCustomerFileSignedUrl(
        customerId,
        fileId
      );
      setDownloadingFiles(downloadingFiles.filter((id) => id !== fileId));
      window.open(data.url, '_blank');
    } catch {
      setDownloadingFiles(downloadingFiles.filter((id) => id !== fileId));
    }
  };

  const [showUploadFilesModal, setShowUploadFilesModal] = React.useState(false);
  const handleOnUploadFiles = () => {
    setShowUploadFilesModal(true);
  };

  const handleOnSuccessUploadFilesModal = () => {
    setShowUploadFilesModal(false);
    refreshData();
  };

  const handleOnCloseUploadFilesModal = () => {
    setShowUploadFilesModal(false);
  };

  const [folderToDelete, setFolderToDelete] = useState<CustomerFolder | null>(
    null
  );
  const [folderToUpdate, setFolderToUpdate] = useState<CustomerFolder | null>(
    null
  );
  const [fileToDelete, setFileToDelete] = useState<CustomerFile | null>(null);
  const [fileToUpdate, setFileToUpdate] = useState<CustomerFile | null>(null);
  const handleOnCloseUpdateFileModal = () => {
    setFileToUpdate(null);
  };
  const handleOnSuccessUpdateFileModal = () => {
    setFileToUpdate(null);
    refreshData();
  };
  const handleOnCloseUpdateFolderModal = () => {
    setFolderToUpdate(null);
  };
  const handleOnSuccessUpdateFolderModal = () => {
    setFolderToUpdate(null);
    refreshData();
  };
  const handleOnCloseDeleteFolderModal = () => {
    setFolderToDelete(null);
  };
  const handleOnSuccessDeleteFolderModal = () => {
    setFolderToDelete(null);
    refreshData();
  };

  const handleClickDeleteFileOrFolder = (file: FileOrFolder) => (e) => {
    if (file.type === 'DIRECTORY') {
      const folder = data.rows?.find((folder) => folder.id === file.id);
      if (folder) {
        return setFolderToDelete(folder);
      }
    }
    if (file.type === 'FILE') {
      const dFile = dataFiles.rows?.find((folder) => folder.id === file.id);
      if (dFile) {
        return setFileToDelete(dFile);
      }
    }
  };

  const handleClickUpdateFolder = (file: FileOrFolder) => (e) => {
    if (file.type === 'DIRECTORY') {
      const folder = data.rows?.find((folder) => folder.id === file.id);
      if (folder) {
        return setFolderToUpdate(folder);
      }
    }
  };

  const handleClickUpdateFile = (file: FileOrFolder) => (e) => {
    if (file.type === 'FILE') {
      const folder = dataFiles.rows?.find((folder) => folder.id === file.id);
      if (folder) {
        return setFileToUpdate(folder as CustomerFile);
      }
    }
  };

  const handleOnCloseDeleteFileModal = () => {
    setFileToDelete(null);
  };
  const handleOnSuccessDeleteFileModal = () => {
    setFileToDelete(null);
    refreshData();
  };

  const translations = useTranslations();
  return (
    <Card
      title="Pliki"
      toolbar={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Group>
            <Button onClick={handleClickOnUpload} variant="outline">
              Dodaj plik
            </Button>
            <Button onClick={handleOnAddFolder}>Dodaj folder</Button>
          </Group>
          <div>
            <small>Maksymalny rozmiar pliku 10MB</small>
          </div>
        </div>
      }
    >
      <input
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      {isUploading && <LoadingOverlay visible title="Uploading..." />}
      {isLoading && <LoadingOverlay visible />}
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={`${styles.dropzone} ${
          isHovering ? styles.dropzoneHover : ''
        }`}
      >
        <UpdateFileModal
          open={!!fileToUpdate}
          onSuccess={handleOnSuccessUpdateFileModal}
          onClose={handleOnCloseUpdateFileModal}
          customerId={customerId}
          file={fileToUpdate!}
        />
        <UploadFilesModal
          open={showUploadFilesModal}
          onClose={handleOnCloseUploadFilesModal}
          onSuccess={handleOnSuccessUploadFilesModal}
          customerId={customerId}
          currentFolderId={currentFolderId}
        />
        <CreateFilesFolderModal
          open={showAddFolderModal}
          onClose={handleOnCloseAddFolderModal}
          onSuccess={handleOnSuccessAddFolderModal}
          customerId={customerId}
          folderId={folderId}
        />
        <UpdateFilesFolderModal
          open={!!folderToUpdate}
          onClose={handleOnCloseUpdateFolderModal}
          onSuccess={handleOnSuccessUpdateFolderModal}
          customerId={customerId}
          folder={folderToUpdate!}
        />
        <DeleteFilesFolderModal
          open={!!folderToDelete}
          onClose={handleOnCloseDeleteFolderModal}
          onSuccess={handleOnSuccessDeleteFolderModal}
          customerId={customerId}
          folder={folderToDelete!}
        />
        <DeleteFilesFileModal
          open={!!fileToDelete}
          onClose={handleOnCloseDeleteFileModal}
          onSuccess={handleOnSuccessDeleteFileModal}
          customerId={customerId}
          file={fileToDelete!}
        />
        <div className="d-flex">
          {breadcrumbs.length > 0 && (
            <div className={styles.breadCrumbs}>
              {breadcrumbs.map((crumb: any, index) => (
                <span key={crumb.id} className={styles.breadCrumbsItem}>
                  <Link
                    to={`/customers/${customerId}/files${
                      crumb.isRoot ? '' : `/${crumb.id}`
                    }`}
                  >
                    {crumb.name}
                  </Link>
                  {index < breadcrumbs.length - 1 ? (
                    <IconChevronRight
                      size={17}
                      className={styles.breadCrumbArrow}
                    />
                  ) : (
                    ''
                  )}
                </span>
              ))}
            </div>
          )}
        </div>
        {files.length === 0 && (
          <div className={styles.emptyFiles}>
            <IconMoodEmpty
              className={`text-primary ${styles.emptyFilesIcon}`}
              size={55}
            />
            <div className={styles.emptyFilesTitle}>
              Brak plików i folderów.
            </div>
            <div className={styles.emptyFilesUploadTitle}>
              <IconUpload
                className={`${styles.emptyFilesUpladIcon}`}
                size={25}
              />
              <div className={styles.emptyFilesUploadTitleContent}>
                Aby wgrać plik{' '}
                <Button
                  variant="subtle"
                  onClick={handleClickOnUpload}
                  style={{ display: 'inline' }}
                >
                  kliknij tutaj
                </Button>
                <br /> lub przeciągnij plik w te miejsce
                <div>
                  <small>Maksymalny rozmiar pliku 10MB</small>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.files}>
          {files.length > 0 && (
            <div className={styles.fileRow}>
              <div className={styles.fileIcon}></div>
              <div className={styles.fileTitle}></div>
              <div className={styles.fileSize}>Rozmiar</div>
              <div className={styles.fileCreator}>Autor</div>
              <div className={styles.fileCreatedAt}>
                {translations.global.createdAt}
              </div>
              <div className={styles.fileActions}></div>
            </div>
          )}
          {files.map((file) => {
            const isDirectory = file.type === 'DIRECTORY';
            const isPrivate = file.visibility === 'PRIVATE';
            return (
              <div className={styles.fileRow} key={file.id}>
                {(isDirectory || isPrivate) && (
                  <div className={styles.fileIcon}>
                    <div className={styles.fileIconWrapper}>
                      {isDirectory && (
                        <IconFolder
                          className={`text-primary ${styles.fileIconIcon}`}
                          size={25}
                        />
                      )}
                      {isPrivate && (
                        <IconLock
                          className={`text-primary ${styles.fileIconIcon}`}
                          size={25}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`${styles.fileTitle} ${
                    !isDirectory && !isPrivate ? styles.fullSpan : ''
                  }`}
                >
                  <Link
                    to={ROUTES.customers.files.file(customerId, [file.id])}
                    onClick={
                      file.type === 'FILE'
                        ? handleFileDownload(file.id)
                        : undefined
                    }
                    className={styles.fileTitleLink}
                  >
                    {file.name}
                    {downloadingFiles.includes(file.id) ? (
                      <Loader size="xs" />
                    ) : null}
                  </Link>
                </div>

                <div className={styles.fileSize}>
                  {file.size ? fileSizeToMbOrKb(file.size) : ''}
                </div>
                <div className={styles.fileCreator}>{file.creatorName}</div>
                <div className={styles.fileCreatedAt}>
                  {!isDirectory && formatDate(file.createdAt)}
                </div>
                <div className={styles.fileActions}>
                  <div className={styles.fileActionsWrapper}>
                    {isDirectory && (
                      <ActionIcon
                        color="blue.3"
                        variant="filled"
                        onClick={handleClickUpdateFolder(file)}
                      >
                        <IconPencil size={17} />
                      </ActionIcon>
                    )}
                    {!isDirectory && (
                      <ActionIcon
                        color="blue.3"
                        variant="filled"
                        onClick={handleClickUpdateFile(file)}
                      >
                        <IconPencil size={17} />
                      </ActionIcon>
                    )}
                    <ActionIcon
                      color="red.3"
                      variant="filled"
                      ml={10}
                      onClick={handleClickDeleteFileOrFolder(file)}
                    >
                      <IconTrash size={17} />
                    </ActionIcon>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

interface FileOrFolder {
  id: string;
  name: string;
  type: 'DIRECTORY' | 'FILE';
  parentFolderId?: string;
  createdAt: string | Date;
  size?: number;
  creatorName?: string;
  visibility?: 'PRIVATE' | 'PUBLIC';
}

export default CustomerFiles;

const fileSizeToMbOrKb = (size: number) => {
  if (size < 1024) {
    return `${size} B`;
  }
  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
};
