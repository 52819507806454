import React from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import ROUTES from 'shared/constants/routes';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { useTranslations } from 'shared/hooks/useTranslations';
import { getCustomerFacilityUsers } from 'shared/services/api/facilities';
import { useState } from 'react';
import { ActionIcon, Badge, Button, Group } from '@mantine/core';
import { FacilityUser } from 'shared/services/api/facilities/types';
import CreateFacilityUserModal from './CreateFacilityUserModal';
import {
  COMMON_USER_TYPE_BADGE_COLOR,
  FACILITY_USER_ROLE_BADGE_COLOR,
} from '../../../../../../../types/user';
import { IconPencil, IconTrash } from '@tabler/icons';
import EditFacilityUserModal from 'modules/Customers/Details/Facilities/Details/Users/List/EditFacilityUserModal';
import RemoveFacilityUserModal from 'modules/Customers/Details/Facilities/Details/Users/List/RemoveFacilityUserModal';

const FacilityUsersList = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);
  const [editCustomerModalOpen, setEditCustomerModalOpen] = useState<
    FacilityUser | undefined
  >();
  const [removeCustomerModalOpen, setRemoveCustomerModalOpen] = useState<
    FacilityUser | undefined
  >();
  const { data, error, isLoading, refresh } = useFetch<{
    rows: FacilityUser[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityUsers(customerId, facilityId),
  });
  const translations = useTranslations();
  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };
  const handleOnEdit = () => {
    setEditCustomerModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveCustomerModalOpen(undefined);
    refresh();
  };

  const handleEdit = (customer: FacilityUser) => () => {
    setEditCustomerModalOpen(customer);
  };
  const handleRemove = (customer: FacilityUser) => () => {
    setRemoveCustomerModalOpen(customer);
  };

  return (
    <div className="">
      <CreateFacilityUserModal
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
        facilityId={facilityId}
        currentUsers={data?.rows}
      />
      <EditFacilityUserModal
        customerId={customerId}
        user={editCustomerModalOpen!}
        open={!!editCustomerModalOpen}
        onClose={() => setEditCustomerModalOpen(undefined)}
        onSuccess={handleOnEdit}
        facilityId={facilityId}
      />
      <RemoveFacilityUserModal
        customerId={customerId}
        user={removeCustomerModalOpen!}
        open={!!removeCustomerModalOpen}
        onClose={() => setRemoveCustomerModalOpen(undefined)}
        onSuccess={handleOnDelete}
        facilityId={facilityId}
      />

      <div className="row">
        <div className="col-xl-12">
          <Card
            title={'Użytkownicy'}
            toolbar={
              <Button onClick={() => setCreateFacilityModalOpen(true)}>
                {/* TODO: Translations */}
                Dodaj użytkownika
              </Button>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Typ</th>
                  <th>Rola</th>
                  <th>Data stworzenia</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((facilityUser) => {
                  const { user, type, role, createdAt } = facilityUser;
                  return (
                    <tr key={user.id}>
                      <td>
                        <Link
                          to={ROUTES.customers.facilities.users.details.to(
                            customerId,
                            facilityId,
                            user.id
                          )}
                        >
                          {user.firstName} {user.lastName}
                        </Link>
                      </td>
                      <td>
                        <Badge color={COMMON_USER_TYPE_BADGE_COLOR[type]}>
                          {translations.enums.commonUserType[type]}
                        </Badge>
                      </td>
                      <td>
                        <Badge color={FACILITY_USER_ROLE_BADGE_COLOR[role]}>
                          {translations.enums.facilityUserRole[role]}
                        </Badge>
                      </td>
                      <td>{formatDate(createdAt)}</td>
                      <td>
                        <Group spacing="xs" noWrap>
                          <ActionIcon
                            type="button"
                            color="blue"
                            onClick={handleEdit(facilityUser)}
                          >
                            <IconPencil size={15} />
                          </ActionIcon>
                          <ActionIcon
                            type="button"
                            color="red"
                            onClick={handleRemove(facilityUser)}
                          >
                            <IconTrash size={15} color="red" />
                          </ActionIcon>
                        </Group>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default FacilityUsersList;
