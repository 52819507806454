import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
// import List from './List';
// import InvitationsList from './InvitationsList';
// import Details from './Details';
// import Edit from './Edit';
import FacilitiesList from './List';
// import CustomerDetails from './Details';

function CustomerFacilityAreasRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.facilities.areas.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <FacilitiesList />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomerFacilityAreasRoutes;
