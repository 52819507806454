import api from 'shared/services/api';
import {
  FacilityPostBody,
  Facility,
  FacilityPatchBody,
  FacilityArea,
  FacilityAreaBody,
  FacilityUser,
  FacilityUserBody,
  FacilityRequest,
  FacilityRequestBody,
  FacilityUserPatchBody,
} from './types';

export const getCustomerFacility = (customerId: string, facilityId: string) =>
  api.get<{ customer: Facility }>(
    `/customers/${customerId}/facilities/${facilityId}`
  );

export const getCustomerFacilityDashboard = (
  customerId: string,
  facilityId: string
) =>
  api.get<any>(`/customers/${customerId}/facilities/${facilityId}/dashboard`);

export const getCustomerFacilities = (customerId: string) =>
  api.get<{ rows: Facility[] }>(`/customers/${customerId}/facilities`);

export const patchCustomerFacility = (
  customerId: string,
  facilityId: string,
  body: FacilityPatchBody
) => api.patch(`/customers/${customerId}/facilities/${facilityId}`, body);

export const postCustomerFacility = (
  customerId: string,
  body: FacilityPostBody
) => api.post(`/customers/${customerId}/facilities`, body);

export const deleteCustomerFacility = (
  customerId: string,
  facilityId: string
) => api.delete(`/customers/${customerId}/facilities/${facilityId}`);

// AREAS
export const getCustomerFacilityArea = (
  customerId: string,
  facilityId: string,
  areaId: string
) =>
  api.get<{ customer: FacilityArea }>(
    `/customers/${customerId}/facilities/${facilityId}/areas/${areaId}`
  );

export const getCustomerFacilityAreas = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityArea[] }>(
    `/customers/${customerId}/facilities/${facilityId}/areas`
  );

export const patchCustomerFacilityArea = (
  customerId: string,
  facilityId: string,
  areaId: string,
  body: FacilityAreaBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/areas/${areaId}`,
    body
  );

export const postCustomerFacilityArea = (
  customerId: string,
  facilityId: string,
  body: FacilityAreaBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/areas`, body);

export const deleteCustomerFacilityArea = (
  customerId: string,
  facilityId: string,
  areaId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/areas/${areaId}`
  );

// Users
export const getCustomerFacilityUser = (
  customerId: string,
  facilityId: string,
  userId: string
) =>
  api.get<{ customer: FacilityUser }>(
    `/customers/${customerId}/facilities/${facilityId}/users/${userId}`
  );

export const getCustomerFacilityUsers = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityUser[] }>(
    `/customers/${customerId}/facilities/${facilityId}/users`
  );

export const patchCustomerFacilityUser = (
  customerId: string,
  facilityId: string,
  userId: string,
  body: FacilityUserPatchBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/users/${userId}`,
    body
  );

export const postCustomerFacilityUser = (
  customerId: string,
  facilityId: string,
  body: FacilityUserBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/users`, body);

export const deleteCustomerFacilityUser = (
  customerId: string,
  facilityId: string,
  userId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/users/${userId}`
  );

// Requests
export const getCustomerFacilityRequest = (
  customerId: string,
  facilityId: string,
  requestId: string
) =>
  api.get<{ customer: FacilityRequest }>(
    `/customers/${customerId}/facilities/${facilityId}/requests/${requestId}`
  );

export const getCustomerFacilityRequests = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityRequest[] }>(
    `/customers/${customerId}/facilities/${facilityId}/requests`
  );

export const patchCustomerFacilityRequest = (
  customerId: string,
  facilityId: string,
  requestId: string,
  body: FacilityRequestBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/requests/${requestId}`,
    body
  );

export const postCustomerFacilityRequest = (
  customerId: string,
  facilityId: string,
  body: FacilityRequestBody
) =>
  api.post(`/customers/${customerId}/facilities/${facilityId}/requests`, body);

export const postCustomerFacilityRequestComment = (
  customerId: string,
  facilityId: string,
  requestId: string,
  body: {
    description: string;
  }
) =>
  api.post(
    `/customers/${customerId}/facilities/${facilityId}/requests/${requestId}/comments`,
    body
  );

export const deleteCustomerFacilityRequest = (
  customerId: string,
  facilityId: string,
  requestId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/requests/${requestId}`
  );
