import logoImg from "assets/img/logo_white.png";
import logoProcleanerImg from "assets/procleaner/logo.png";

export enum LANG {
  PL = "PL",
  EN = "EN",
}
export const getAppConfiguration = () => {
  if (process.env.REACT_APP_API_ENV === "procleaner") {
    return {
      mainTitle: "Procleaner Cloud",
      mainSlogan: "Firma Sprzątająca Wrocław",
      favicon:
        "https://procleaner.pl/wp-content/uploads/2021/10/cropped-procleaner-300x300.png",
      logo: logoProcleanerImg,
      defaultLang: LANG.PL,
    };
  }

  return {
    mainTitle: "Matiko - Inventory Management",
    mainSlogan: "Tool to manage company inventory",
    logo: logoImg,
    defaultLang: LANG.EN,
  };
};
