import moment from 'moment';

export const readableDurationToSeconds = (value: string) => {
  value = value?.replace(' ', '') || '';
  const numbers = value.match(/(\d+)/g);
  let result = 0;

  const minutes = value.match(/(\d+)m/g);
  const seconds = value.match(/(\d+)s/g);
  if (minutes?.length) {
    result += Number(minutes[0].replace('m', '')) * 60;
  }
  if (seconds?.length) {
    result += Number(seconds[0].replace('s', ''));
  }
  // Fallback if someone writes 1m20
  if (numbers?.length === 2 && !seconds) {
    result += Number(numbers[1]); // seconds
  }
  return result;
};

export const secondsToReadableDuration = (value: string | number) => {
  value = Number(value);
  const minutes = Math.floor(value / 60);
  const seconds = value - minutes * 60;
  if (minutes && seconds) {
    return `${minutes}m ${seconds}s`;
  }
  if (minutes && !seconds) {
    return `${minutes}m`;
  }
  if (!minutes && seconds) {
    return `${seconds}s`;
  }
  return value;
};

export const isCorrectTimeValidator = (value?: string) => {
  if (!value || String(value) === '0') {
    return true;
  }
  value = value?.replace(' ', '') || '';
  return !!value.match(/^((\d+m)|(\d+m\d+s)|(\d+s))$/g);
};

export const dateFromNow = (date: string | Date) => {
  return moment(date).fromNow();
};

export const formatDate = (date: string | Date) => moment(date).format('LLL');
export const formatDateWithoutTime = (date: string | Date) => moment(date).format('DD/MM/YYYY');
