const ROUTES = {
  auth: {
    default: {
      path: '/auth',
    },
  },
  account: {
    default: {
      path: '/account',
    },
    overall: {
      path: '/account',
    },
    personalInformation: {
      path: '/account/personal-information',
    },
    settings: {
      path: '/account/settings',
    },
    subscription: {
      path: '/account/subscription',
    },
    clients: {
      path: '/account/clients',
    },
    edit: {
      path: '/account/edit',
    },
  },

  clients: {
    default: {
      path: '/clients',
    },
    list: {
      path: '/clients',
    },
    details: {
      path: '/clients/:id',
      to: (id: number) => `/clients/${id}`,
    },
  },
  files: {
    default: {
      path: '/files',
    },
    list: {
      path: '/files/list',
    },
    create: {
      path: '/files/create',
    },
    edit: {
      path: '/files/edit/:id',
    },
  },
  pdf: {
    default: {
      path: '/pdf',
    },
    list: {
      path: '/pdf/list',
    },
    create: {
      path: '/pdf/create',
    },
    edit: {
      path: '/pdf/edit/:id',
      to: (id: number) => `/pdf/edit/${id}`,
    },
    templates: {
      default: {
        path: '/pdf/templates',
      },
      list: {
        path: '/pdf/templates/list',
      },
      create: {
        path: '/pdf/templates/create',
      },
      edit: {
        path: '/pdf/templates/edit/:id',
        to: (id: number) => `/pdf/templates/edit/${id}`,
      },
    },
  },
  inventory: {
    default: {
      path: '/inventory',
    },
    groups: {
      path: '/inventory/groups',
      list: {
        path: '/inventory/groups',
      },
      details: {
        path: '/inventory/groups/:id',
        to: (id: number) => `/inventory/groups/${id}`,
      },
      edit: {
        path: '/inventory/groups/edit/:id',
        to: (id: number) => `/inventory/groups/edit/${id}`,
      },
      create: {
        path: '/inventory/groups/create',
      },
    },
    items: {
      path: '/inventory/items',
      list: {
        path: '/inventory/items',
      },
      details: {
        path: '/inventory/items/:id',
        to: (id: number) => `/inventory/items/${id}`,
      },
      edit: {
        path: '/inventory/items/edit/:id',
        to: (id: number) => `/inventory/items/edit/${id}`,
      },
      create: {
        path: '/inventory/items/create',
      },
    },
    requests: {
      path: '/inventory/requests',
      list: {
        path: '/inventory/requests',
      },
      details: {
        path: '/inventory/requests/:id',
        to: (id: number) => `/inventory/requests/${id}`,
      },
      manage: {
        path: '/inventory/requests/manage/:id',
        to: (id: number) => `/inventory/requests/manage/${id}`,
      },
      create: {
        path: '/inventory/requests/create',
      },
    },
  },
  users: {
    default: {
      path: '/users',
    },
    list: {
      path: '/users/list',
    },
    details: {
      path: '/users/:id',
      to: (id: string) => `/users/${id}`,
    },
    edit: {
      path: '/users/edit/:id',
      to: (id: string) => `/users/edit/${id}`,
    },
    invitations: {
      path: '/users/invitations',
    },
  },
  admin: {
    default: {
      path: '/admin',
    },
    invites: {
      path: '/admin/invites',
    },
    companies: {
      path: '/admin/companies',
      edit: {
        path: '/admin/companies/:companyId/edit',
        to: (id: string) => `/admin/companies/${id}/edit`,
      },
    },
  },
  customers: {
    default: {
      path: '/customers',
    },
    list: {
      path: '/customers/list',
    },
    create: {
      path: '/customers/create',
    },
    details: {
      path: '/customers/:customerId',
      to: (id: string) => `/customers/${id}`,
    },
    edit: {
      path: '/customers/edit/:customerId',
      to: (id: string) => `/customers/edit/${id}`,
    },
    users: {
      default: {
        path: '/customers/:customerId/users',
      },
      path: '/customers/:customerId/users',
      to: (customerId: string) => `/customers/${customerId}/users`,
      details: {
        path: '/customers/:customerId/users/:userId',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/users/${id}`,
      },
    },
    files: {
      path: '/customers/:customerId/files*',
      to: (customerId: string) => `/customers/${customerId}/files`,

      file: (customerId: string, fileIds: string[]) =>
        `/customers/${customerId}/files/${fileIds.join('/')}`,
    },

    contracts: {
      default: {
        path: '/customers/:customerId/contracts',
      },
      list: {
        path: '/customers/:customerId/contracts',
        to: (customerId: string) => `/customers/${customerId}/contracts`,
      },
    },
    facilities: {
      default: {
        path: '/customers/:customerId/facilities',
      },
      list: {
        path: '/customers/:customerId/facilities',
        to: (customerId: string) => `/customers/${customerId}/facilities`,
      },
      create: {
        path: '/customers/:customerId/facilities/create',
      },
      details: {
        path: '/customers/:customerId/facilities/:facilityId',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}`,
      },
      audits: {
        path: '/customers/:customerId/facilities/:facilityId/audits',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/audits`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/audits/:auditId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/audits/${id}`,
        },
      },

      files: {
        path: '/customers/:customerId/facilities/:facilityId/files*',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/files`,

        file: (customerId: string, facilityId: string, fileIds: string[]) =>
          `/customers/${customerId}/facilities/${facilityId}/files/${fileIds.join(
            '/'
          )}`,
      },

      areas: {
        path: '/customers/:customerId/facilities/:facilityId/areas',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/areas`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/areas/:areaId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/areas/${id}`,
        },
      },

      workSchedules: {
        path: '/customers/:customerId/facilities/:facilityId/work-schedules',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/work-schedules`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/work-schedules/:workScheduleId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/work-schedules/${id}`,
        },
      },

      workOrganisations: {
        path: '/customers/:customerId/facilities/:facilityId/work-organisations',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/work-organisations`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/work-organisations/:workOrganisationId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/work-organisations/${id}`,
        },
      },
      requests: {
        path: '/customers/:customerId/facilities/:facilityId/requests',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/requests`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/requests/:requestId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/requests/${id}`,
        },
      },
      orders: {
        path: '/customers/:customerId/facilities/:facilityId/orders',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/orders`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/orders/:orderId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/orders/${id}`,
        },
      },
      additionalServices: {
        path: '/customers/:customerId/facilities/:facilityId/additionalServices',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/additionalServices`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/additionalServices/:additionalServiceId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/additionalServices/${id}`,
        },
      },
      inventoryItems: {
        path: '/customers/:customerId/facilities/:facilityId/inventory-items',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/inventory-items`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/inventory-items/:inventoryItemId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/inventory-items/${id}`,
        },
      },
      workplan: {
        path: '/customers/:customerId/facilities/:facilityId/workplan',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/workplan`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/requests/:workplanId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/workplan/${id}`,
        },
      },
      users: {
        path: '/customers/:customerId/facilities/:facilityId/users',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/${id}/users`,
        details: {
          path: '/customers/:customerId/facilities/:facilityId/users/:userId',
          to: (customerId: string, facilityId: string, id: string) =>
            `/customers/${customerId}/facilities/${facilityId}/users/${id}`,
        },
      },
      edit: {
        path: '/customers/:customerId/facilities/edit/:facilityId',
        to: (customerId: string, id: string) =>
          `/customers/${customerId}/facilities/edit/${id}`,
      },
    },
  },
  company: {
    default: {
      path: '/company',
    },
    edit: {
      path: '/company/edit',
      to: () => `/company/edit`,
    },
    services: {
      path: '/company/services',
      to: () => `/company/services`,
    },
    areaTypes: {
      path: '/company/area-types',
      to: () => `/company/area-types`,
    },
    auditTasks: {
      path: '/company/audit-tasks',
      to: () => `/company/audit-tasks`,
    },
    companyProducts: {
      path: '/company/products',
      to: () => `/company/products`,
    },
  },
};

export default ROUTES;
