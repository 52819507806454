import api from 'shared/services/api';
import {
  CustomerFile,
  CustomerFileBody,
  CustomerFolder,
  CustomerFolderBody,
} from './types';

export const getCustomerCustomerFolder = (
  customerId: string,

  folderId: string
) =>
  api.get<{ customer: CustomerFolder }>(
    `/customers/${customerId}/folders/${folderId}`
  );

export const getCustomerCustomerFolders = (
  customerId: string,

  parentFolderId?: string,
  rootOnly?: boolean
) =>
  api.get<{ rows: CustomerFolder[] }>(`/customers/${customerId}/folders`, {
    params: { parentFolderId, rootOnly },
  });

export const patchCustomerCustomerFolder = (
  customerId: string,

  folderId: string,
  body: CustomerFolderBody
) => api.patch(`/customers/${customerId}/folders/${folderId}`, body);

export const postCustomerCustomerFolder = (
  customerId: string,

  body: CustomerFolderBody
) => api.post(`/customers/${customerId}/folders`, body);

export const deleteCustomerCustomerFolder = (
  customerId: string,

  folderId: string
) => api.delete(`/customers/${customerId}/folders/${folderId}`);

// FILES

export const getCustomerCustomerFile = (
  customerId: string,

  fileId: string
) =>
  api.get<{ customer: CustomerFile }>(
    `/customers/${customerId}/files/${fileId}`
  );

export const getCustomerCustomerFileSignedUrl = (
  customerId: string,

  fileId: string
) =>
  api.get<{ url: string }>(
    `/customers/${customerId}/files/${fileId}/signed-url`
  );

export const getCustomerCustomerFiles = (customerId: string) =>
  api.get<{ rows: CustomerFile[] }>(`/customers/${customerId}/files`);

export const patchCustomerCustomerFile = (
  customerId: string,

  fileId: string,
  body: CustomerFileBody
) => api.patch(`/customers/${customerId}/files/${fileId}`, body);

export const postCustomerCustomerFile = (
  customerId: string,

  body: any
) =>
  api.post(`/customers/${customerId}/files`, body, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const deleteCustomerCustomerFile = (
  customerId: string,

  fileId: string
) => api.delete(`/customers/${customerId}/files/${fileId}`);
