import api from 'shared/services/api';
import { FacilityInventoryItem, FacilityInventoryItemBody } from './types';
// InventoryItems
export const getCustomerFacilityInventoryItem = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.get<{ customer: FacilityInventoryItem }>(
    `/customers/${customerId}/facilities/${facilityId}/inventory-items/${orderId}`
  );

export const getCustomerFacilityInventoryItems = (
  customerId: string,
  facilityId: string
) =>
  api.get<{ rows: FacilityInventoryItem[] }>(
    `/customers/${customerId}/facilities/${facilityId}/inventory-items`
  );

export const patchCustomerFacilityInventoryItem = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: FacilityInventoryItemBody
) =>
  api.patch(
    `/customers/${customerId}/facilities/${facilityId}/inventory-items/${orderId}`,
    body
  );

export const postCustomerFacilityInventoryItem = (
  customerId: string,
  facilityId: string,
  body: FacilityInventoryItemBody
) => api.post(`/customers/${customerId}/facilities/${facilityId}/inventory-items`, body);

export const postCustomerFacilityInventoryItemComment = (
  customerId: string,
  facilityId: string,
  orderId: string,
  body: {
    description: string;
  }
) =>
  api.post(
    `/customers/${customerId}/facilities/${facilityId}/inventory-items/${orderId}/comments`,
    body
  );

export const deleteCustomerFacilityInventoryItem = (
  customerId: string,
  facilityId: string,
  orderId: string
) =>
  api.delete(
    `/customers/${customerId}/facilities/${facilityId}/inventory-items/${orderId}`
  );
