import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import List from './List';

function CustomerFacilityInventoryItemsRoutes() {
  return (
    <Switch>
      <Route path={[ROUTES.customers.facilities.inventoryItems.path]} exact>
        {/* <WithRole roles={[USER_PERMISSION.USERS_LIST]}> */}
        <List />
        {/* </WithRole> */}
      </Route>
    </Switch>
  );
}

export default CustomerFacilityInventoryItemsRoutes;
