import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useMemo } from 'react';
import { postCustomerFacilityInventoryItem } from 'shared/services/api/facilities/inventoryItems';

import { useTranslations } from 'shared/hooks/useTranslations';
import { getCompanyInventoryItems } from 'shared/services/api/company';
import useFetch from 'shared/hooks/useFetch';
import { InventoryItem } from 'shared/services/api/company/types';
import { FacilityInventoryItem } from 'shared/services/api/facilities/inventoryItems/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  currentItems: FacilityInventoryItem[];
}
const CreateFacilityInventoryItemModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId, currentItems } =
    props;
  const { data, isLoading, isLoaded } = useFetch<{
    items: InventoryItem[];
  }>({
    initialValue: { items: [] },
    fetchAction: () => getCompanyInventoryItems(),
  });

  const form = useForm({
    initialValues: {
      inventoryItemId: undefined,
      amount: undefined,
    },

    validate: {
      inventoryItemId: (value) => (!value ? 'required' : null),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerFacilityInventoryItem(customerId, facilityId, {
        inventoryItemId: values.inventoryItemId,
        amount: values.amount,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  const translations = useTranslations();
  const inventoryItemsOptions = useMemo(() => {
    return data.items.map((item) => {
      const alreadyUsed = !!currentItems.find(
        (i) => i.inventoryItem.id === item.id
      );
      return {
        label: alreadyUsed ? `${item.name} (w użyciu)` : item.name,
        value: item.id,
        disabled: alreadyUsed,
      };
    });
  }, [data.items, currentItems]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj sprzęt">
        {isLoading && <LoadingOverlay visible />}
        {isLoaded && (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <Select
              withAsterisk
              mt={10}
              data={inventoryItemsOptions}
              // TODO: Translations
              label="Sprzęt"
              placeholder=""
              {...form.getInputProps('inventoryItemId')}
            />
            <NumberInput
              withAsterisk
              label="Ilość"
              description={
                'W przypadku braku ilości, zostanie ona ustawiona na "według potrzeb".'
              }
              min={0}
              placeholder=""
              {...form.getInputProps('amount')}
            />
            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button type="submit" loading={loading}>
                {translations.global.add}
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default CreateFacilityInventoryItemModal;
