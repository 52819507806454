import React, { ReactNode } from 'react';
import cn from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from './styles.module.scss';
interface Props {
  type?: 'text' | 'email' | 'password';
  label?: string;
  placeholder?: string;
  tip?: string;
  required?: boolean;
  name: string;
  error?: FieldError;
  defaultValue?: any;
  row?: boolean;
  solid?: boolean;
  append?: ReactNode;
  prepend?: ReactNode;
  className?: string;
  disabled?: boolean;
  onChange?: any // TODO: add type
}
const TextField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type = 'text',
    placeholder,
    tip,
    label,
    required,
    name,
    error,
    defaultValue,
    row,
    solid,
    append,
    prepend,
    className,
    disabled,
    onChange
  } = props;
  const inputClasses = cn('form-control', {
    'is-invalid': error,
    'form-control-solid': solid,
  });
  const wrapperClasses = cn('form-group', className, {
    row: row,
    [styles.disabled]: disabled,
  });
  const inputWrapperClasses = cn('', {
    'input-group': !!append || !!prepend,
    'col-lg-9 col-xl-6': row,
  });
  const labelClasses = cn('', {
    'col-xl-3 col-lg-3 col-form-label': row,
  });
  return (
    <div className={wrapperClasses}>
      {label && (
        <label className={labelClasses}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className={inputWrapperClasses}>
        {prepend && (
          <div className="input-group-append">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        <input
          ref={ref}
          className={inputClasses}
          placeholder={placeholder}
          type={type}
          required={required}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
        />
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
      </div>
      {error && <small className="text-danger">{error.message}</small>}
      {tip && !error && <span className="form-text text-muted">{tip}</span>}
    </div>
  );
});

export default TextField;
