import React, { useRef } from 'react';
import Card from 'shared/components/Card';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import useFetch from 'shared/hooks/useFetch';

import { formatDate } from 'shared/utils/time';
import { getCompanyProducts } from 'shared/services/api/company/companyProducts';
import { useState } from 'react';
import { ActionIcon, Button, Group } from '@mantine/core';
import { CompanyProduct } from 'shared/services/api/company/companyProducts/types';
import CreateCompanyProductModal from './CreateCompanyProductModal';
import EditCompanyProductModal from './EditCompanyProductModal';
import RemoveCompanyProductModal from './RemoveCompanyProductModal';
import { IconPencil, IconTrash } from '@tabler/icons';
import api from 'shared/services/api';

const CompanyProducts = () => {
  const { customerId, facilityId } = useParams<{
    customerId: string;
    facilityId: string;
  }>();
  const [createFacilityModalOpen, setCreateFacilityModalOpen] = useState(false);

  const { data, error, isLoading, refresh } = useFetch<{
    rows: CompanyProduct[];
  }>({
    initialValue: {},
    fetchAction: () => getCompanyProducts(),
  });

  const [isUploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const uploadFiles = async (fileList: FileList) => {
    try {
      setUploading(true);
      const files = Array.from(fileList);
      await Promise.all(
        files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          const workOrganisationName = formatDate(new Date());
          formData.append('name', workOrganisationName);
          await api.put(`/company/products/update`, formData, {
            // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
            headers: {
              'content-type': 'multipart/form-data',
            },
          });
        })
      );
      refresh();
      setUploading(false);

      //   refreshData();
    } catch (error) {
      setUploading(false);
      alert('Failed to upload files');
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.type = 'text';
        fileInputRef.current.type = 'file';
      }
    }
  };
  const handleFileChange = (event) => {
    uploadFiles(event.target.files);
  };

  const handleOnCreate = () => {
    setCreateFacilityModalOpen(false);
    refresh();
  };

  const handleClickOnUpload = (
    e: any //: React.ChangeEventHandler<HTMLInputElement>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    // @ts-ignore
    fileInputRef.current?.click();
  };

  useState(false);
  const [editCompanyProductModalOpen, setEditCompanyProductModalOpen] =
    useState<CompanyProduct | undefined>();
  const [removeCompanyProductModalOpen, setRemoveCompanyProductModalOpen] =
    useState<CompanyProduct | undefined>();

  const handleOnEdit = () => {
    setEditCompanyProductModalOpen(undefined);
    refresh();
  };

  const handleOnDelete = () => {
    setRemoveCompanyProductModalOpen(undefined);
    refresh();
  };

  const handleEdit = (companyProduct: CompanyProduct) => () => {
    setEditCompanyProductModalOpen(companyProduct);
  };
  const handleRemove = (companyProduct: CompanyProduct) => () => {
    setRemoveCompanyProductModalOpen(companyProduct);
  };
  return (
    <div className="">
      <CreateCompanyProductModal
        facilityId={facilityId}
        open={createFacilityModalOpen}
        onClose={() => setCreateFacilityModalOpen(false)}
        onSuccess={handleOnCreate}
        customerId={customerId}
      />
      <EditCompanyProductModal
        companyProduct={editCompanyProductModalOpen!}
        open={!!editCompanyProductModalOpen}
        onClose={() => setEditCompanyProductModalOpen(undefined)}
        onSuccess={handleOnEdit}
      />
      <RemoveCompanyProductModal
        companyProduct={removeCompanyProductModalOpen!}
        open={!!removeCompanyProductModalOpen}
        onClose={() => setRemoveCompanyProductModalOpen(undefined)}
        onSuccess={handleOnDelete}
      />
      <div className="row">
        <input
          type="file"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div className="col-xl-12">
          <Card
            title={'Produkty'}
            toolbar={
              <Group>
                <Button
                  onClick={handleClickOnUpload}
                  variant="outline"
                  loading={isUploading}
                >
                  {/* TODO: Translations */}
                  Zaktualizuj produkty
                </Button>
                <Button
                  onClick={() => setCreateFacilityModalOpen(true)}
                  display="none"
                >
                  {/* TODO: Translations */}
                  Dodaj produkt
                </Button>
              </Group>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!!error && <div>Error</div>}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Indeks</th>
                  <th>Nazwa art.</th>
                  <th>Jednostka</th>
                  <th>Dostawca</th>
                  <th>Kategoria</th>
                  <th>Cena sprzedaży</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((companyProduct, index) => (
                  <tr key={companyProduct.id}>
                    <td>{companyProduct.uniqueId}</td>
                    <td>{companyProduct.name}</td>
                    <td>{companyProduct.unitType}</td>
                    <td>{companyProduct.manufacturer}</td>
                    <td>{companyProduct.category}</td>
                    <td>{Number(companyProduct.price / 100).toFixed(2)} zł</td>
                    <td></td>
                    <td>{formatDate(companyProduct.createdAt)}</td>
                    <td>
                      <Group spacing="xs" noWrap display="none">
                        <ActionIcon
                          type="button"
                          color="blue"
                          onClick={handleEdit(companyProduct)}
                        >
                          <IconPencil size={15} />
                        </ActionIcon>
                        <ActionIcon
                          type="button"
                          color="red"
                          onClick={handleRemove(companyProduct)}
                        >
                          <IconTrash size={15} color="red" />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompanyProducts;
