import React from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import useFetch from 'shared/hooks/useFetch';
import { getInventoryGroups } from 'shared/services/api/inventory';
import { useTranslations } from 'shared/hooks/useTranslations';

export default function ExerciceList() {
  const { data, isLoading, isLoaded } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryGroups(),
  });
  const history = useHistory();

  const onAdd = () => {
    history.push(ROUTES.inventory.groups.create.path);
  };
  const onRowClick = (id: number) => () => {
    history.push(ROUTES.inventory.groups.edit.to(id));
  };
  const translations = useTranslations();
  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.groups.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{translations.groups.groups}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div className="col-xl-12">
          <Card
            title={translations.groups.groups}
            toolbar={
              <>
                <Button onClick={onAdd} color="primary">
                  {translations.global.create}
                </Button>
              </>
            }
          >
            {isLoading && (
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {isLoaded && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>{translations.global.name}</th>
                    <th>{translations.global.order}</th>
                    {/* <th>Grupy nadrzędne</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.groups.map((exercice) => (
                    <tr
                      key={exercice.id}
                      onClick={onRowClick(exercice.id)}
                      role="button"
                    >
                      <td>{exercice.name}</td>
                      <td>{exercice.order}</td>
                      {/* <td>{exercice.parentInventoryGroup?.name}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
}
