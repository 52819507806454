import {
  Alert,
  Button,
  Group,
  Modal,
  NumberInput,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchCompanyProduct } from 'shared/services/api/company/companyProducts';
import { CompanyProduct } from 'shared/services/api/company/companyProducts/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  companyProduct: CompanyProduct;
}
const EditCompanyProductModal = (props: Props) => {
  const { open, onClose, onSuccess, companyProduct } = props;

  const form = useForm({
    initialValues: {
      name: companyProduct?.name,
      order: companyProduct?.order,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCompanyProduct(companyProduct.id, {
        name: values.name,
        order: values.order,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyProduct && open) {
      form.setValues({
        name: companyProduct?.name,
        order: companyProduct?.order,
      });
    }
    // eslint-disable-next-line
  }, [companyProduct, open]);

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj produkt">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <NumberInput
            withAsterisk
            label="Kolejnosc"
            min={0}
            placeholder=""
            {...form.getInputProps('order')}
          />

          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Zapisz
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditCompanyProductModal;
