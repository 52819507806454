import {
  Alert,
  Button,
  Group,
  Modal,
  NumberInput,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect } from 'react';
import { patchCompanyService } from 'shared/services/api/company/services';

import { useTranslations } from 'shared/hooks/useTranslations';
import { CompanyService } from '../../../../../shared/services/api/company/services/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
  service: CompanyService;
}
const EditCompanyServiceModal = (props: Props) => {
  const { open, onClose, onSuccess, service } = props;
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      price: 0.0,
      unit: 'zł',
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
      description: (value) =>
        value && value.length > 0 ? null : 'Invalid name',
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await patchCompanyService(service.id, {
        name: values.name,
        description: values.description,
        price: values.price,
        unit: values.unit,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      form.setValues(service);
    }
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, service]);
  const translations = useTranslations();

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Edytuj usługę">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            withAsterisk
            // TODO: Translations
            label="Nazwa"
            placeholder=""
            {...form.getInputProps('name')}
          />
          <Textarea
            withAsterisk
            mt={10}
            // TODO: Translations
            label="Opis usługi"
            placeholder=""
            {...form.getInputProps('description')}
          />
          <NumberInput
            // TODO: Translations
            label="Cena"
            placeholder=""
            decimalSeparator=","
            defaultValue={0.0}
            precision={2}
            step={0.1}
            {...form.getInputProps('price')}
          />
          <TextInput
            // TODO: Translations
            label="Jednostka"
            placeholder="m2"
            {...form.getInputProps('unit')}
          />
          {error && <Alert color="red">{error}</Alert>}
          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              {translations.global.add}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default EditCompanyServiceModal;
