import { Alert, Button, Group, Modal } from '@mantine/core';
import React from 'react';
import { deleteCustomerUser } from 'shared/services/api/customers';
import { CustomerUser } from 'shared/services/api/customers/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  user: CustomerUser;
}
const RemoveCustomerUserModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, user } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomerUser(customerId, user.userId);
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal opened={open} onClose={onClose} title="Odepnij Użytkownika">
        Czy na pewno chcesz odpiąć użytkownika od tego klienta?
        {error && <Alert color="red">{error}</Alert>}
        <Group position="right" mt="md">
          <Button type="submit" loading={loading} onClick={handleSubmit}>
            Zapisz
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default RemoveCustomerUserModal;
