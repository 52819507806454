const colorByStatus = {
  FREE: 'success',
  TAKEN: 'info',
};

export const INVENTORY_PRIORITY = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const INVENTORY_USABILITY = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const INVENTORY_ITEM_STATUS = {
  FREE: 'FREE',
  TAKEN: 'TAKEN',
};
export const INVENTORY_STATUS = {
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  DONE: 'DONE',
};

export const getBadgeColorByInventoryItemStatus = (status: string) => {
  return colorByStatus[status] || 'light';
};

const colorByInvitationStatus = {
  REGISTERED: 'success',
  PENDING: 'info',
};

export const getBadgeColorByInvitationStatus = (status: string) => {
  return colorByInvitationStatus[status] || 'light';
};

const colorByRequestStatus = {
  CREATED: 'success',
  IN_PROGRESS: 'info',
  REJECTED: 'light',
  CANCELED: 'light',
  DONE: 'light',
};

export const getBadgeColorByRequestStatus = (status: string) => {
  return colorByRequestStatus[status] || 'light';
};
const colorByRequestPriority = {
  LOW: 'light',
  MEDIUM: 'warning',
  HIGH: 'danger',
};

export const getBadgeColorByRequestPriority = (status: string) => {
  return colorByRequestPriority[status] || 'light';
};
