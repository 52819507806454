import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import React, { useEffect, useMemo, useState } from 'react';
import { postCustomerUser } from 'shared/services/api/customers';
import useFetch from 'shared/hooks/useFetch';
import { getCurrentCompanyUsers } from 'shared/services/api/company';
import { CommonUserType, CompanyUser } from '../../../../../../types/user';
import { CustomerUser } from 'shared/services/api/customers/types';
import {
  createCustomerUserBaseSchema,
  createCustomerUserExtendedSchema,
} from './validate';
import { useTranslations } from 'shared/hooks/useTranslations';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  currentUsers: CustomerUser[];
}

const CreateCustomerUserModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, currentUsers } = props;

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { data, isLoading, isLoaded } = useFetch<{ rows: CompanyUser[] }>({
    initialValue: {},
    fetchAction: () => getCurrentCompanyUsers(),
  });
  const [addedItem, setAddedItem] = useState<undefined | any>();
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerUser(customerId, {
        ...values,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const form = useForm({
    initialValues: {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      type: undefined,
    },

    validate: zodResolver(
      addedItem
        ? createCustomerUserExtendedSchema
        : createCustomerUserBaseSchema
    ),
  });

  const translations = useTranslations();
  const usersData = useMemo(() => {
    const list =
      data.rows?.map(({ user }) => {
        const isDisabled = currentUsers?.find((u) => u.userId === user.id);

        return {
          label: `${[user.firstName, user.lastName].join(' ')} (${
            translations.enums.commonUserType[user.type!]
          })`,
          value: user.email,
          userId: user.id,
          email: user.email,
          type: user.type,
          disabled: isDisabled,
        };
      }) || [];
    if (addedItem) {
      list.push(addedItem);
    }
    return list as SelectItem[];
  }, [data.rows, currentUsers, addedItem, translations]);

  const selectedUser = usersData?.find((u) => form.values.email === u.value);
  const existingSelectedUser = usersData?.find(
    (u) => form.values.email === u.value && u.userId
  );
  const willCreateNewUser = !!selectedUser && !selectedUser.userId;

  const typeData = [
    {
      label: 'Klient',
      value: CommonUserType.CUSTOMER,
    },
    {
      label: 'Pracownik naszej firmy',
      value: CommonUserType.COMPANY_EMPLOYEE,
    },
  ].filter((type) =>
    existingSelectedUser ? existingSelectedUser.type === type.value : true
  );

  useEffect(() => {
    if (
      (addedItem && !form.values.email) ||
      (addedItem &&
        usersData?.find((u) => form.values.email === u.value && u.userId))
    ) {
      setAddedItem(false);
    }
  }, [form.values, addedItem, usersData]);

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj użytkownika">
        {isLoading && <LoadingOverlay visible />}
        {isLoaded && (
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ minHeight: 400 }}
          >
            <Select
              withAsterisk
              label="Email"
              data={usersData}
              creatable
              clearable
              description="Wpisz adres e-mail aby dodać nowego użytkownika"
              getCreateLabel={(query) =>
                `+ Stwórz nowego użytkownika z emailem ${query}`
              }
              onCreate={(query) => {
                const item = { value: query, label: query };
                setAddedItem(item);
                return item;
              }}
              searchable
              placeholder=""
              {...form.getInputProps('email')}
            />
            {willCreateNewUser && (
              <Select
                mt={10}
                withAsterisk
                label="Typ konta"
                data={typeData}
                searchable
                placeholder=""
                {...form.getInputProps('type')}
              />
            )}

            {willCreateNewUser && (
              <>
                <TextInput
                  mt={10}
                  withAsterisk
                  label="Imię"
                  placeholder=""
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  mt={10}
                  withAsterisk
                  label="Nazwisko"
                  placeholder=""
                  {...form.getInputProps('lastName')}
                />
                <TextInput
                  mt={10}
                  label="Numer telefonu"
                  placeholder=""
                  {...form.getInputProps('phoneNumber')}
                />
              </>
            )}
            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button type="button" variant="outline" onClick={onClose}>
                Anuluj
              </Button>
              <Button
                type="submit"
                loading={loading}
                disabled={!form.values.email}
              >
                Stwórz
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default CreateCustomerUserModal;
