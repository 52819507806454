import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { useHistory, useParams } from 'react-router';
// import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';

import UserContext from 'shared/contexts/UserContext';

import { getUser } from 'shared/services/api/users';
import { TRANSLATIONS } from 'shared/translations';
import { LANG } from 'config';
import { Badge } from '@mantine/core';
import { COMMON_USER_TYPE_BADGE_COLOR } from 'types/user';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Params {
  id: string;
}
export default function EditExercice() {
  const { data, isLoading, isLoaded, error } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getUser(id),
  });
  const { id } = useParams<Params>();
  const history = useHistory();
  const handleEdit = () => {
    history.push(ROUTES.users.edit.to(data.user?.id));
  };
  const translations = useTranslations();
  return (
    <UserContext.Consumer>
      {({ data: user }) => (
        <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item
              linkProps={{ to: ROUTES.users.list.path }}
              linkAs={Link}
            >
              Użytkownicy
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {data.user?.firstName} {data.user?.lastName}
            </Breadcrumb.Item>
          </Breadcrumb>
          {isLoaded && (
            <>
              {/* <RemoveExerciceModal
                show={removeModal}
                onClose={() => setRemoveModal(false)}
                exercice={data.item}
              />
              <ConfirmTakeModal
                show={takeModal}
                onClose={() => setTakeModal(false)}
                exercice={data.item}
              /> */}
            </>
          )}
          <div className="row">
            <div className="col-xl-12">
              {isLoading && (
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
              {error && <div className="alert alert-danger">No user</div>}
              {isLoaded && (
                <Card
                  title="Użytkownik"
                  toolbar={
                    <>
                      <Button
                        color="light"
                        onClick={handleEdit}
                        className="mr-5"
                      >
                        Edit
                      </Button>
                    </>
                  }
                >
                  <div className="row">
                    <div className="col-md-4">
                      <h4>Informacje</h4>
                      <div className="mb-5">
                        <div>
                          <strong>Nazwa:</strong>
                        </div>
                        {data.user?.firstName} {data.user?.lastName}
                      </div>
                      <div className="mb-5">
                        <div>
                          <strong>Email:</strong>
                        </div>
                        {data.user?.email}
                      </div>
                      {data.user?.type && (
                        <div className="mb-5">
                          <div>
                            <strong>Typ:</strong>
                          </div>
                          <Badge
                            color={
                              COMMON_USER_TYPE_BADGE_COLOR[data.user?.type]
                            }
                          >
                            {translations.enums.commonUserType[data.user?.type]}
                          </Badge>
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <h4>Role</h4>
                      <ul>
                        {data.user?.roles?.map((o) => (
                          <li className="mb-5" key={`${o.id}`}>
                            <div>
                              <strong>
                                {TRANSLATIONS[LANG.PL].user.permissions[
                                  o.role
                                ] || o.role}
                              </strong>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="mb-5">
                        <div>
                          <h4>Taken inventory items:</h4>
                        </div>
                        <div>
                          <ul>
                            {data.user?.inventoryItems?.map((o) => (
                              <li key={`${o.id}`} className="mt-5">
                                <strong>{o.name}</strong>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
}
