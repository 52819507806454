import {
  Alert,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useMemo } from 'react';
import {
  getCustomerFacilityAreas,
  postCustomerFacilityRequest,
} from 'shared/services/api/facilities';
import useFetch from 'shared/hooks/useFetch';
import { FacilityArea } from 'shared/services/api/facilities/types';
import { useTranslations } from 'shared/hooks/useTranslations';
import { createFacilityAreaSelectOptions } from '../../../Areas/List/CreateFacilityAreaModal/helpers';

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  customerId: string;
  facilityId: string;
}
const CreateFacilityRequestModal = (props: Props) => {
  const { open, onClose, onSuccess, customerId, facilityId } = props;

  const { data, isLoading, isLoaded } = useFetch<{
    rows: FacilityArea[];
  }>({
    initialValue: {},
    fetchAction: () => getCustomerFacilityAreas(customerId, facilityId),
  });

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      facilityAreaId: undefined,
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : 'Invalid name'),
      description: (value) =>
        value && value.length > 0 ? null : 'Invalid name',
    },
  });
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await postCustomerFacilityRequest(customerId, facilityId, {
        name: values.name,
        description: values.description,
        facilityAreaId: values.facilityAreaId,
      });
      onSuccess();
    } catch (e) {
      setError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const requests = useMemo(
    () => createFacilityAreaSelectOptions(data?.rows || []),
    [data?.rows]
  );
  const translations = useTranslations();

  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Modal opened={open} onClose={onClose} title="Dodaj zgłoszenie">
        {isLoading && <LoadingOverlay visible />}
        {isLoaded && (
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              withAsterisk
              // TODO: Translations
              label="Nazwa"
              placeholder=""
              {...form.getInputProps('name')}
            />
            <Textarea
              withAsterisk
              mt={10}
              // TODO: Translations
              label="Opis zgłoszenia"
              placeholder=""
              {...form.getInputProps('description')}
            />
            <Select
              mt={10}
              clearable
              searchable
              data={requests}
              // TODO: Translations
              label="Strefa"
              placeholder=""
              {...form.getInputProps('facilityAreaId')}
            />

            {error && <Alert color="red">{error}</Alert>}
            <Group position="right" mt="md">
              <Button type="submit" loading={loading}>
                {translations.global.add}
              </Button>
            </Group>
          </form>
        )}
      </Modal>
    </>
  );
};

export default CreateFacilityRequestModal;
