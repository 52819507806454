import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ROUTES from 'shared/constants/routes';
import Button from 'shared/components/Button';
import Card from 'shared/components/Card';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import ExerciceForm from '../Form';
import useFetch from 'shared/hooks/useFetch';
import map from 'lodash/map';
import { toast } from 'react-toastify';
import validation from '../validation';

import RemoveExerciceModal from '../RemoveExerciceModal';
import {
  editInventoryItem,
  getInventoryItem,
} from 'shared/services/api/inventory';
import api from 'shared/services/api';
import { useTranslations } from 'shared/hooks/useTranslations';
interface Params {
  id: string;
}
export default function EditExercice() {
  const { data, isLoading, isLoaded, error, refresh } = useFetch<any>({
    initialValue: [],
    fetchAction: () => getInventoryItem(id),
  });
  const translations = useTranslations();
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [isSaving, setSaving] = useState(false);
  const { id } = useParams<Params>();
  const form = useForm({
    defaultValues: {
      uniqueId: undefined,
      order: undefined,
      metaData: [],
    },
    resolver: yupResolver(validation),
  });
  const { register, handleSubmit, errors, reset, control, watch } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metaData',
    // keyName: 'keyName',
  });

  useEffect(() => {
    const metaData =
      data.item?.data.map((o) => ({
        key: o.key,
        value: o.value,
      })) || [];
    reset({
      // ...data.item,
      // @ts-ignore
      name: data?.item?.name,
      uniqueId: data?.item?.uniqueId,
      description: data?.item?.description,
      order: data?.item?.order,
      metaData: metaData,
      // inventoryGroupId: { value: data?.item?.inventoryGroupId },
    });
  }, [data, reset]);

  const onSubmit = async (formValues: any) => {
    setSaving(true);
    try {
      const metaData = map(formValues.metaData, (o, index) => {
        return {
          id: o.uuid,
          key: o.key?.value || o.key,
          value: o.value,
        };
      });
      await editInventoryItem(id, {
        uniqueId: data.item?.uniqueId,
        ...formValues,
        metaData,
        inventoryGroupId: formValues.inventoryGroupId?.value,
      });
      if (formValues.image) {
        const formData = new FormData();
        // @ts-ignore
        formData.append('file', formValues.image);
        await api.post(`/inventory/items/${id}/image`, formData, {
          // baseURL: process.env.REACT_APP_API_OLD_ENDPOINT,
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
      }
      refresh();
      toast.success(translations.inventory.editSuccess);
      setSaving(false);
    } catch (e) {
      toast.error('Something went wrong');
    }
  };
  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.items.list.path }}
          linkAs={Link}
        >
          {translations.inventory.inventory}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkProps={{ to: ROUTES.inventory.items.list.path }}
          linkAs={Link}
        >
          {translations.global.list}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.item?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <RemoveExerciceModal
        show={removeModal}
        onClose={() => setRemoveModal(false)}
        exercice={data.item}
      />
      <div className="row">
        <div className="col-xl-12">
          {isLoading && (
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {error && <div className="alert alert-danger">No inventory item</div>}
          {isLoaded && !isLoading && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                title={translations.inventory.editItem}
                toolbar={
                  <>
                    <Button
                      color="danger"
                      onClick={() => setRemoveModal(true)}
                      className="mr-5"
                      type="button"
                    >
                      {translations.global.remove}
                    </Button>
                    <Button color="primary" isLoading={isSaving}>
                      {translations.global.save}
                    </Button>
                  </>
                }
              >
                <ExerciceForm
                  errors={errors}
                  register={register}
                  control={control}
                  metaData={fields}
                  append={append}
                  remove={remove}
                  watch={watch}
                  item={data.item}
                  getValues={form.getValues}
                  setValue={form.setValue}
                />
              </Card>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
